







































import { Component, Vue } from "vue-property-decorator";
import {EventBus} from "@/config";

@Component
export default class LostEmploymentInfoModal extends Vue {

  public selectedHasNoJobCheckbox: string = '';

  public handleOpenLostEmploymentInfoModal(e) {
    this.selectedHasNoJobCheckbox = e.params.type
  }

  public setHasNoJobCheckbox() {
    EventBus.$emit('has-no-job-change', this.selectedHasNoJobCheckbox)
    this.$modal.hide('lostEmploymentInfoModal')
  }
}
