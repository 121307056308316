import { Address, Name } from '../pos/common.model';

export class LoanOriginatorInfo {
    constructor() {
        this.originatorSignature = null;
        this.loanTxnId = null;
        this.date = null;
        this.originator = new Originator();
        this.organization = new Organization();
    }
    public originatorSignature: string;
    public loanTxnId: string;
    public date: string;
    public originator: Originator;
    public organization: Organization;
    public originatorModelName :string;
    public organizationModelName: string;
}

export class Originator {
    constructor() {
        this.email = null;
        this.phone = null;
        this.firstName = null;
        this.NMLSNumber = null;
        this.stateLicenseId = null
    }
    public email: string;
    public phone: string;
    public firstName: string;
    public NMLSNumber: string;
    public stateLicenseId: string;
}


export class Organization {
    constructor() {
        this.address = null;
        this.NMLSNumber = null;
        this.stateLicenseId = null;
        this.companyName = null;
    }
    public address: string;
    public NMLSNumber: string;
    public stateLicenseId: string;
    public companyName: string;
}
