
export default (namePrefix: string) => [

    {
        path: 'pos-questionnaire',
        name: namePrefix +  'POSQuestionnaire',
        component: () => import(/* webpackChunkName: "pos-questionnaire" */"@/components/pos/questionnaire/Questionnaire.vue"),
        meta: {
            requiresAuth: true,
            title: "POS Questionnaire"
        }
    },
    {
        path: 'new-urla',
        name: namePrefix +  'NewUrla',
        component: () => import(/* webpackChunkName: "new-urla" */'@/components/urla/new/Urla.vue'),
        meta: {
            requiresAuth: true,
            title: "NewUrla"
        }
    },
    {
        path: "current-urla",
        name: namePrefix +  "CurrentUrla",
        component: () => import(/* webpackChunkName: "current-urla" */'@/components/urla/los/Los.vue'),
        meta: {
            requiresAuth: true,
            title: "CurrentUrla"
        }
    }
]