import stores from '@/store/store';
const store:any  = stores;
import { getCurrentUserTypePath } from "./broker.routes";
import posRoutes from './pos.routes';
import commonRoutes from './common.routes';
export const beforeEachEnter = async (to, from, next) => {
    if (store.state.sessionObject.type == "Borrower") {
        next()
    } else {
        let path = await getCurrentUserTypePath();
        next(path)
    }
};


export default [
    {
        path: "/borrower",
        name: "Borrower",
        component: () => import(/* webpackChunkName: "borrower" */'@/components/broker/Broker.vue'),
        redirect: { name: 'BorrowerDashBoard' },
        meta: {
            requiresAuth: true,
            title: "Dashboard"
        },
        beforeEnter: beforeEachEnter,
        children: [
            {
                path: "dashboard",
                name: "BorrowerDashBoard",
                component: () => import(/* webpackChunkName: "borrower-dashboard" */'@/components/borrower/borrower-dashboard/BorrowerDashboard.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Dashboard"
                },
            },
            {
                path: "my-account",
                name: "Account",
                component: () => import(/* webpackChunkName: "borrower-account" */'@/components/borrower/my-account/MyAccount.vue'),
                meta: {
                    requiresAuth: true,
                    title: "My Account"
                }
            },
            {
                path: "disclosures",
                name: "Disclosures",
                component: () => import(/* webpackChunkName: "borrowers-disclosure" */'@/components/borrower/disclosures/Disclosures.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Disclosures"
                }
            },
            {
                path : "notifications",
                name: "NotificationBorrower",
                component: () => import(/* webpackChunkName: "notification-borrower" */ '@/components/borrower/notification/Notification.vue'),
                meta : {
                    requiresAuth : true,
                    title : "Notification"
                } 
            },
            ...posRoutes('Borrower'),
            ...commonRoutes('Borrower')
        ]
    },

]