export class PreRequisiteModel {
    constructor(){
        this.steps = new Steps();
    }
    totalStep: number;
    steps: Steps;
}

class Steps {
    constructor(){
        this.step1 = "LenderInfo";
        this.step2 = "LOS";
        this.step3 = "CreditVendor"
        this.step4 = "AMCVendor";
        this.step5 = "AUSVendor";
        this.step6 = "Contract";
        this.step7 = "BrokerStaff";
        this.step8 = "AccessRights";
        this.step9 = "OnBoardingCall";
        this.step10 = "BrokerDashBoard";
    }
    step1: string;
    step2: string;
    step3: string;
    step4: string;
    step5: string;
    step6: string;
    step7: string;
    step8: string;
    step9: string;
    step10: string;
}