import Axios from 'axios';
import { BASE_API_URL } from '@/config';
import { SUPER_ADMIN_NAV_LINKS, BROKER_NAV_LINKS, WEMLO_PROCESSOR_NAV_LINKS, BORROWER_NAV_LINKS, MLO_NAV_LINKS, ADMIN_STAFF_NAV_LINKS } from '@/constants';

export default {
    setSession({ commit }: any, loginResponse: any) {
        commit('SET_LOGIN_SESSION', loginResponse)
    },

    clearSession({ commit }: any) {
        commit('CLEAR_SESSION')
    },

    logout({ commit }: any) {
        commit('CLEAR_SESSION');
        commit('LOGOUT');
    },

    updateSessionData({ commit }: any, data: any) {
        commit('UPDATE_SESSION_DATA', data)
    },

    updateSessionUserType({ commit }: any, data: any) {
        commit('UPDATE_SESSION_USER_TYPE', data)
    },

    updateConsentToContact({ commit }: any, data: boolean) {
        commit('UPDATE_CONSENT_TO_CONTACT', data)
    },


    updatePipelineFilters({ commit }: any, data: any) {
        commit('UPDATE_PIPELINE_FILTERS', data)
    },

    updateEDisclosureAgreement({ commit }: any, data: boolean) {
        commit('UPDATE_E_DISCLOSURE_AGREEMENT', data)
    },

    updateSessionStates({ commit }: any, data: any) {
        commit('UPDATE_SESSION_STATES', data)
    },

    updatePreRequisite({ commit }: any, data: any) {
        commit('UPDATE_PRE_REQUISITE', data)
    },

    removeStatusOfVerfiedToken({ commit }: any) {
        commit('REMOVE_STATUS_OF_VERIFIED_TOKEN')
    },

    async statusOfProfilePicture({ commit }, userInfo:any) {
        try {
            if(userInfo.status!==null) return;
            let obj = {};
            if (userInfo.image){
                let ext = userInfo.image.split('.').pop();
                userInfo.image = userInfo.image.replace(/original/gi, 'thumb');
                let response = await Axios.post(BASE_API_URL+'common/fetchDocument', { path: userInfo.image});
                if (response.status == 201) throw "deleted";
                obj['thumb'] = 'data:image/'+ext+';base64,'+response.data;
                obj['status'] = true;
                obj['cordinates'] = await imageCordinates(obj['thumb']);
                this.dispatch('backgroundFetchOfImageSmallSize', userInfo.image.replace(/thumb/gi, 'small'));
            } else {
                obj['status'] = false;
                obj['alpha'] = userInfo.name[0];
                obj['status'] = false;
            }
            commit('STATUS_OF_PROFILE_PICTURE', obj);
        } catch (error) {
            console.log(error);
            let obj = {}
            obj['status'] = false;
            obj['alpha'] = userInfo.name[0];
            obj['status'] = false;
            commit('STATUS_OF_PROFILE_PICTURE', obj);
        }
    },


    async setProfilePicture({ commit }: any, obj: any) {
        obj.data['cordinates'] = await imageCordinates(obj.data.thumb);
        commit('SET_PROFILE_PICTURE', obj)
    },

    async backgroundFetchOfImageSmallSize ({ commit }, path){
        try {
            let ext = path.split('.').pop();
            let response = await Axios.post(BASE_API_URL+'common/fetchDocument', { path: path});
            commit('SET_IMAGE_SMALL', 'data:image/'+ext+';base64,'+response.data);
        } catch (error) {
            console.log(error);
        }
    },
    // setImageSmall({ commit }, data){
    // },
    // this.$store.dispatch('setImageSmall', 'data:image/'+ext+';base64,'+response.data);


    setloanSifterId({ commit }: any, data: any) {
        commit('SET_LOANSIFTERID', data)
    },

    setTwilioActiveStatus({ commit }: any, data: any) {
        commit('SET_TWILIO_ACTIVE_STATUS', data)
    },

    async updatePreRequisiteOnServer({ commit }: any, body) {
        try {
            if (body.hasOwnProperty('changeInState') && body.changeInState) {
                commit('UPDATE_PRE_REQUISITE', body.num);
            }
            let response = await Axios.post(BASE_API_URL + "broker/updatePrerequisite",
                {
                    userId: body.userId,
                    userType: body.userType,
                    num: body.num
                });
        } catch (error) {
            console.log(error.response);
        }
    },
    async autoSaveSignatures({ commit }: any, body) {
        try {
            // if (body.hasOwnProperty('changeInState') && body.changeInState) {
            //     commit('UPDATE_PRE_REQUISITE', body.num);
            // }
            let response = await Axios.post(BASE_API_URL + "disclosure/saveDisclosureSigned",
                {
                    userId: body.userId,
                    userType: body.userType,
                    docId: body.docId,
                    loanTxnId: body.loanTxnId,
                    signId: body.signId
                });
        } catch (error) {
            console.log(error.response);
        }
    },

    async updateSubmissionForm({ commit }: any, data: any) {
        try {
            await Axios.post(BASE_API_URL + "broker/saveSubmissionStep",
                {
                    loanTxnId: data.loanTxnId,
                    currentState: data.currentState
                });
        } catch (error) {
            console.log(error);
        }
    },


    // async fetchPosData({ commit }: any, param) {
    //     try {
    //         let response = await Axios.get(BASE_API_URL + 'pos/fetchPosData', { params: { loanTxnId: param.id } })
    //         commit('SET_POS_ANSWERS', response.data)
    //     } catch (error) {
    //         console.log('Unable to fetch your saved form details.');
    //     }
    // },
    async getPosBasicInfo({ commit }: any, param) {
        try {
            let response = await Axios.get(BASE_API_URL + 'pos/getPosBasicInfo', { params: { loanTxnId: param.loanTxnId } })
            commit('SET_POS_ANSWERS', response.data)
        } catch (error) {
            console.log('Unable to fetch your saved form details.');
        }
    },

    async getQuestionnaireHistory({ commit }: any, obj: any) {
        try {
            let response = await Axios.get(BASE_API_URL + "pos/getQuestionnaireHistory", { params: { loanTxnId: obj.loanTxnId, borrowerId: obj.borrowerId } });
            commit('SET_QUESTIONNAIRE_HISTORY', { questionnaireHistory: response.data.history, loanTxnId: obj.loanTxnId, borrowerId: obj.borrowerId });
            return;
        } catch (error) {
            console.log(error, 'Unable to fetch your last accessed question.');
        }
    },
    async updateQuestionHistory({ commit }, data) {
        commit('UPDATE_QUESTIONNAIRE_HISTORY', data)
    },

    async updateAccessRight({ commit }, data) {
        try {
            let response = await Axios.post(BASE_API_URL + 'broker/getUserAccess', { userId: data });
            commit('UPDATE_ACCESS_RIGHT', response.data);
        } catch (error) {
            console.log(error);
        }
    },

    async setSidenavLinks({ commit }, data) {
        const type: string = data.type && data.type.replace(' ', '');
        const isMottoUser = JSON.parse(localStorage.getItem('state.WeMLO')).sessionObject?.userInfo?.isMottoUser;
        let types = {
            'SuperAdmin': SUPER_ADMIN_NAV_LINKS(data?.userInfo?.entitlements),
            'Broker': BROKER_NAV_LINKS(),
            'MLO': MLO_NAV_LINKS(),
            'AdminStaff': ADMIN_STAFF_NAV_LINKS(),
            'WemloProcessor': WEMLO_PROCESSOR_NAV_LINKS(),
            'Borrower': BORROWER_NAV_LINKS()
        }

        if (isMottoUser) {
            removeWehelp(types, 'Broker');
            removeWehelp(types, 'MLO');
            removeWehelp(types, 'AdminStaff');
        }

        let links: Array<any> = types[type];
        if (type == 'MLO' || type == 'AdminStaff') {
            const rights: Array<any> = data.access ? data.access : [];

            /**
             * Due to incomplete information about components restriction based on users,
             * we are only applying check on two components. This can be modified later on.
             */
            let hasManageUsersAccess = rights.find(r => r.component == 'manageUsers' && r.canAccess)
            let hasNewLoanAccess = rights.find(r => r.component == 'startNewLoan' && r.canAccess)
            links.forEach((link: any, index: number, array: Array<any>) => {
                if (link.name == 'Manage Users' && !hasManageUsersAccess) {
                    array.splice(index, 1);
                }
                if (link.name == 'New Loan' && !hasNewLoanAccess) {
                    array.splice(index, 1);
                }
                // if (link.name == 'Manage Vendors') {
                //     array.splice(index, 1);
                // }
            });
        }

        if (type == 'WemloProcessor'){
            const rights: Array<any> = data.access ? data.access : [];
            let hasManageUsersAccess = rights.find(r => r.component == 'list-processor-users' && r.canAccess);
            let managerRoutes = ["Processors", "Compliance"];

            links = links.filter((link: any, index: number, array: Array<any>) => {
                if (!hasManageUsersAccess && managerRoutes.includes(link.name)) {
                    return false;
                }

                return true;
            });
        }
        commit('SET_SIDE_NAV_LINKS', links);

    },

    async saveQuestionnaireHistory({ commit }: any, data) {
        try {
            await Axios.post(BASE_API_URL + 'pos/saveQuestionnaireHistory', data);
        } catch (error) { }
    },

    resetPosData({ commit }: any) {
        commit('RESET_POS_DATA')
    },

    async pipelineData({ commit }, data) {
        try {
            commit('SET_PIPELINE_DATA', data)
        } catch (error) {
            console.log(error);
        }
    },

    async pipelineSearchData({ commit }, data) {
        try {
            commit('SET_PIPELINE_SEARCH_DATA', data)
        } catch (error) {
            console.log(error);
        }
    },

    async setTwilioToken({ commit }, data) {
        try {
            commit('SET_TWILIO_TOKEN', data)
        } catch (error) {
            console.log(error);
        }
    },

    async removeLocalFiles({ commit }, data) {
        await Axios.post(BASE_API_URL + 'common/removeS3Files', data)
        commit('REMOVE_LOCAL_FILES')
    },

    async emptyLocalFiles({ commit }) {
        commit("REMOVE_LOCAL_FILES");
    },

    async setLocalFiles({ commit }, data) {
        commit('SET_LOCAL_FILES', data);
    },
    setFnmUrl({ commit }: any, data: any) {
        commit('SET_FNM_URL', data)
    },
    async setIdentityAssumedMode({ commit }, data) {
        commit('SET_IDENTITY_ASSUMED_MODE', data);
    },
    async setCopySuperAdminSessionObject({ commit }, data) {
        commit('SET_COPY_SUPER_ADMIN_SESSION_OBJECT', data);
    },
    async setUserInfoForIdentityAssumedMode({ commit }, data) {
        commit('SET_USER_INFO_FOR_IDENTITY_ASSUMED_MODE', data);
    },
}

async function imageCordinates (imageData) {
    let image = new Image();
    image.src = imageData;
    let returnData = { height: 0, width: 0 }
    let promise =  new Promise<void>((resolve, reject)=>{
        image.onload = () => {
            if (image.height >= image.width) {
                returnData.width = image.width / 3;
                returnData.height = image.height / 3;
            } else {
               returnData.width = image.width / 2.2;
                returnData.height = image.height / 2;
            }
            resolve();
        }
    })
    await Promise.resolve(promise);
    return returnData;
}

function removeWehelp(types, field) {
    let index;
    index = types[field].findIndex(el => el.name === 'WeHelp List');
    types[field].splice(index, 1);
}