import store from '@/store/store';
import Axios from 'axios';
import { BASE_API_URL, setInstanceVariables } from '@/config';
import { PreRequisiteModel } from '@/models/pre-requisite.model';
import {isStepsNotComplete, getOnboardingStep} from "@/constants";

export const getAuthHeader = () => {
    let state: any = store.state
    return { Authorization: state.sessionObject.token }
}

export async function verifyMailVerificationToken(data) {
    try {
        let response = await Axios.post(
            BASE_API_URL + "authentication/verifyMailVerificationToken",
            {
                token: data.token,
                needUserInfo: data.needUserInfo
            }
        );
        if (response.data) {

            store.dispatch("setSession", response.data);
            if (data.needUserInfo) {
                /**
             * Dispatch the information of the user which holds the access rights on to components
                * after onboarding/login.
                */
                await store.dispatch("setSidenavLinks", response.data);
                await setInstanceVariables();
            }
            return true;
        } else {
            store.dispatch("clearSession");
            return false;
        }
    } catch (error) {
        return false;
    }
}

export async function divertRoute(router: any, routerToken) {

    let state: any = store.state;
    let nextRouteName = "dashboard";
    let userType = state.sessionObject.type.toLowerCase()
    if (state.sessionObject.type == "Broker" || state.sessionObject.type == "MLO") {
        if (routerToken && routerToken.meta.title == 'EmailReply') {
            let response = await Axios.post(
                BASE_API_URL + "authentication/verifyEmailReplyToken",
                {
                    token: routerToken.query.token, id: routerToken.query.id
                }
            );
            if (response.data.length > 0 && response.data[0].to[0].userId == state.sessionObject.userId) {
                let responseRemoveToken = await Axios.post(
                    BASE_API_URL + "authentication/removeEmailReplyToken",
                    {
                        token: routerToken.query.token, id: routerToken.query.id
                    }
                );
                if (responseRemoveToken.data.message == 'success')
                    return router.replace(`/${userType}/pipeline?id=${response.data[0].loanTxnId}`);
            }
        }
        if (state.sessionObject.type == "Broker")
            nextRouteName = state.sessionObject.userInfo && getOnboardingStep(state) == 'onboarding' ? "Broker" : "Prerequisites";
        else
            nextRouteName = state.sessionObject.userInfo && getOnboardingStep(state) == 'agreement' ? "MLO" : "MLOPrerequisites";
    }
    else if (state.sessionObject.type == "Borrower") {
        // Do we not need to verify the eail token for borrower ????

        if (routerToken && routerToken.meta.title == 'EmailReply') {
            await Axios.post(
                BASE_API_URL + "authentication/removeEmailReplyToken",
                {
                    token: routerToken.query.token,
                    id: routerToken.query.id
                }
            );
        }
        if(state.sessionObject.borrowerRoute)
            return router.replace({ path: state.sessionObject.borrowerRoute });
        else if(state.sessionObject && state.sessionObject.userInfo){
            let response = await Axios.post(
                 BASE_API_URL + "authentication/getBorrowerRoute",
                 {
                        borrowerId : state.sessionObject.userId,
                        loanTxnId : state.sessionObject.userInfo.loanTxnId
                 });
            if (response.status == 200)
                return router.replace({ path: response.data });
        }
    }
    else if (state.sessionObject.type == "Admin Staff") {
        nextRouteName = "AdminStaff";
    } else if (state.sessionObject.type == "Wemlo Processor") {
        nextRouteName = "Processor";
    } else nextRouteName = "SuperAdmin";

    router.replace({ name: nextRouteName });
}


// export async function divertRoute(router: any, routerToken) {
//     let state: any = store.state;
//     let preRequisite: any = new PreRequisiteModel();
//     if (state.sessionObject.type == "Broker" && state.sessionObject.hasOwnProperty('userInfo')) {
//         if (routerToken && routerToken.meta.title == 'EmailReply') {
//             let response = await Axios.post(
//                 BASE_API_URL + "authentication/verifyEmailReplyToken",
//                 {
//                     token: routerToken.query.token
//                 }
//             );
//             if (response.data.length > 0 && response.data[0].to[0].userId == state.sessionObject.userId) {
//                 let responseRemoveToken = await Axios.post(
//                     BASE_API_URL + "authentication/removeEmailReplyToken",
//                     {
//                         token: routerToken.query.token
//                     }
//                 );
//                 if (responseRemoveToken.data.message == 'success')
//                     router.push("/broker/pipeline?id=" + response.data[0].loanTxnId);
//                 else
//                     router.push({ name: "Broker" });
//             } else
//                 router.push({ name: "Broker" });
//         } else
//             router.push({ name: "Broker" });
//     } else {
//         if (state.sessionObject.type == "MLO") {
//             if (routerToken && routerToken.meta.title == 'EmailReply') {
//                 let response = await Axios.post(
//                     BASE_API_URL + "authentication/verifyEmailReplyToken",
//                     {
//                         token: routerToken.query.token,
//                         id: routerToken.query.id

//                     }
//                 );
//                 if (response.data.length > 0 && response.data[0].to[0].userId == state.sessionObject.userId) {
//                     let responseRemoveToken = await Axios.post(
//                         BASE_API_URL + "authentication/removeEmailReplyToken",
//                         {
//                             token: routerToken.query.token,
//                             id: routerToken.query.id

//                         }
//                     );
//                     if (responseRemoveToken.data.message == 'success')
//                         router.push("/mlo/pipeline?id=" + response.data[0].loanTxnId);
//                     else
//                         router.push({ name: "MLO" });
//                 } else
//                     router.push({ name: "MLO" });
//             } else
//                 router.push({ name: "MLO" });
//         }
//         else if (state.sessionObject.type == "Borrower") {
//             if (routerToken && routerToken.meta.title == 'EmailReply') {
//                 await Axios.post(
//                     BASE_API_URL + "authentication/removeEmailReplyToken",
//                     {
//                         token: routerToken.query.token,
//                         id: routerToken.query.id

//                     }
//                 );
//                 router.push({ name: "Borrower" })
//             }
//             else
//                 router.push({ name: "Borrower" })
//         }
//         else if (state.sessionObject.type == "Admin Staff")
//             router.push({ name: "AdminStaff" })
//         else if (state.sessionObject.type == "Super Admin")
//             router.push({ name: "SuperAdmin" })
//         else if (state.sessionObject.type == "Wemlo Processor")
//             router.push({ name: "Processor" })
//     }
// }

export async function checkIfPreRequisiteCompleted(router: any, to: any, from: any) {
    let state: any = store.state;
    let preRequisite: any = new PreRequisiteModel();
    if ((state.sessionObject.type == "Broker") && state.sessionObject.hasOwnProperty('userInfo')) {
        if (state.sessionObject.userInfo.hasOwnProperty("completedPreRequisites") && isStepsNotComplete(getOnboardingStep(state), 'onboarding')) {
            let nextStep = Number(state.sessionObject.userInfo.completedPreRequisites) + 1;
            let nextRoute: any = preRequisite.steps["step" + nextStep];
            if (to.name != nextRoute) {
                router.push({ name: nextRoute });
            } else {
                return;
            }
        } else {
            if ((Object.values(preRequisite.steps).includes(to.name) && to.name != 'BrokerDashBoard') || (to.name == 'Information')) {
                if (from.name != null) {
                    router.push({ name: from.name });
                } else {
                    router.push({ name: 'BrokerDashBoard' })
                }
            }
        }
    }
}
