// export class TransactionDetail {

//     constructor() {
//         this.borrowerInfo = [new Detail()];
//         this.coBorrowerInfo = [new Detail()];
//     }
//     public loanTxnId: string;
//     public borrowerInfo: Array<Detail>;
//     public coBorrowerInfo: Array<Detail>;
// }

export class TransactionDetail {
    constructor() {
        // this.purchasePrice = null;
        // this.alternationImprovementRepair = null;
        // this.closingCostPaidBySeller = null;
        // this.closingCosts = null;
        // this.discount = null;
        // this.fundingFee = null;
        // this.fundingFeeFinanced = null;
        // this.land = null;
        // this.loanAmount = null;
        // this.prepaidItem = null;
        // this.refinance = null;
        // this.subOrdinateFinancing = null;
        this.otherCredits = [new OtherCredit()];
    }
    public loanTxnId: string;
    public purchasePrice: number;
    public alternationImprovementRepair: number;
    public land: number;
    public refinance: number;
    public prepaidItem: number;
    public closingCosts: number;
    public fundingFee: number;
    public discount: number;
    public subOrdinateFinancing: number;
    public closingCostPaidBySeller: number;
    public otherCredits: Array<OtherCredit>;
    public loanAmount: number;
    public fundingFeeFinanced: number;
}

export class OtherCredit {
    constructor() {
        this.amount = 0;
        this.creditType = null;
    }
    public amount: number;
    public creditType: object;
}

