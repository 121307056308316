import { Address } from './common.model';
export class FinancialInfo {
    constructor() {
        this.assets = [new Assets()];
        this.liabilities = [new Liabilities()];
        this.realEstate = new RealEstate();
        this.financialInfo =new FinancialInformation
        // this.borrowerInfo = [new FinancialDetails()];
        // this.coBorrowerInfo = [new FinancialDetails()];
    }
    public loanTxnId: string;
    public assets: Array<Assets>;
    public liabilities: Array<Liabilities>;
    public realEstate: RealEstate;
    public financialInfo:FinancialInformation;

    // public borrowerInfo: Array<FinancialDetails>;
    // public coBorrowerInfo: Array<FinancialDetails>;
}

export class FinancialInformation {
    constructor() {
        this.automobileOwned = [new AutomobileOwnedDetail()];
        this.cashDepositTowardsPurchase = new CashDepositTowardsPurchase()
        this.totalMonthlyExpense = 0;
        this.totalProposedMonthlyExpense = 0;
        this.assetsAndLiabilitiesSameAsBorrower = false;
        this.realEstateSameAsBorrower = false;
    }
    public automobileOwned: Array<AutomobileOwnedDetail>;
    public assetsAndLiabilitiesSameAsBorrower: boolean;
    public realEstateSameAsBorrower: boolean;
    public totalMonthlyExpense: number;
    public totalProposedMonthlyExpense: number;
    public cashDepositTowardsPurchase: CashDepositTowardsPurchase;
    public subTotalLiquidAsset: number;
    public realEstateOwnedMarketValue: number;
    public netWorthBusinessOwned: number;
    public refusedOtherAssetsInformation: boolean;
    public refusedLiabilitiesInformation: boolean;
    public refusedOtherLiabilitiesAndExpenseInformation: boolean;
}

export class FinancialDetails {
    constructor() {
        this.assetsAndLiabilities = new AssetsAndLiabilities();
        this.realEstate = new RealEstate();
        this.totalMonthlyExpense = 0;
        this.totalProposedMonthlyExpense = 0;
        this.assetsAndLiabilitiesSameAsBorrower = false;
        this.realEstateSameAsBorrower = false;
    }
    public assetsAndLiabilitiesSameAsBorrower: boolean;
    public realEstateSameAsBorrower: boolean;
    public totalMonthlyExpense: number;
    public totalProposedMonthlyExpense: number;
    public assetsAndLiabilities: AssetsAndLiabilities;
    public realEstate: RealEstate;
}

class AssetsAndLiabilities {
    constructor() {
        this.automobileOwned = [new AutomobileOwnedDetail()];
        this.assets = [new Assets()];
        this.otherAssets = [new OtherAssets()];
        this.liabilities = [new Liabilities()];
        this.otherLiabilitiesAndExpenses = [new OtherLiabilitiesAndExpenses()];
        this.cashDepositTowardsPurchase = new CashDepositTowardsPurchase()
    }
    public automobileOwned: Array<AutomobileOwnedDetail>;
    public assets: Array<Assets>;
    public otherAssets: Array<OtherAssets>;
    public liabilities: Array<Liabilities>;
    public otherLiabilitiesAndExpenses: Array<OtherLiabilitiesAndExpenses>;
    public cashDepositTowardsPurchase: CashDepositTowardsPurchase;
    public subTotalLiquidAsset: number;
    public realEstateOwnedMarketValue: number;
    public netWorthBusinessOwned: number;
    public refusedOtherAssetsInformation: boolean;
    public refusedLiabilitiesInformation: boolean;
    public refusedOtherLiabilitiesAndExpenseInformation: boolean;

}

export class AutomobileOwnedDetail {
    constructor() {
        this.year = null;
        this.model = null;
        this.cashOrMarketValue = 0;
    }
    public year: string;
    public model: string;
    public cashOrMarketValue: number;
}

export class Assets {
    constructor() {
        this.accountNumber = null;
        this.accountType = null;
        this.cashOrMarketValue = 0;
        this.financialInstitution = null;
        this.lifeInsuranceFaceAmount = 0;
        this.stockAndBondsDecription = null;
        this.address = new Address();
        this.borrowerIndex = 0;
        this.otherDescription = null;
        this.assetIndex = null;
    }
    public borrowerIndex: number;
    public assetsMapping: Array<AssetsMapping>;
    public accountType: object;
    public financialInstitution: string;
    public accountNumber: string;
    public cashOrMarketValue: number;
    public address: Address;
    public lifeInsuranceFaceAmount: number;
    public stockAndBondsDecription: string;
    public otherDescription: object;
    public assetIndex: number;
}

class AssetsMapping {
    public borrowerIndex : number;
    public jointStatus : boolean;

}
export class OtherAssets {
    constructor() {
        this.assetType = null;
        this.borrowerIndex = 0;
        this.cashOrMarketValue = 0;
        this.otherDescription = null;
        this.assetIndex = null;
    }
    public assetType: object;
    public borrowerIndex: number;
    public cashOrMarketValue: number;
    public otherDescription: object;
    public assetsMapping: Array<AssetsMapping>;
    public assetIndex: number;
}

export class Liabilities {
    constructor() {
        this.unpaidBalance = new UnpaidBalance();
        this.accountType = null;
        this.companyName = null;
        this.monthlyPayment = 0;
        this.monthsLeftToPay = null;
        this.accountNumber = null;
        this.address = new Address();
        this.borrowerIndex = 0;
        this.reoAssetId = null;

    }
    public borrowerIndex: number;
    public liabilitiesMapping: Array<AssetsMapping>;
    public accountType: object;
    public companyName: string;
    public unpaidBalance: UnpaidBalance;
    public monthlyPayment: number;
    public monthsLeftToPay: string
    public address: Address;
    public accountNumber: string;
    public reoAssetId :number
}

class UnpaidBalance {
    constructor() {
        this.amount = 0;
        this.maxAmount = 0;
        this.toBePaidAtOrOffClosing = false;
        this.toBeExclude=false;
        this.toBeReSub=false;
        this.payOffAmount = 0;
        this.excludeType = null;
    }
    public amount: number;
    public maxAmount: number;
    public toBePaidAtOrOffClosing: boolean;
    public toBeExclude : boolean;
    public toBeReSub:boolean;
    public payOffAmount: number;
    public excludeType: string;
}

export class OtherLiabilitiesAndExpenses {
    constructor() {
        this.name = null;
        this.monthsLeftToPay = null;
        this.monthlyPayment = 0;
    }
    public name: object;
    public monthlyPayment: number;
    public monthsLeftToPay: string
}

class RealEstate {
    constructor() {
        this.properties = [new Properties()];
    }
    public properties: Array<Properties>;
    public haveNotRealEstate: boolean
}

export class Properties {
    constructor() {
        this.address = new Address();
        this.mortgageLoans = [];
        this.propertyValue = 0;
        this.status = null;
        this.propertyType = null;
        this.currentProperty=false;
        this.subjectProperty=false;
        this.maintenanceExpenseAmount = 0;
        this.rentalIncomeGrossAmount = 0;
        this.rentalIncomeNetAmount = 0;
        this.numberOfUnits = null;
        this.reoSameAsSubjectProperty = false;
        this.reoSameAsCurrnetAddress = false;
    }
    public address: Address;
    public propertyValue: number;
    public status: object;
    public propertyType: object;
    public maintenanceExpenseAmount: number;
    public rentalIncomeGrossAmount: number;
    public rentalIncomeNetAmount: number;
    public numberOfUnits: number;
    public mortgageLoans: Array<MortgageLoans>;
    public refusedMortgageInformation: boolean
    public currentProperty:boolean;
    public subjectProperty:boolean;
    public reoSameAsSubjectProperty:boolean;
    public reoSameAsCurrnetAddress:boolean;
    public reoMapping: Array<REOMapping> = [];
}



class MortgageLoans {
    constructor() {
        this.unpaidBalance = new UnpaidBalance();
        this.creditorName = null;
        this.accountNumber = null;
        this.monthlyPaymentAmount = 0;
        this.proposedMonthlyPaymentAmount = null;
        this.mortgageType = null;
        this.creditLimit = 0;
        this.currentFirstMortgageHolderType = null;
    }
    public creditorName: string;
    public accountNumber: string;
    public monthlyPaymentAmount: number;
    public proposedMonthlyPaymentAmount: number;   //For current Form
    public unpaidBalance: UnpaidBalance;
    public mortgageType: string;
    public creditLimit: number;
    public currentFirstMortgageHolderType: string;
}

class CashDepositTowardsPurchase {
    constructor() {
        this.cashOrMarketValue = 0;
    }
    public heldBy: string;
    public cashOrMarketValue: number
}

class REOMapping {
    public borrowerIndex: number;
    public jointStatus: boolean;
}
