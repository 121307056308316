export class Declaration {
    constructor() {
        this.borrowerInfo = [new DeclarationDetails()];
        // this.coBorrowerInfo = [new DeclarationDetails()];   Not used in Sprint 5
    }
    public loanTxnId: string;
    public borrowerInfo: Array<DeclarationDetails>;
    // public coBorrowerInfo: Array<DeclarationDetails>;    Not used in Sprint 5
}

class AffiliationSellerProperty {
    constructor(){
        this.hasAffiliationSellerProperty = null;
        this.hasGiftedTransaction = null;
    }
    hasAffiliationSellerProperty : string;
    hasGiftedTransaction: string;

}

export class DeclarationDetails {
    constructor() {
        this.propertyType = new PropertyType();
        this.borrowedForDownpayment = new BorrowedForDownpayment();
        this.bankruptcyDeclaration = new BankruptcyDeclaration();
        this.everTransferOftitleDuetoLoan = new EverTransferTitle();
        this.outstandingJudgements = new outstandingJudgements();
        this.conveyedTitleToAnyProperty = new conveyedTitleToAnyProperty();
        this.partyInLawsuit = new partyInLawsuit();
        this.federalDebt = new federalDebt();
        this.childAlimonySeperateSupport = new childAlimonySeperateSupport();
        this.coSigner =  new coSigner();
        this.affiliationSellerProperty = new AffiliationSellerProperty();
    }
    public propertyType: PropertyType;
    public borrowedForDownpayment: BorrowedForDownpayment;
    public applyForLoanInAnotherProperty: string;
    public applyForOtherCredit: string;
    public isSubjectToLien: string;
    public childAlimonySeperateSupport: childAlimonySeperateSupport;
    public coSigner: coSigner;
    public outstandingJudgements: outstandingJudgements;
    public federalDebt: federalDebt;
    public partyInLawsuit: partyInLawsuit;
    public conveyedTitleToAnyProperty: conveyedTitleToAnyProperty;
    public haveCompletedPreForeClosureSale: string;
    public hadForeClosedProperty: string;
    public bankruptcyDeclaration: BankruptcyDeclaration;
    public isMortgageLoan: string;
    public isCreditCard: string;
    public isMortgageLien: string;
    public affiliationSellerProperty: AffiliationSellerProperty
    public outstandingMortgage: string;
    public everTransferOftitleDuetoLoan: EverTransferTitle
    public isUSCitizen: string;
    public isPermanentResidentAlien: string;

}
class coSigner{
    constructor(){
        this.ediValue = "99";
        this.isCoSigner = null;
        this.explanation = null;
        this.mismoValue= "Co-Maker or Endorser on a note";

    }
    public ediValue:string;
    public isCoSigner:string;
    public mismoValue:string;
    public explanation:string;
}

class childAlimonySeperateSupport{
    constructor(){
        this.ediValue = "97";
        this.isChildAlimonySeperateSupport = null;
        this.explanation = null;
        this.mismoValue= "Child Support Maintenance";

    }
    public ediValue:string;
    public isChildAlimonySeperateSupport:string;
    public mismoValue:string;
    public explanation:string;
}

class federalDebt {
    constructor(){
        this.ediValue = "96";
        this.hasFederalDebt = null;
        this.explanation = null;
        this.mismoValue= "Delinquency or Default";
        this.delinquencyDate = null;

    }
    public ediValue:string;
    public hasFederalDebt:string;
    public mismoValue:string;
    public explanation:string;
    public delinquencyDate: string;
}

class partyInLawsuit {
    constructor(){
        this.ediValue = "94";
        this.isPartyInLawsuit = null;
        this.explanation = null;
        this.mismoValue= "Party to Lawsuit";
    }
    public ediValue:string;
    public isPartyInLawsuit:string;
    public mismoValue:string;
    public explanation:string;
}

class conveyedTitleToAnyProperty{
    constructor(){
        this.ediValue = "93";
        this.hasOutstandingJudgements = null;
        this.explanation = null;
        this.mismoValue= "Foreclosed Property in the past 7 years";

    }
    public ediValue:string;
    public hasOutstandingJudgements:string;
    public mismoValue:string;
    public explanation:string;
}
class outstandingJudgements { 
    constructor(){
        this.ediValue = "91";
        this.hasOutstandingJudgements = null;
        this.explanation = null;
        this.mismoValue= "Outstanding Judgement";

    }
    public ediValue:string;
    public hasOutstandingJudgements:string;
    public mismoValue:string;
    public explanation:string;
 }

class PurchasedTransaction {
    constructor() {
        this.detail = null;
        this.transfer = 'false';
    }
    public transfer: string;
    public detail: string;
}
class PropertyType {
    constructor() {
        this.typeOfProperty = null;
        this.typeOfPropertyTitle = {};
    }
    public isPrimaryResidence: string;
    public ownershipInPast: string;
    public typeOfProperty: object;
    public typeOfPropertyTitle: object;
}

class BorrowedForDownpayment {
    constructor() {
        this.explanation = null;
        this.ediValue = "98";
        this.mismoValue = "Part of DownPayment"
        this.typeOfDownPayment = null;
        this.hasBorrowed= null;
    }
    public ediValue:string;
    public mismoValue:string;
    public hasBorrowed: string;
    public explanation: number;
    public typeOfDownPayment: string;
}

class BankruptcyDeclaration {
    constructor() {
        this.typesOfBankruptcy = new Array<string>();
        this.ediValue = "92";
        this.mismoValue = "DeclaredBankruptcyInPast7Years"
    }
    public ediValue:string;
    public mismoValue: string;
    public haveDeclaredBankruptcy: string;
    public typesOfBankruptcy: Array<string>;
}

class EverTransferTitle {
    constructor() {
        this.ediValue = "95";
        this.mismoValue = "Obligated on a loan foreclosed"
        this.detail = null;
    }
    ediValue:string;
    mismoValue:string;
    transfer: string;
    detail: string;
}
