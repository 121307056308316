import optimizelySDK from '@optimizely/optimizely-sdk';
import logger from '@optimizely/optimizely-sdk/lib/plugins/logger';
import { LOG_LEVEL } from '@optimizely/optimizely-sdk/lib/utils/enums';
import config from '@/environments/index';

const sdkKey = config.OPTIMIZELY_KEY || 'WFHQHSxhfWTwBraRL7QoC';

let optimizelyReady = false;
let optimizelyClientInstance = null;

export function featureInit() {
    optimizelyClientInstance = optimizelySDK.createInstance({
        sdkKey,
        eventBatchSize: 10,
        eventFlushInterval: 1000,
        logger: logger.createLogger({
            /**
             * Show only warnings and errors and not infos
             */
            logLevel: LOG_LEVEL.WARNING,
        }),
    });
    
    optimizelyClientInstance.onReady().then(() => {
        optimizelyReady = true;
    });
}

/*
 * vue files should import the feature-flag class
 *
 *     import { featureEnabled } from '@/feature-flag';
 *
 * and use this function in their mounted() function, e.g.
 *
 *     this.disclosuresEnabled = await featureEnabled('disclosures');
 *
 * Then in the html, the v-if can be used to feature flag out elements, e.g.
 *     <div v-if="disclosuresEnabled">
 */
export async function featureEnabled(name: string, userId?: string): Promise<boolean> {
    if (!userId) {
        userId = "all";
    }
    
    return new Promise((resolve, reject) => {
        if (!optimizelyReady) {
            optimizelyClientInstance.onReady().then(() => {
                const enabled = optimizelyClientInstance.isFeatureEnabled(name, userId);
                resolve(enabled);
            });
        } else {
            const enabled = optimizelyClientInstance.isFeatureEnabled(name, userId);
            resolve(enabled);
        }
    });
}
