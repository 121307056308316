import Axios from "axios";
import Vue from 'vue';
import { EventBus } from './config';
import { getAuthHeader } from "@/services/auth.service";
import _ from 'lodash';

const errorHandler = (error: { response: any; }) => {
  const { response = {} } = error;
  const { status } = response;

  EventBus.$emit('hideLoader');

  if (_.isEmpty(response)) {
    EventBus.$emit('notifyError', 'We are unable to connect to server. Please try after sometime.');
  }

  switch (status) {
    case 400:
      badRequest(response);
      break;
    case 401:
      unauthorized();
      break;
    case 403:
      EventBus.$emit('notifyError', error.response.data.message || 'You have no permissions to perform this action. Please, contact Admin to solve this issue.');
      break;
    case 404:
      EventBus.$emit('notifyError', error.response.data.message);
      break;
    case 500:
      EventBus.$emit('notifyError', 'Something went wrong! Please contact Admin.');
      break;
    default:
      break;
  }

  return Promise.reject(error);
}

const badRequest = ({ data }) => {
  const errors = _.get(data, 'errors');
  const errorMessage = _.get(data, 'message');

  if (_.isArray(errors)) {
    EventBus.$emit('notifyError', 'Request failed! Please check: ' + errors.join(", "));
  } else if (errorMessage) {
    EventBus.$emit('notifyError', errorMessage);
  }
}

const unauthorized = () => {
  Vue.prototype.$modal.show('reAuthenticationModal')
}

Axios.interceptors.response.use(
  response => response,
  errorHandler
);

Axios.interceptors.request.use((request) => {
  if (!request.headers.public) {
    request.headers = { ...getAuthHeader(), ...request.headers };
  } else {
    delete request.headers.public;
  }
  return request;
});