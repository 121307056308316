











import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";

import Axios from "axios";

@Component
export default class Loader extends Vue {
//SETTING WEMLO LOADER CLASS DATA==================================================================
  public get wemloLoader():any{
      return this.$store.state.wemloLoader;
  }

//MOUNTED FUNCTION FOR FURTHER USAGE===============================================================
  mounted(){
        // this.$store.state.wemloLoader=false;
  }
}
//VUE CLASS ENDED==================================================================================
