import LogRocket from 'logrocket';
import _ from 'lodash';

export function identifyUserSession(data: any) {
  const { email, userId, userInfo } = data;
  const name = userInfo ? _.trim(userInfo.firstName + " " + userInfo.lastName) : "Super Admin";

  LogRocket.identify(userId, {
    name,
    email,
  });
}
