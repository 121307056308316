
























import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";


@Component({})
export default class ConsentToContact extends Vue {

    checked: boolean = false;

    get valid() { return this.checked; }

    async consentToContact() {

        try {
            this.$store.state.wemloLoader = true;

            const { data: { success } } = await Axios.post(BASE_API_URL + "borrower/consentToContact", {});
            console.log(success);

            if (success) {
                this.$store.dispatch('updateConsentToContact', true);
                this.$emit('actionComplete');
            }
        } finally {
            this.$store.state.wemloLoader = false;
        }
    }
}
