import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL, PHONE_EXT } from '@/config';
import Axios from 'axios';

import stores from '../store/store'
const store: any = stores.state;
import saveAs from "save-as";
import print from 'print-js'
import _ from "lodash";


@Component
export default class CommonMixin extends Vue {
    public showMessageError = false;
    public message = null;
    public exportInProgress = false;
    public callLogs = [];
    public notificationLength = null;
    public file = [];
    public errorSendFileMessage = null;
    //=============================================================================================
    public downloadFilesArray = [];
    public zipDownloadProgress = 'Zip';
    public singleDownloadProgress = 'Single';
    public setBatchToLenderData: any = {}
    //=============================================================================================
    public inProcessForMessage = false;
    public exportFnmEnable = false;
    public brokerId = null;
    public mloId = null;
    public brokerPhone = null;
    public mloPhone = null;
    public defaultType= "password"
    public defaultType2= "password"

    public capitalizeFirstCharacter(string: string) {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
    }

    //Updating pre-requisites on previous button
    public async modifyPreRequisite(num: any) {
        let userId = this.$store.state.sessionObject.userId;
        let userType = this.$store.state.sessionObject.userType;
        if (!userType)
            userType = this.$store.state.sessionObject.type;
        await this.$store.dispatch("updatePreRequisiteOnServer", { userId: userId, userType: userType, num: num, changeInState: true });
    }


    /**Data must have the object like
     * {
     * toUserType:toUserType,
     * loanTxnId:loanTxnId 
     * }
     *  */
    public async sendMessage(data) {
        let self = this;

        function resetMessage() {
            self.message = '';
            self.file = []

            try {
                const textEditorComponent: any = self.$refs.texteditor;
                textEditorComponent.removeAllFiles();
            } catch (error) { }
        }

        try {
            this.inProcessForMessage = true;
            let valid = await this.$validator.validateAll("message");
            if (!valid && !data.message) {
                this.showMessageError = true;
                setTimeout(() => {
                    this.showMessageError = false;
                }, 1000);
                this.inProcessForMessage = false;
                return;
            }
            let formData = new FormData();

            Object.values(data.file ? data.file : this.file).forEach((element: any) => {
                formData.append("file", element)
            });
            let obj = {
                contacts: data.contacts,
                type: data.type ? data.type : null,
                toUserId: data.toUserId ? data.toUserId : null,
                toUserType: data.toUserType ? data.toUserType : null,
                message: data.message ? data.message : this.message,
                loanTxnId: data.loanTxnId,
                fromType: this.$store.state.sessionObject.type,
                borrowerName: data.borrowerName ? data.borrowerName : null,
                notificatioModuleId: data.notificatioModuleId
            };
            formData.append("data", JSON.stringify(obj))
            let response = await Axios.post(
                BASE_API_URL + "message/addMessage",
                formData);
            if (response.data.error != null && response.data.error.checkEmail == "emailNotExist") {
                this.inProcessForMessage = false;
                this.$snotify.error(`No Email is added by ${response.data.error.name}`);
                resetMessage();
                return;
            }
            if (response.data.emailFailureUser.length > 0) {
                let emailFailureUserName = ""
                let emailFailureUser = response.data.emailFailureUser
                for (let index = 0; index < emailFailureUser.length; index++) {
                    emailFailureUserName += emailFailureUser[index] + " "
                }
                this.inProcessForMessage = false;
                this.$snotify.error("Mail is not sent to" + " " + emailFailureUserName)
                resetMessage();
                return;
            }

            if (
                response.status == 200 &&
                response.data.communicationData.to.length > 0
            ) {
                this.inProcessForMessage = false;
                resetMessage();
                if (data.hasOwnProperty('isCalledFromDashboard') && data.isCalledFromDashboard) {
                    return response.data.communicationData;
                } else if (!data.hasOwnProperty('isCalledFromPopup') || (data.hasOwnProperty('isCalledFromPopup') && !data.isCalledFromPopup)) {
                    this.callLogs.unshift(response.data.communicationData);
                    this.notificationLength = this.callLogs.length;
                }
                this.$snotify.success("Your message has been sent ");
            } else {
                this.inProcessForMessage = false;
                this.$snotify.error("Email cannot be sent ");
                resetMessage();
            }
            return;
        } catch (error) {
            this.inProcessForMessage = false;
            console.log(error, "error");
            self.errorSendFileMessage = _.get(error, 'response.data.message', '');
        }
    }

    /**
     * 
     * @param borrower  detail form we have fetch [data] for pipeline list
     * @param fieldName  data variable name
     */
    public getLoanIno(borrower, fieldName) {
        switch (fieldName) {
            case "names":
                let borrowerFirstName = borrower.borrowerName ? borrower.borrowerName.firstName : borrower.borrowerName.firstName;
                let borrowerLastName = borrower.borrowerName ? borrower.borrowerName.lastName : borrower.borrowerName.lastName;
                return borrowerLastName ?
                    this.capitalizeFirstCharacter(borrowerFirstName) +
                    " " +
                    this.capitalizeFirstCharacter(borrowerLastName)
                    : this.capitalizeFirstCharacter(borrowerFirstName)
                break;
            case "name":
                let firstName = borrower.loanInfo.length > 0 ? borrower.loanInfo[0].borrowerInfo[0].personalInfo.name.firstName : borrower.basicInformation.borrower.firstName;
                let lastName = borrower.loanInfo.length > 0 ? borrower.loanInfo[0].borrowerInfo[0].personalInfo.name.lastName : borrower.basicInformation.borrower.lastName
                return lastName ?
                    this.capitalizeFirstCharacter(firstName) +
                    " " +
                    this.capitalizeFirstCharacter(lastName)
                    : this.capitalizeFirstCharacter(firstName)
                break;

            case "address":
                let address =
                    borrower.loanInfo.length > 0
                        ? borrower.loanInfo[0].borrowerInfo[0].personalInfo.address
                            .currentAddress.address
                        : borrower.basicInformation.borrower.address;
                return (
                    address.street +
                    " " +
                    address.city +
                    " " +
                    address.country +
                    ", " +
                    address.zip
                );
                break;
        }
    }

    /**
     * 
     * @param identifier whcih file we have to generate like fnm or xml
     * @param loanTxnId against the loan transaction-ID
     */
    public async generateFannieMaeFile(identifier, loanTxnId) {
        this.$store.state.wemloLoader = true;
        try {
            let response = await Axios.get(
                BASE_API_URL + "pos/generateFannieMaeFile",
                {
                    params: {
                        loanTxnId: loanTxnId,
                        identifier: identifier
                    },
                    responseType: "blob"
                }
            );
            saveAs(response.data, 'WeMLO_' + loanTxnId + '.zip');

            this.$store.state.wemloLoader = false;
            // setTimeout(async () => {
            //     if (response.data) {
            //         store.displayFiles.downloadFilesArray = [s3filePath];
            //         await this.downloadDocs(false);
            //         this.$store.state.wemloLoader = false;
            //     }
            // }, 1000);

        } catch (error) {
            this.$store.state.wemloLoader = false;
            console.log(error);
            // this.$snotify.error("Something went wrong, please try again later");
        }
    }
    /**
     * 
     * @param loanTxnId against the loan transaction-ID
     */
    public async generateMismoFile(loanTxnId, extensions) {
        this.$store.state.wemloLoader = true;

        try {
            const { data: zip } = await Axios.get(
                BASE_API_URL + "mismo/getMismoFile",
                {
                    params: { loanTxnId, extensions },
                    responseType: "blob"
                }
            );

            saveAs(zip, 'WeMLO_' + loanTxnId + '.zip');

            this.$store.state.wemloLoader = false;
        } catch (error) {
            this.$store.state.wemloLoader = false;
            console.log(error);
        }
    }

    async getCheckSsnForBorrower(loanTxnId) {
        try {
            let response = await Axios.post(
                BASE_API_URL + "pos/checkSsnForBorrower",
                { loanTxnId: loanTxnId }, {}
            );
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    public async checkSsnForBorrower(type, loanTxnId) {
        try {
            let haveSSn = await this.getCheckSsnForBorrower(loanTxnId);
            if (haveSSn.length > 0) {
                if (this.$refs.hasOwnProperty('ssnModal'))
                    this.$refs.ssnModal['showModal'](haveSSn);
            } else {
                await this.generateExportFile(type, loanTxnId, ['DU'])
            }
        } catch (error) {
            console.log(error);
        }
    }

    async showCriticalFieldsModal(modal) {
        if (!this.$refs.hasOwnProperty('criticalModal')) return
        this.$refs.criticalModal['showModal'](modal.title, modal.message);
    }
    
    public async checkCriticalFields(type, loanTxnId) {
        try {
            // Place to add checks for other critical fields
            let haveSSN = await this.getCheckSsnForBorrower(loanTxnId);
            if (haveSSN.length) return this.showCriticalFieldsModal({ title: 'SSN Required', message: 'This file could not be exported because SSN is missing.' })

            await this.generateExportFile(type, loanTxnId, ['DU'])
        } catch (error) {
            console.log(error);
        }
    }

    public async validateLOSForExport(type, loanTxnId, checkLosFormCompleted, extensions) {
        try {
            let response = await Axios.post(
                BASE_API_URL + "pos/checkSsnForBorrower",
                {
                    loanTxnId: loanTxnId,
                }
            );
            let haveSSn = response.data;
            if (haveSSn.length > 0) {
                if (this.$refs.hasOwnProperty('ssnModal')) {
                    this.$refs.ssnModal['showModal'](haveSSn);
                }
            } else if (type === 'mismo') {
            
                checkLosFormCompleted && checkLosFormCompleted(true, function() { 
                    this.generateExportFile(type, loanTxnId, extensions)
                }.bind(this))

            } else {
              await this.generateFannieMaeFile(type, loanTxnId);
            }
        } catch (error) {
            console.log(error);
        }
        // this.$store.state.wemloLoader = false
    }

    public async generateExportFile(type, loanTxnId, extensions){
        if (type === 'mismo'){
          await this.generateMismoFile(loanTxnId, extensions);
        } else {
          await this.generateFannieMaeFile(type, loanTxnId);
        }
    }

    public async exportExistingEdiFile(ediFileObj) {
        if (ediFileObj.length > 0) {
            this.$store.state.wemloLoader = true;
            let fnmFile = ediFileObj.map(a => {
                return a.path
            })
            store.displayFiles.downloadFilesArray = [];
            store.displayFiles.downloadFilesArray = fnmFile;
            await this.downloadDocs(false);
            this.$store.state.wemloLoader = false;
        }

    }


    public durationInFormat(duration) {
        if (duration > 3600) {
            let hour = Math.floor(duration / 3600);
            let min = Math.floor((duration % 3600) / 60);
            let sec = Math.floor(duration - (hour * 3600 + min * 60));
            let minUnit = null;
            let hourUnit = null;
            let secUnit = null;
            if (min != 1) {
                minUnit = "minutes";
            } else {
                minUnit = "minute";
            }
            if (hour != 1) {
                hourUnit = "hours";
            } else {
                hourUnit = "hour";
            }
            secUnit = "sec";
            return (
                hour +
                " " +
                hourUnit +
                " " +
                min +
                " " +
                minUnit +
                " " +
                sec +
                " " +
                secUnit
            );
        } else if (duration > 60) {
            let min = Math.floor(duration / 60);
            let sec = Math.floor(duration % 60);
            let minUnit = null;
            let secUnit = null;
            if (min != 1) {
                minUnit = "minutes";
            } else {
                minUnit = "minute";
            }
            secUnit = "sec";
            return min + " " + minUnit + " " + sec + " " + secUnit;
        } else {
            let sec = Math.floor(duration);
            let secUnit = null;
            secUnit = "sec";
            return sec + " " + secUnit;
        }
    }

    public assignPipelineHeaderData(borrower, currentTask) {
        return {
            brokerName: borrower.brokerName,
            brokerId: borrower.brokerId,
            mloName: borrower.mloName,
            mloId: borrower.addedBy == borrower.brokerId ? null : borrower.addedBy,
            loanTxnId: borrower.loanTxnId,
            ediFile: borrower.ediFile ? borrower.ediFile : null,
            lenderId: borrower.lenderId,
            lenderName: borrower.lenderName,
            borrowerInfo: borrower.borrowerInfo,
            task: currentTask ? borrower.activeTask : borrower.loanStatus.taskName,
            createdOn: borrower.createdOn.slice(0, 10),
            exportFnmEnable: this.exportFnmEnable,
            loanNumber: borrower.loanNumber ? borrower.loanNumber : null,
            loanAmount: borrower.loanAmount,
            primaryBorrowerIndex: borrower.primaryBorrowerIndex,
            updatedClosingDate: borrower.updatedClosingDate,
            updatedRateLockDate: borrower.updatedRateLockDate,
            updatedRateExpirationDate: borrower.updatedRateExpirationDate,
            updatedRateLockExtensionDate: borrower.updatedRateLockExtensionDate,
            updatedRateType: borrower.updatedRateType
        };
    }




    public downloadCurrentReviewDocument(fileArray) {
        try {
            console.log('hello')
            store.displayFiles.downloadFilesArray = [];
            store.displayFiles.downloadFilesArray = fileArray;
            this.downloadDocs(true);
        } catch (error) {
            console.log(error);
        }
    }

    public async downloadDocs(checkZip) {
        this.$store.state.wemloLoader = true;
        try {
            store.displayFiles.zipDownloadProgress = 'Zipping Files...'
            let zipName = this.$route.query.id;
            if (!zipName) { zipName = "Documents" }
            let data = store.displayFiles.downloadFilesArray;
            let response = await Axios.post(BASE_API_URL + 'common/downloadFileS3',
                { check: checkZip, data: data, name: zipName }, { responseType: 'blob' })
            saveAs(response.data, zipName + `.zip`);
            store.displayFiles.singleDownloadProgress = 'Single';
            store.displayFiles.zipDownloadProgress = 'Zip';
            this.$store.state.wemloLoader = false;
            store.displayFiles.downloadFilesArray = [];
        } catch (error) {
            this.$store.state.wemloLoader = false;
            store.displayFiles.zipDownloadProgress = 'Zip';
            store.displayFiles.singleDownloadProgress = 'Single';
            console.log(error);
        }


    }

    public async printImage(file) {
        try {
            let ext = file.split('.').pop().toLowerCase();
            if (ext == 'pdf') {
                let loader = this.$loading.show();
                let body = { data: file }
                let response = await Axios.post(BASE_API_URL + 'common/downloadPDF',
                    body, { responseType: 'blob' })
                loader.hide();
                let printUrl = URL.createObjectURL(response.data);
                print(printUrl);
            }
            else if (ext == 'jpg' || ext == 'gif' || ext == 'jpeg' || ext == 'png') {
                let loader = this.$loading.show();
                let body = { data: file }
                let response = await Axios.post(BASE_API_URL + 'common/downloadPDF',
                    body, { responseType: 'blob' })
                loader.hide();
                let printUrl = URL.createObjectURL(response.data);
                print({ printable: printUrl, type: 'image', imageStyle: 'width:100%' });
            }
            else {
                this.$snotify.error('Printing is not supported on this document')
            }
        } catch (error) {
            console.log(error);
        }
    }
    public async sendStatusSmsToBroker(sms, loanTxnId) {
         try {
            // if (process.env.NODE_ENV == 'production') {
            let toUsers = []
            if (this.mloId) {
                toUsers.push({
                    number: PHONE_EXT + this.mloPhone.replace(/[()-\s]/g, ""),
                    userId: this.mloId,
                    type: 'MLO'
                })
            } else {
                toUsers.push({
                    number: PHONE_EXT + this.brokerPhone.replace(/[()-\s]/g, ""),
                    userId: this.brokerId,
                    type: 'Broker'
                })
            }
            let response = await Axios.post(
                BASE_API_URL + "communication/sendTextMessage",
                {
                    message: sms,
                    fromUserId: this.$userId,
                    fromType: "Wemlo Processor",
                    loanTxnId: loanTxnId,
                    toUsers: toUsers,
                    wemloNumber: null,
                    callingFrom: "loanTask"
                });

            if (response.data.info.status == 400) {
                this.$snotify.error("Broker Number Is Not Valid")
            }
            // }
        } catch (error) {
            console.log(error)
        }
    }

    public getPrimaryBorrowerName(borrower, primaryIndex, use) {
        try {
            if (use == 'pipelineHeader') {
                return borrower[primaryIndex].firstName + " " + borrower[primaryIndex].lastName;
            } else if (use == 'borrowerInfo') {
                return borrower[primaryIndex].personalInfo.name.firstName + " " + borrower[primaryIndex].personalInfo.name.lastName;
            }
        } catch (error) {
            console.log(error);
        }
    }

    public async updateProcessorAudiTrail(body) {
        try {
            let response = await Axios.post(
                BASE_API_URL + "wemloStaff/updateProcessorAudiTrail",
                {
                    performedBy: body.performedBy,
                    activity: body.activity,
                    processorId: body.processorId
                });
            this.$snotify.success("Updated");
        } catch (error) {
            console.log(error, "updateProcessorAudiTrail");
        }
    }
    public async activateWemloProcessing(body) {
        try {
            let response = await Axios.post(
                BASE_API_URL + "broker/activateWemloProcessing",
                {
                    wemloProcessor: body.wemloProcessor,
                    userId: body.userId
                });
            this.$snotify.success("Updated");
        } catch (error) {
            console.log(error, "activateWemloProcessing");

        }
    }

    public viewPassword() {
        // var x:any = document.getElementById("myInput");
        if (this.defaultType === "password") {
          this.defaultType = "text";
        } else {
          this.defaultType = "password";
        }
      }
    
      public viewPasswordForFannieMae(){
        // var x:any = document.getElementById("myInput");
        if (this.defaultType2 === "password") {
          this.defaultType2 = "text";
        } else {
          this.defaultType2 = "password";
        }
      }

    public async resetAusCaseFileId() {
        await Axios.post(
            BASE_API_URL + "borrower/updateAusCaseFileId",
            {
                loanTxnId: this.$route.query.id,
                ausCasefileId: null
            }
        );
    }

    public checkResponseIfFileInfected(error): Array<string> {
        const { response: { data: { message = ''} = {} } = {}} = error;
        const errorFiles = [];
        if (message.includes("infected")) {
            const messages = message.split("!").filter((m) => m.length);
            for (const f of messages) {
                errorFiles.push(f.match(/File\s([\w().,\-:;@#$%^&*\[\]"'+–\/\\°⁰!?{}|'~\s\W]+)\sis\sinfected/i)[1]);
            }
        }
        return errorFiles;
    }

    public filterSamplesIfInfected (file, infectedFilename) {
        if (file.samples.length > 0 && !file.samples[0].hasOwnProperty("path")) {
            file.samples = file.samples.filter(f => (f.name !== infectedFilename));
        }
    }
}