import Vue from "vue";

const getTargetElement = (el) => {
    if (el.classList?.contains('multiselect')) {
        return el.querySelector('.multiselect__tags');
    }
    const isWrapper = !['SELECT', 'INPUT'].includes(el.tagName);
    return isWrapper ? el.querySelector('input') || el.querySelector('select') : el;
}

const getStyledElement = (targetEl, parentEl) => {
    switch (targetEl.getAttribute('type')) {
        case 'radio':
            return parentEl.querySelector('.checkround') || parentEl.querySelector('.dirty-checkbox');
        case 'checkbox':
            return parentEl.querySelector('.dirty-checkbox');
        default:
            return targetEl;
    }
}

const getStringifyValue = (val) => {
    // input can receive a value as an empty string or null
    // when input is cleared, it emits an empty string even if it was originally null
    // so we have to set the same value for comparison - 'null'
    return typeof val === 'object' ? JSON.stringify(val) : (`${val ?? ''}` || 'null');
}

const getLoadedState = (binding) => {
    const loaderName = Object.keys(binding?.modifiers)?.find(m => m.toLowerCase().includes('loader'));
    return loaderName ? Vue.prototype.$store.state?.[loaderName] : binding?.value?.valueIsLoaded;
}

// works with v-show, do not use v-if (if the user can add or hide the field himself)
// in order to keep the initial data in the dataset
Vue.directive('dirty-field', {
    inserted(el, binding) {
        const elem = getTargetElement(el);
        const isCheckbox = elem.getAttribute('type') === 'checkbox';
        const current = binding?.value?.current;
        const isHidden = !el.clientHeight && !el.clientWidth;
        const valueIsLoaded = getLoadedState(binding);

        elem.dataset.dirtyInitial = isCheckbox && !current ? 'false' : getStringifyValue(current);

        // for debug mode: binding.modifiers.debug && console.log(elem.dataset.dirtyInitial);

        // if this field is hidden in its initial state
        // we will show the field as dirty
        if (!binding?.modifiers?.initClean && (isHidden || (binding?.modifiers?.canUserAdd && !valueIsLoaded))) {
            const styledEl = getStyledElement(elem, el);
            delete elem.dataset.dirtyInitial;
            styledEl?.classList?.add('dirty-field', `dirty-field--${binding?.arg || 'blue' }`);
        }
    },
    update(el, binding) {
        const elem = getTargetElement(el);
        const isCheckbox = elem.getAttribute('type') === 'checkbox';
        const current = binding?.value?.current;
        const currentStr = isCheckbox && !current ? 'false' : getStringifyValue(current);
        const isChanged = (currentStr !== elem.dataset?.dirtyInitial);
        const classList = ['dirty-field', `dirty-field--${binding?.arg || 'blue' }`];
        const styledEl = getStyledElement(elem, el);
        const isHidden = !el.clientHeight && !el.clientWidth;
        const valueIsLoaded = getLoadedState(binding);

        // for debug mode: binding.modifiers.debug && console.log('currentStr: ', currentStr, 'dataset: ', elem.dataset?.dirtyInitial);

        if (valueIsLoaded) {
            if (isHidden && !binding?.modifiers?.initClean) {
                delete elem.dataset.dirtyInitial;
                styledEl?.classList?.add(...classList);
            } else {
                elem.dataset.dirtyInitial = currentStr;
                styledEl?.classList?.remove(...classList);
            }
            return;
        }
        if (binding?.value?.current === binding?.oldValue?.current || isHidden && binding?.modifiers?.initClean) return;

        Vue.prototype.$nextTick(() => {
            isChanged ? styledEl?.classList?.add(...classList) : styledEl?.classList?.remove(...classList);
        });
    },
})