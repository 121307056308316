
export class BorrowerData {
  constructor() {
    this.agencyCaseNumber = null;
    this.lenderCaseNumber = null;
    this.loanStatus = new Array<any>();
  }
  public agencyCaseNumber: string;
  public lenderCaseNumber: string;
  public loanStatus: Array<any>;
}

export class PendingQuestionList {
  constructor() {
    this.borrower = [new PendingSectionList()];
    this.coBorrower = [new PendingSectionList()];
  }
  public borrower: Array<PendingSectionList>;
  public coBorrower: Array<PendingSectionList>;
}

export class PendingSectionList {
  constructor() {
    this.LP = [];
    this.PI = [];
    this.II = [];
    this.LAP = [];
    this.DCL = [];
    this.DI = [];
    this.AF = [];
  }
  public LP: Array<any>;
  public PI: Array<any>;
  public II: Array<any>;
  public LAP: Array<any>;
  public DCL: Array<any>;
  public DI: Array<any>;
  public AF: Array<any>;
}

export class LoanReports {
  constructor() {
    this.countsDataset = new CountsDataset();
    this.pieChartDataset = new PieChartDataset();
    this.barChartDataset = new BarChartDataset();
  }
  public countsDataset: CountsDataset;
  public pieChartDataset: PieChartDataset;
  public barChartDataset: BarChartDataset;
}

class BarChartDataset {
  public series = [0, 0, 0, 0];
  public labels = [];
}

class CountsDataset {
  public refinance = 0;
  public purchase = 0;
  public totalLoans = 0;
  public totalAmount = 0;
}

class PieChartDataset {
  public series = [0, 0, 0, 0];
  public labels = [];
}

export class QuestionInfo {
  constructor() {
    this.answeredAll = true;
    this.isInProgress = false;
    this.borrowerId = null;
    this.userType = null;
    this.index = null;
    this.indexingFlow = [];
    this.currentQuestion = null;
    this.currentSection = null;
    this.previousQuestion = null;
    this.pendingQuestionList = new PendingQuestionList();
  }
  public answeredAll: boolean;
  public isInProgress: boolean;
  public borrowerId: string;
  public userType: string;
  public index: number;
  public indexingFlow: Array<any>;
  public currentSection: string;
  public currentQuestion: number;
  public previousQuestion: number;
  public pendingQuestionList: PendingQuestionList;
}

export class QuestionHistory {
  constructor() {
    this.updatedOn = new Date();
  }
  public sectionName: string;
  public questionNumber: number;
  public userType: string;
  public borrowerId: string;
  public updatedBy: string;
  public updatedOn: Date;
}

export class UserData {
  constructor() {
      this._id = null;
      this.email = null;
      this.address = new UserDataAddress();
  }
  public _id: string;
  public email: string;
  public address: UserDataAddress;
}

export class UserDataAddress {
  public unit: string;
  public street: string;
  public zip: string;
  public designatorType: string;
  public city: UserDataCityAddress;
  public state: UserDataStateAddress;
}

export class UserDataCityAddress {
  public code: string;
  public name: string;
}

export class UserDataStateAddress {
  public code: string;
  public name: string;
}
