

import { Component, Vue } from "vue-property-decorator";
import {setInstanceVariables} from "@/config";
@Component
export default class PreRequisiteHeader extends Vue {
  get hasSession() {
    let data = this.$store.state.sessionObject;
    if (data.token) {
      return true;
    } else {
      return false;
    }
  }
  
  async logOutIdentityAssumedMode() {
    try {
      await this.$store.dispatch("setIdentityAssumedMode", false);
      await this.$store.dispatch("setUserInfoForIdentityAssumedMode", {});
      await this.$store.dispatch("setSession", this.$store.state.copySuperAdminSessionObject);
      await setInstanceVariables();
      await this.$store.dispatch("setSidenavLinks", this.$store.state.copySuperAdminSessionObject);
      await this.$router.push({path: this.$getCurrentUserTypePath("manage-all-user")});
    } catch (error) {
      console.log(error)
    }
  }
  
  public logout() {
    if (this.$store.state.isIdentityAssumedMode) {
      this.logOutIdentityAssumedMode()
    } else {
      localStorage.setItem("logout-event", "user_logged_out" + Math.random());
      this.$store.dispatch("clearSession");
    }
    if (this.$route.name != "Login") this.$router.push({ name: "Login" });
  }

  private otherSites = [
    'ActivationPage',
    'TermsAndConditions',
    'EmailReply',
  ];

  getOtherSite() {
    return this.otherSites.includes(this.$route.name);
  }

  getBrandingInformation(){
    return this.$store.state.brandingInformation || {};
  }
}
