


















import { Component, Vue, Prop } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "@/config";
import _ from "lodash";
import PDFJS from "@/pdfjs";
import moment from "moment";

const sleep = m => new Promise(r => setTimeout(r, m));

@Component({})
export default class EDisclosureAgreement extends Vue {

    @Prop({ default: true }) readonly showRefuseButton: boolean;

    signatureLegalDocumentCanvas = null;
    signatureLegalDocumentContext = null;
    signatureLegalDocumentScale = 1.5;
    ipAddress: string = '';
    userId = null;
    username = null;
    valid: boolean = false;
    accept: boolean = true;
    showAcceptedLabel: boolean = false;

    $refs!: {
        signatureLegalDocumentWrap: any,
        signatureLegalDocument: any,
    };

    async agreeEdisclosureAgreement() {

        try {
            this.$store.state.wemloLoader = true;
            this.showAcceptedLabel = true;

            this.updateSignatureLegalDocument();
            
            const econsentImage = this.convertEconsentToPng();
            const { data: { success } } = await Axios.post(BASE_API_URL + 'disclosure/createEconsentDocument', { econsentImage, username: this.username });

            if (success) {
                this.$store.dispatch('updateEDisclosureAgreement', true);
                this.$emit('actionComplete');
                this.$emit('remount');
            }
        } finally {
            this.$store.state.wemloLoader = false;
        }
    }

    async refuseEdisclosureAgreement() {
        try {
            this.$store.state.wemloLoader = true;
            this.accept = false;
            this.showAcceptedLabel = true;

            this.updateSignatureLegalDocument();

            await sleep(500);

            const econsentImage = this.convertEconsentToPng();
            const { data: { success } } = await Axios.post(BASE_API_URL + 'disclosure/refuseEdisclosureAgreement', { econsentImage, username: this.username });

            if (success) {
                this.$store.dispatch('updateEDisclosureAgreement', false);
                this.$emit('actionComplete');
                this.$emit('remount');
                await this.sendMessage(`
                    The borrower ${this.username} has declined to receive electronic documents for this loan. 
                    Please contact them and confirm that it is their desire. To help you remain compliant with applicable regulations, 
                    you will be prompted to confirm the date when the LO mailed the printed disclosures to the borrower.
                `);
            }
        } finally {
            this.$store.state.wemloLoader = false;
        }
    }

    async sendMessage(msg) {
        try {
            let formData = new FormData();
        
            let obj = {
                contacts: null,
                type: null,
                toUserId: null,
                toUserType: 'Broker',
                message: msg,
                loanTxnId:  this.$store.state.sessionObject &&
                            this.$store.state.sessionObject.userInfo &&
                            this.$store.state.sessionObject.userInfo.loanTxnId
                            ? this.$store.state.sessionObject.userInfo.loanTxnId
                            : null,
                fromType: 'Borrower',
                borrowerName: this.username,
                notificatioModuleId: null
            };
            formData.append("data", JSON.stringify(obj))

            await Axios.post(BASE_API_URL + "message/addMessage", formData);
        
            return;
        } catch (error) {
            console.log(error, "error");
        }
    }

    convertEconsentToPng() {
        let econsentImage = null;

        try {
            econsentImage = this.signatureLegalDocumentCanvas.toDataURL();
        } catch(e) { }

        return econsentImage;
    }

    rescaleSignatureLegalDocument() {
        if(!this.$refs.signatureLegalDocument || !this.$refs.signatureLegalDocumentWrap) { return; }

        const wrapperWidth = this.$refs.signatureLegalDocumentWrap.offsetWidth;

        const canvasWidth = this.$refs.signatureLegalDocument.width;
        const canvasHeight = this.$refs.signatureLegalDocument.height;

        if (!wrapperWidth || !canvasWidth || !canvasHeight) { return; }

        const scale = wrapperWidth / canvasWidth;

        this.$refs.signatureLegalDocument.style.height = `${canvasHeight * scale}px`;
        this.$refs.signatureLegalDocumentWrap.style.height = `${canvasHeight * scale}px`;
    }

    async renderSignatureLegalDocument() {
        if (!this.$refs.signatureLegalDocument || !this.$refs.signatureLegalDocumentWrap) { return; }

        this.userId = _.get(this.$store.state, 'sessionObject.userId', null);
        const currentBorrower = _.get(this.$store.state, 'sessionObject.userInfo.borrowerInfo', []).find(borrower => _.get(borrower, 'userId', false) === this.userId);
        const nameObject = _.get(currentBorrower, 'personalInfo.name', {});
        this.username = `${_.get(nameObject, 'firstName', '')} ${_.get(nameObject, 'lastName', '')}`;

        const { data: { ip } } = await Axios.get(
            BASE_API_URL + "common/getIpAddress"
        );

        this.ipAddress = ip;

        this.$store.state.wemloLoader = true;

        const { data: consentDocument } = await Axios.post(
            BASE_API_URL + "common/fetchDocument",
            { path: '/WesignForms/Mapped/e-ConsentAgreement.pdf' }
        );

        if (!consentDocument) {
            this.$store.state.wemloLoader = false;
            return;
        }

        const pdf = await PDFJS.getDocument({ data: atob(consentDocument) }).promise;
        const page = await pdf.getPage(1);

        this.signatureLegalDocumentCanvas = this.$refs.signatureLegalDocument;
        this.signatureLegalDocumentContext = this.signatureLegalDocumentCanvas.getContext('2d');

        const viewport = page.getViewport({ scale: this.signatureLegalDocumentScale });

        this.signatureLegalDocumentCanvas.height = viewport.height;
        this.signatureLegalDocumentCanvas.width = viewport.width;

        await page.render({
            canvasContext: this.signatureLegalDocumentContext,
            viewport
        }).promise;
        
        this.$store.state.wemloLoader = false;

        this.valid = true;
        this.updateSignatureLegalDocument();
        Vue.nextTick(() => { this.rescaleSignatureLegalDocument(); });
    }

    updateSignatureLegalDocument() {
        if (!this.signatureLegalDocumentContext || !this.signatureLegalDocumentCanvas) { return; }

        const ctx = this.signatureLegalDocumentContext;

        ctx.clearRect(110, 900, 810, 30);
        ctx.fillStyle = 'white';
        ctx.fillRect(110, 900, 810, 30);
        ctx.fillStyle = '#0c4f86';
        ctx.font = `${9 * this.signatureLegalDocumentScale}px Verdana`;

        const time = moment().format('Y-MM-DD HH:mm:ss');
        const status = this.accept ? 'Accepted' : 'Refused';
        const ipAddressText = this.ipAddress ? ` from ${this.ipAddress}` : '.';

        if (this.showAcceptedLabel) {
            ctx.fillText(`${status} by ${this.username} on ${time}${ipAddressText}`, 115, 920);
        }

        setTimeout(function() {
            this.updateSignatureLegalDocument();
        }.bind(this), 1000);
    }

    created() {
        setTimeout(() => {
            window.addEventListener("resize", this.rescaleSignatureLegalDocument);
        }, 1000);
    }

    destroyed() {
        window.removeEventListener("resize", this.rescaleSignatureLegalDocument);
    }

    async mounted() {
        await this.renderSignatureLegalDocument();
    }
}
