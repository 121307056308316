export class Acknowledgement {

    constructor() {
        this.borrowerInfo = [new AckDetail()];
        // this.coBorrowerInfo = [new AckDetail()];    not used in Sprint 5
    }
    public loanTxnId: string;
    public borrowerInfo: Array<AckDetail>;
    // public coBorrowerInfo: Array<AckDetail>;      not used in Sprint 5
}

export class AckDetail {
    constructor(){
        this.ackSameAsBorrower = false;
    }
    public ackSameAsBorrower:boolean;
    public signature: string;
    public date: string;
}