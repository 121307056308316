
import { Validator } from 'vee-validate';
import { charsets, PasswordPolicy } from 'password-sheriff';

const wemloPasswordPolicy = new PasswordPolicy(
    {
      length: {minLength: 8},
      containsAtLeast: {
        atLeast: 4,
        expressions: [charsets.lowerCase, charsets.upperCase, charsets.numbers, charsets.specialCharacters]
      }
    }
);

Validator.extend('alpha_numeric_spaces', {
  getMessage: field => 'The ' + field + ' field can contain only valid characters .',
  validate: value => {
    let reg = /^[\sa-zA-Z0-9\s%'\.,-]+$/;
    if (!reg.test(value)) return false;
    return true;
  }
});

Validator.extend('url_format', {
  getMessage: field => 'The ' + field + ' field can contain only valid characters .',
  validate: value => {
    let reg = /^([A-z-A-z0-9]+)\.([A-z]{2,})/
    let regex = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    if(!reg.test(value) && !regex.test(value)) return false;
    return true;

    // let reg = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
    // if (reg.test(value))
    //   return true;
    // else
    //   return false;
  }
})
// Register validation rule for zip field;
Validator.extend('zip', {
  getMessage: field => 'The ' + field + ' field can have only 5 or 9 digits.',
  validate: value => {
    let reg = /^[0-9]*$/;

    return reg.test(value) && !((value.length < 5) || (value.length > 5 && value.length < 9) || (value.length > 9))
  }
});

//Validation Rule for Albhabetic, Special character ,nmumeric & space
Validator.extend('alpha_num_special', {
    getMessage: field => 'The ' + field + ' field can contain only valid characters .',
    validate: value => {
      let reg = /^[A-Za-z0-9 ][A-Za-z0-9-!'^ ]*$/;
      if (!reg.test(value)) return false;
      return true;
    }
  });

//Validation Rule for password
Validator.extend('strong_password', {
    getMessage: field => 'The password must have at least 8 characters, at least one number, one uppercase letter, one lowercase letter and one special symbol.',
    validate: value => {
      return wemloPasswordPolicy.check(value);
    }
  });
