
import * as PDFJS from 'pdfjs-dist/legacy/build/pdf';
import PDFJSWorker from 'worker-loader?esModule=false&filename=[name].[contenthash].js!pdfjs-dist/legacy/build/pdf.worker';

/**
 * Set service worker to run
 */
PDFJS.GlobalWorkerOptions.workerPort = new PDFJSWorker();

export default PDFJS;
