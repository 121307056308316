











import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { EventBus } from "@/config";
@Component
export default class LearnMoreVideoModal extends Vue {
    /**
     * data.taskName & data.videoUrl for using it.
     */
    
    @Prop()
    public data;

    @Watch('isOpen')
    close(){
        EventBus.$emit('closeWeLearn',false);
    }

}

