import _ from "lodash";

export const OTHER_INCOME_SOURCE = [
  { name: "Alimony", ediValue: "F7", mismoValue: "Alimony", value: "Alimony" },
  { name: "Automobile Allowance", ediValue: "M2", mismoValue: "AutomobileAllowance", value: "Automobile Allowance" },
  { name: "Boarder Income", ediValue: "BI", mismoValue: "BoarderIncome", value: "Boarder Income" },
  { name: "Capital Gains", ediValue: "CG", mismoValue: "CapitalGains", value: "Capital Gains" },
  { name: "Child Support", ediValue: "F7", mismoValue: "ChildSupport", value: "Child Support" },
  // { name: "Disability", ediValue: "42", mismoValue: "Disability", value: "Disability" },
  { name: "Foster Care", ediValue: "M3", mismoValue: "FosterCare", value: "Foster Care" },
  { name: "Housing or Parsonage", ediValue: "S8", mismoValue: "Housing or Parsonage", value: "Housing or Parsonage" },
  { name: "Interest and Dividends", ediValue: "17", mismoValue: "DividendsInterest", value: "Interest and Dividends" },
  { name: "Mortgage Credit Certificate", ediValue: "MC", mismoValue: "MCC", value: "Mortgage Credit Certificate" },
  { name: "Mortgage Differential Payments", ediValue: "30", mismoValue: "Mortgage Differential Payments", value: "Mortgage Differential Payments" },
  { name: "Notes Receivable", ediValue: "F8", mismoValue: "Notes Receivable", value: "Notes Receivable" },
  // { name: "Public Assistance", ediValue: "M1", mismoValue: "PublicAssistance", value: "Public Assistance" },
  { name: "Retirement (e.g., Pension, IRA)", ediValue: "41", mismoValue: "Retirement", value: "Retirement" },
  { name: "Royalty Payments", ediValue: "RP", mismoValue: "RoyaltyPayments", value: "Royalty Payments" },
  // { name: "Separate Maintenance", ediValue: "", mismoValue: "SeparateMaintenance", value: "Separate Maintenance" },
  { name: "Social Security", ediValue: "42", mismoValue: "SocialSecurity", value: "Social Security" },
  { name: "Trust", ediValue: "F9", mismoValue: "Trust", value: "Trust" },
  { name: "Unemployment Benefits", ediValue: "M1", mismoValue: "UnemploymentBenefits", value: "Unemployment Benefits" },
  { name: "VA Compensation", ediValue: "M4", mismoValue: "VA Compensation", value: "VA Compensation" },
  { name: "Other Income", ediValue: "45", mismoValue: "Other", value: "Other" },

  //ADDITIONAL EDI VALUES ADDED FOR READING FNM FILE
  { name: "Military Base Pay", ediValue: "F1", mismoValue: "", value: "Military Base Pay" },
  { name: "Military Rations Allowance", ediValue: "07", mismoValue: "", value: "Military Rations Allowance" },
  { name: "Military Hazard Pay", ediValue: "F3", mismoValue: "", value: " Military Hazard Pay" },
  { name: "Military Clothes Allowance", ediValue: "02", mismoValue: "", value: "Military Clothes Allowance" },
  { name: "Military Quarters Allowance", ediValue: "04", mismoValue: "", value: "Military Quarters Allowance" },
  { name: "Military Prop Pay", ediValue: "03", mismoValue: "", value: "Military Prop Pay" },
  { name: "Military Overseas Pay", ediValue: "F4", mismoValue: "", value: "Military Overseas Pay" },
  { name: "Military Combat Pay", ediValue: "F5", mismoValue: "", value: "Military Combat Pay" },
  { name: "Military Variable Housing Allowance", ediValue: "F6", mismoValue: "", value: "Military Variable Housing Allowance" },
  // { name: "Base Employment Income", ediValue: "20", mismoValue: "", value: "Base Employment Income"},
  // { name: "Overtime", ediValue: "09", mismoValue: "", value: "Overtime"},
  // { name: "Bonuses", ediValue: "08", mismoValue: "", value: "Bonuses"},
  // { name: "Commissions", ediValue: "10", mismoValue: "", value: "Commissions"},
  // { name: "Dividends/Interest", ediValue: "17", mismoValue: "", value:"Dividends/Interest"},
  { name: "Subject Property Net Cash Flow", ediValue: "SI", mismoValue: "", value: "Subject Property Net Cash Flow" },
  // { name: "Net Rental Income", ediValue: "33", mismoValue: "", value:"Net Rental Income"},
  // { name: "Boarder Income", ediValue: "BI", mismoValue: "", value:"Boarder Income"},
  { name: "Trailing Co-borrower Income", ediValue: "TC", mismoValue: "", value: "Trailing Co-borrower Income" },
  { name: "Foreign Income", ediValue: "FI", mismoValue: "", value: "Foreign Income" },
  { name: "Other", ediValue: "TL", mismoValue: "", value: "Temporary Leave" }

];

export const ASSET_TYPE = [
  { name: "Checking", ediValue: "03", mismoValue: "CheckingAccount", value: "Checking" },
  { name: "Savings", ediValue: "SG", mismoValue: "SavingsAccount", value: "Savings" },
  { name: "Money Market", ediValue: "F3", mismoValue: "MoneyMarketFund", value: "Money Market" },
  { name: "Certificate of Deposit(CD Account)", ediValue: "01", mismoValue: "CertificateOfDepositTimeDeposit", value: "Certificate of Deposit" },
  { name: "Mutual Fund", ediValue: "F4", mismoValue: "MutualFund", value: "Mutual Fund" },
  { name: "Stocks", ediValue: "05", mismoValue: "Stock", value: "Stocks" },
  { name: "Bonds", ediValue: "06", mismoValue: "Bond", value: "Bonds" },
  { name: "Retirement (e.g., 401k, IRA)", ediValue: "08", mismoValue: "RetirementFund", value: "Retirement (e.g., 401k, IRA)" },
  { name: "Bridge Loan Proceeds", ediValue: "F7", mismoValue: "BridgeLoanNotDeposited", value: "Bridge Loan Proceeds" },
  { name: "Trust Account", ediValue: "11", mismoValue: "TrustAccount", value: "Trust Account" },
  { name: 'Business Owned', ediValue: "F8", mismoValue: "BusinessOwned", value: "Business Owned" },
  { name: 'Secured Borrowed Funds ', ediValue: "F5", mismoValue: "SecuredBorrowedFunds", value: "Secured Borrowed Funds" },

  // No edi values
  // { name: "Cash Value of Life Insurance", ediValue: '', mismoValue: "LifeInsurance", value: "Cash Value of Life Insurance" },
  // { name: "Individual Development Account", ediValue: '', mismoValue: "IndividualDevelopmentAccount", value: "Individual Development Account" },
  // { name: "Stock Options", ediValue: '', mismoValue: "StockOptions", value: "Stock Options" },

  // new added 
  { name: 'Gift of Equity', ediValue: "GE", mismoValue: "GiftofEquity", value: "Gift of Equity" },
  { name: 'Cash On Hand', ediValue: "COH", mismoValue: "CashOnHand", value: "Cash On Hand" },
  { name: 'Net Equity', ediValue: "NE", mismoValue: "NetEquity", value: "Net Equity" },
  { name: 'Other Liquid Asset', ediValue: "OL", mismoValue: "OtherLiquidAsset", value: "Other Liquid Asset" },
  { name: 'Other Non-Liquid Asset', ediValue: "M1", mismoValue: "OtherNon-LiquidAsset", value: "Other Non-Liquid Asset" },
  { name: 'Cash Deposit On Sale Contract', ediValue: "F1", mismoValue: "CashDepositOnSaleContract", value: "Cash Deposit On Sale Contract" },
  { name: 'Gift', ediValue: "F2", mismoValue: "Gift", value: "Gift" },
];

export const MISMO_AMORTIZATION_ARM_TYPES = [
  { name: "Other", ediValue: "", mismoValue: "Other", value: "Other" }
]

export const MISMO_AMORTIZATION_ARM_OTHER_DESCRIPTION = [
  { name: "11th District COF", ediValue: "", mismoValue: "11thDistrictCOF", value: "11thDistrictCOF" },
  { name: "30 Day Average SOFR", ediValue: "", mismoValue: "30DayAverageSOFR", value: "30DayAverageSOFR" },
  { name: "Daily CD Rate", ediValue: "", mismoValue: "DailyCDRate", value: "DailyCDRate" },
  { name: "Fannie Mae 60 Day Required Net Yield", ediValue: "", mismoValue: "FannieMae60DayRequiredNetYield", value: "FannieMae60DayRequiredNetYield" },
  { name: "Fannie Mae LIBOR", ediValue: "", mismoValue: "FannieMaeLIBOR", value: "FannieMaeLIBOR" },
  { name: "Federal Cost Of Funds", ediValue: "", mismoValue: "FederalCostOfFunds", value: "FederalCostOfFunds" },
  { name: "Freddie Mac 60Day Required Net Yield", ediValue: "", mismoValue: "FreddieMac60DayRequiredNetYield", value: "FreddieMac60DayRequiredNetYield" },
  { name: "Freddie Mac LIBOR", ediValue: "", mismoValue: "FreddieMacLIBOR", value: "FreddieMacLIBOR" },
  { name: "Monthly Average CMT", ediValue: "", mismoValue: "MonthlyAverageCMT", value: "MonthlyAverageCMT" },
  { name: "National Average Contract Rate", ediValue: "", mismoValue: "NationalAverageContractRate", value: "NationalAverageContractRate" },
  { name: "National Monthly Median Cost Of Funds", ediValue: "", mismoValue: "NationalMonthlyMedianCostOfFunds", value: "NationalMonthlyMedianCostOfFunds" },
  { name: "T Bill Daily Value", ediValue: "", mismoValue: "TBillDailyValue", value: "TBillDailyValue" },
  { name: "Wall Street Journal LIBOR", ediValue: "", mismoValue: "WallStreetJournalLIBOR", value: "WallStreetJournalLIBOR" },
  { name: "Weekly Ave PrimeRate", ediValue: "", mismoValue: "WeeklyAvePrimeRate", value: "WeeklyAvePrimeRate" },
  { name: "Weekly Average CD Rate", ediValue: "", mismoValue: "WeeklyAverageCDRate", value: "WeeklyAverageCDRate" },
  { name: "Weekly Average CMT", ediValue: "", mismoValue: "WeeklyAverageCMT", value: "WeeklyAverageCMT" },
  { name: "Weekly Average SMTI", ediValue: "", mismoValue: "WeeklyAverageSMTI", value: "WeeklyAverageSMTI" },
  { name: "Weekly Average TAABD", ediValue: "", mismoValue: "WeeklyAverageTAABD", value: "WeeklyAverageTAABD" },
  { name: "Weekly Average TAAI", ediValue: "", mismoValue: "WeeklyAverageTAAI", value: "WeeklyAverageTAAI" }
]

export const MISMO_REFIANCE_PROGRAM_OTHER = [ 
  { name: "203k", ediValue: "", mismoValue: "203k", value: "203k" },
  { name: "Rate Term", ediValue: "", mismoValue: "RateTerm", value: "RateTerm" },
  { name: "Short", ediValue: "", mismoValue: "Short", value: "Short" },
  { name: "Simple", ediValue: "", mismoValue: "Simple", value: "Simple" },
  { name: "Streamline Credit", ediValue: "", mismoValue: "StreamlineCredit", value: "StreamlineCredit" },
  { name: "Streamline No Credit", ediValue: "", mismoValue: "StreamlineNoCredit", value: "StreamlineNoCredit" },

]

export const MISMO_INDIAN_COUNTRY_LAND_TENURE = [
  { name: "Fee Simple", ediValue: "", mismoValue: "FeeSimple", value: "FeeSimple" },
  { name: "Individual Trust Land (Allotted/Restricted)", ediValue: "", mismoValue: "IndividualTrustLand", value: "IndividualTrustLand" },
  { name: "Other", ediValue: "", mismoValue: "Other", value: "Other" }
]

export const MISMO_INDIAN_COUNTRY_LAND_TENURE_OTHER = [
  { name: "Tribal Trust Land On a Reservation", ediValue: "", mismoValue: "TribalTrustLandOnAReservation", value: "TribalTrustLandOnAReservation" },
  { name: "Tribal Trust Land Off Reservation", ediValue: "", mismoValue: "TribalTrustLandOffReservation", value: "TribalTrustLandOffReservation" },
  { name: "Alaska Native Corporation Land", ediValue: "", mismoValue: "AlaskaNativeCorporationLand", value: "AlaskaNativeCorporationLand" },
]

export const MISMO_ASSET_TYPE = [
  { name: "Bond", ediValue: "", mismoValue: "Bond", value: "Bond" },
  { name: "Bridge Loan Not Deposited", ediValue: "", mismoValue: "BridgeLoanNotDeposited", value: "BridgeLoanNotDeposited" },
  { name: "Certificate Of Deposit Time Deposit", ediValue: "", mismoValue: "CertificateOfDepositTimeDeposit", value: "CertificateOfDepositTimeDeposit" },
  { name: "Checking Account", ediValue: "", mismoValue: "CheckingAccount", value: "CheckingAccount" },
  { name: "Individual Development Account", ediValue: "", mismoValue: "IndividualDevelopmentAccount", value: "IndividualDevelopmentAccount" },
  { name: "Life Insurance", ediValue: "", mismoValue: "LifeInsurance", value: "LifeInsurance" },
  { name: "Money Market Fund", ediValue: "", mismoValue: "MoneyMarketFund", value: "MoneyMarketFund" },
  { name: "Mutual Fund", ediValue: "", mismoValue: "MutualFund", value: "MutualFund" },
  { name: "Retirement Fund", ediValue: "", mismoValue: "RetirementFund", value: "RetirementFund" },
  { name: "Savings Account", ediValue: "", mismoValue: "SavingsAccount", value: "SavingsAccount" },
  { name: "Stock", ediValue: "", mismoValue: "Stock", value: "Stock" },
  { name: "Stock Options", ediValue: "", mismoValue: "StockOptions", value: "StockOptions" },
  { name: "Trust Account", ediValue: "", mismoValue: "TrustAccount", value: "TrustAccount" },
];

export const MISMO_FUNDS_SOURCE_TYPE = [
  { name: "Community Non Profit", ediValue: "", mismoValue: "CommunityNonProfit", value: "CommunityNonProfit" },
  { name: "Federal Agency", ediValue: "", mismoValue: "FederalAgency", value: "FederalAgency" },
  { name: "Institutional", ediValue: "", mismoValue: "Institutional", value: "Institutional" },
  { name: "Lender", ediValue: "", mismoValue: "Lender", value: "Lender" },
  { name: "Local Agency", ediValue: "", mismoValue: "LocalAgency", value: "LocalAgency" },
  { name: "Non Parent Relative", ediValue: "", mismoValue: "NonParentRelative", value: "NonParentRelative" },
  { name: "Other", ediValue: "", mismoValue: "Other", value: "Other" },
  { name: "Parent", ediValue: "", mismoValue: "Parent", value: "Parent" },
  { name: "Relative", ediValue: "", mismoValue: "Relative", value: "Relative" },
  { name: "Religious Non Profit", ediValue: "", mismoValue: "ReligiousNonProfit", value: "ReligiousNonProfit" },
  { name: "State Agency", ediValue: "", mismoValue: "StateAgency", value: "StateAgency" },
  { name: "Unmarried Partner", ediValue: "", mismoValue: "UnmarriedPartner", value: "UnmarriedPartner" },
  { name: "Unrelated Friend", ediValue: "", mismoValue: "UnrelatedFriend", value: "UnrelatedFriend" },
]

export const MILITARY_STATUS_TYPE = [
  { name: 'Active Duty', mismoValue: 'ActiveDuty', value: 'ActiveDuty' },
  { name: 'Reserve or Guard', mismoValue: 'ReserveNationalGuardNeverActivated', value: 'ReserveNationalGuardNeverActivated' },
  { name: 'Veteran', mismoValue: 'Veteran', value: 'Veteran' },
];

export const MISMO_FUNDS_SOURCE_TYPE_OTHER = [
  { name: "NonProfitInstrumentalityOfGovernment", ediValue: "", mismoValue: "NonProfitInstrumentalityOfGovernment", value: "NonProfitInstrumentalityOfGovernment" },
]

export const MISMO_ASSET_TYPE_OTHER_ENUM = [
  { name: "Other Liquid Asset", ediValue: "", mismoValue: "OtherLiquidAsset", value: "OtherLiquidAsset" },
  { name: "Other Non Liquid Asset", ediValue: "", mismoValue: "OtherNonLiquidAsset", value: "OtherNonLiquidAsset" }
];

export const MISMO_PURCHASE_CREDITS_TYPE_OTHER_ENUM = [
  { name: "Borrower Paid Fees", ediValue: "", mismoValue: "BorrowerPaidFees", value: "BorrowerPaidFees" },
  { name: "Lot Equity", ediValue: "", mismoValue: "LotEquity", value: "LotEquity" }
];

export const MISMO_OTHER_ASSET_TYPE = [
  { name: "Other Asset", ediValue: "", mismoValue: "Other", value: "Other" },
  { name: "Proceeds from Real Estate Property to be sold on or before closing", ediValue: "", mismoValue: "PendingNetSaleProceedsFromRealEstateAssets", value: "PendingNetSaleProceedsFromRealEstateAssets" },
  { name: "Proceeds From Sale Of Non Real Estate Asset", ediValue: "", mismoValue: "ProceedsFromSaleOfNonRealEstateAsset", value: "ProceedsFromSaleOfNonRealEstateAsset" },
  { name: "Proceeds From Secured Loan", ediValue: "", mismoValue: "ProceedsFromSecuredLoan", value: "ProceedsFromSecuredLoan" },
  { name: "Proceeds From Unsecured Loan", ediValue: "", mismoValue: "ProceedsFromUnsecuredLoan", value: "ProceedsFromUnsecuredLoan" },
];

export const MISMO_PURCHASE_CREDIT_TYPE = [
  { name: "Earnest Money", ediValue: "", mismoValue: "EarnestMoney", value: "EarnestMoney"},
  { name: "Employer Assistance", ediValue: "", mismoValue: "EmployerAssistedHousing", value: "EmployerAssistedHousing"},
  { name: "Rent Credit", ediValue: "", mismoValue: "LeasePurchaseFund", value: "LeasePurchaseFund"},
  { name: "Other Purchase Credit", ediValue: "", mismoValue: "Other", value: "Other"},
  { name: "Relocation Funds", ediValue: "", mismoValue: "RelocationFunds", value: "RelocationFunds"},
  { name: "Sweat Equity", ediValue: "", mismoValue: "SweatEquity", value: "SweatEquity"},
  { name: "Trade Equity From Property Swap", ediValue: "", mismoValue: "TradeEquityFromPropertySwap", value: "TradeEquityFromPropertySwap"}
];

export const CURRENT_ASSET_TYPE = [
  { name: "Checking", ediValue: "03", mismoValue: "CheckingAccount", value: "Checking" },
  { name: "Savings", ediValue: "SG", mismoValue: "SavingsAccount", value: "Savings" },
  { name: "Money Market", ediValue: "F3", mismoValue: "MoneyMarketFund", value: "Money Market" },
  { name: "Certificate of Deposit(CD Account)", ediValue: "01", mismoValue: "CertificateOfDepositTimeDeposit", value: "Certificate of Deposit" },
  { name: "Mutual Fund", ediValue: "F4", mismoValue: "MutualFund", value: "Mutual Fund" },
  { name: "Bridge Loan Proceeds", ediValue: "F7", mismoValue: "BridgeLoanNotDeposited", value: "Bridge Loan Proceeds" },
  { name: "Trust Account", ediValue: "11", mismoValue: "TrustAccount", value: "Trust Account" },
  { name: 'Business Owned', ediValue: "F8", mismoValue: "BusinessOwned", value: "Business Owned" },
  { name: 'Secured Borrowed Funds ', ediValue: "F5", mismoValue: "SecuredBorrowedFunds", value: "Secured Borrowed Funds" },
];

export const PARTIAL_ASSET_TYPE = [
  { name: "Cash Value of Life Insurance", ediValue: '', mismoValue: "LifeInsurance", value: "Cash Value of Life Insurance" },
  { name: "Stocks", ediValue: "05", mismoValue: "Stock", value: "Stocks" },
  { name: "Bonds", ediValue: "06", mismoValue: "Bond", value: "Bonds" },
  { name: "Retirement (e.g., 401k, IRA)", ediValue: "08", mismoValue: "RetirementFund", value: "Retirement (e.g., 401k, IRA)" },
]

export const OTHER_ASSET_DETAIL = [
  { name: "Other Non-Liquid Asset", ediValue: "M1", mismoValue: "OtherNonLiquidAsset", value: "Other Non-Liquid Asset" },
  { name: "Other Liquid Asset", ediValue: "OL", mismoValue: "OtherLiquidAsset", value: "Other Liquid Asset" },

]

export const OTHER_ASSET = [
  { name: "Earnest Money", mismoValue: "EarnestMoney" },
  { name: "Proceeds from Sale of Non-Real Estate Asset", mismoValue: "ProceedsFromSaleOfNonRealEstateAsset" },
  { name: "Proceeds from Real Estate Property to be sold on or before closing", mismoValue: "PendingNetSaleProceedsFromRealEstateAssets" },
  { name: "Sweat Equity", mismoValue: "SweatEquity" },
  { name: "Employer Assistance", mismoValue: "EmployerAssistedHousing" },
  { name: "Secured Borrowed Funds", mismoValue: "ProceedsFromSecuredLoan" },
  { name: "Trade Equity", mismoValue: "TradeEquityFromPropertySwap" },
  { name: "Unsecured Borrowed Funds", mismoValue: "ProceedsFromUnsecuredLoan" },
  { name: "Other", mismoValue: "Other" },
  // Rent Credit
];

export const LIABILITIES_TYPE = [
  { name: "Revolving (e.g., credit cards)", ediValue: "R", mismoValue: "Revolving", value: "Revolving" },
  { name: "Installment (e.g., car, student, personal loans)", ediValue: "I", mismoValue: "Installment", value: "Installment" },
  { name: "Open 30-Day (balance paid monthly)", ediValue: "O", mismoValue: "Open30DayChargeAccount", value: "Open 30-Day" },
  { name: "Lease (not real estate)", ediValue: "F", mismoValue: "LeasePayment", value: "Lease" },
  { name: "Other", ediValue: "Z", mismoValue: "Other", value: "Other" },
  { name: "Taxes", ediValue: "A", mismoValue: "Taxes", value: "Taxes" },
  { name: "Liens", ediValue: "N", mismoValue: "TaxLien", value: "Liens" },
  { name: "HELOC", ediValue: "C", mismoValue: "HELOC", value: "HELOC" },
  { name: "Mortgage Loan", ediValue: "M", mismoValue: "MortgageLoan", value: "Mortgage Loan" }
];

export const MISMO_LIABILITIES_TYPE = [
  { name: "Revolving (e.g., credit cards)", ediValue: "R", mismoValue: "Revolving", value: "Revolving" },
  { name: "Installment (e.g., car, student, personal loans)", ediValue: "I", mismoValue: "Installment", value: "Installment" },
  { name: "Open 30-Day (balance paid monthly)", ediValue: "O", mismoValue: "Open30DayChargeAccount", value: "Open30DayChargeAccount" },
  { name: "Lease (not real estate)", ediValue: "F", mismoValue: "LeasePayment", value: "LeasePayment" },
  { name: "Other", ediValue: "Z", mismoValue: "Other", value: "Other" },
  { name: "HELOC", ediValue: "C", mismoValue: "HELOC", value: "HELOC" },
  { name: "Mortgage Loan", ediValue: "M", mismoValue: "MortgageLoan", value: "Mortgage Loan" },
];

// DU only support FHA
export const LIABILITIES_MORTGAGE_TYPE = [
  { name: "FHA", ediValue: "", mismoValue: "FHA", value: "FHA" },
  // { name: "VA", ediValue: "", mismoValue: "VA", value: "VA" },
  // { name: "Conventional", ediValue: "", mismoValue: "Conventional", value: "Conventional" },
  // { name: "USDA-RD", ediValue: "", mismoValue: "USDARD", value: "USDARD" },
  // { name: "Other", ediValue: "", mismoValue: "Other", value: "Other" },
];

export const MISMO_EXPENSES_TYPE = [
  { name: "Alimony", ediValue: "DR", mismoValue: "Alimony", value: "Alimony" },
  { name: "Child Support", ediValue: "DT", mismoValue: "ChildSupport", value: "ChildSupport" },
  { name: "Separate Maintenance", ediValue: "DV", mismoValue: "SeparateMaintenanceExpense", value: "SeparateMaintenanceExpense" },
  { name: "Job Related Expenses", ediValue: "DZ", mismoValue: "JobRelatedExpenses", value: "JobRelatedExpenses" },
  { name: "Other", ediValue: "EE", mismoValue: "Other", value: "Other" },
];

export const MISMO_GIFTS_TYPE = [
  { name: "Gift Of Cash", ediValue: "", mismoValue: "GiftOfCash", value: "GiftOfCash" },
  { name: "Gift Of Property Equity", ediValue: "", mismoValue: "GiftOfPropertyEquity", value: "GiftOfPropertyEquity" },
  { name: "Grant", ediValue: "", mismoValue: "Grant", value: "Grant" },
];

export const MISMO_GIFTS_FUNDS_SOURCE_TYPE = [
  { name: "Community Non Profit", ediValue: "", mismoValue: "CommunityNonProfit", value: "CommunityNonProfit" },
  { name: "Employer", ediValue: "", mismoValue: "Employer", value: "Employer" },
  { name: "Federal Agency", ediValue: "", mismoValue: "FederalAgency", value: "FederalAgency" },
  { name: "Local Agency", ediValue: "", mismoValue: "LocalAgency", value: "LocalAgency" },
  { name: "Lender", ediValue: "", mismoValue: "Lender", value: "Lender" },
  { name: "Other", ediValue: "", mismoValue: "Other", value: "Other" },
  { name: "Relative", ediValue: "", mismoValue: "Relative", value: "Relative" },
  { name: "Religious Non Profit", ediValue: "", mismoValue: "ReligiousNonProfit", value: "ReligiousNonProfit" },
  { name: "State Agency", ediValue: "", mismoValue: "StateAgency", value: "StateAgency" },
  { name: "Unmarried Partner", ediValue: "", mismoValue: "UnmarriedPartner", value: "UnmarriedPartner" },
];

export const LIABILITIES_EXPENSES = [
  { name: "Alimony", ediValue: "DR", mismoValue: "Alimony", value: "Alimony" },
  { name: "Child Support", ediValue: "DT", mismoValue: "ChildSupport", value: "Child Support" },
  { name: "Separate Maintenance", ediValue: "DV", mismoValue: "SeparateMaintenanceExpense", value: "Separate Maintenance" },
  { name: "Job Related Expenses", ediValue: "DZ", mismoValue: "JobRelatedExpenses", value: "Job Related Expenses" },
  { name: "Other", ediValue: "EE", mismoValue: "Other", value: "Other" }
];

export const DEMOGRAPHY_CHANNEL_TYPE = [
  { name: "Face-to-Face Interview", ediValue: "F", mismoValue: "FaceToFace", value: "Face-to-face interview" },
  { name: "Fax or Mail", ediValue: "M", mismoValue: "Fax", value: "Fax Or Mail" },
  { name: "Telephone Interview", ediValue: "T", mismoValue: "Telephone", value: "Telephone InterVew" },
  { name: "E-mail or Internet", ediValue: "I", mismoValue: "Internet", value: "Email Or Internet" }
];

export const MISMO_DEMOGRAPHY_CHANNEL_TYPE = [
  { name: "Face-to-Face Interview", ediValue: "", mismoValue: "FaceToFace", value: "Face-to-face interview" },
  { name: "Fax", ediValue: "", mismoValue: "Fax", value: "Fax" },
  { name: "Mail", ediValue: "", mismoValue: "Mail", value: "Mail" },
  { name: "Telephone Interview", ediValue: "", mismoValue: "Telephone", value: "Telephone Interview" },
  { name: "Internet", ediValue: "", mismoValue: "Internet", value: "Internet" },
  { name: "E-mail", ediValue: "", mismoValue: "Email", value: "Email" }
];

// export const REFINANCE_PURPOSE = [
//   { name: "No Cash Out", ediValue: "F1", mismoValue: "NoCashOut", value: "No Cash Out" },
//   { name: "Cash-Out/Other", ediValue: "01", mismoValue: "Cash-out/Other", value: "Cash-Out/Other" },
//   { name: "Cash-Out/Home Improvement", ediValue: "04", mismoValue: "Cash-Out/Home Improvement", value: "Cash-Out/Home Improvement" },
//   { name: "Cash-Out/Debt Consolidation", ediValue: "11", mismoValue: "Cash-Out/Debt Consolidation", value: "Cash-Out/Debt Consolidation" },
//   { name: "Limited Cash-Out", ediValue: "13", mismoValue: "Limited Cash-Out", value: "Limited Cash-Out" }
// ];

export const REFINANCE_PURPOSE = [
  { name: "Cash-Out", ediValue: "01", mismoValue: "CashOut", value: "Cash-Out/Other" },
  { name: "Streamline", ediValue: "F1", mismoValue: "NoCashOut", value: "No Cash Out" },
  // { name: "Cash-Out/Home Improvement", ediValue: "04", mismoValue: "Cash-Out/Home Improvement", value: "Cash-Out/Home Improvement" },
  // { name: "Cash-Out/Debt Consolidation", ediValue: "11", mismoValue: "Cash-Out/Debt Consolidation", value: "Cash-Out/Debt Consolidation" },
  { name: "Rate-And-Term", ediValue: "13", mismoValue: "LimitedCashOut", value: "Limited Cash-Out" },
];

export const REFINANCE_PROGRAM = [
  { name: "Full Documentation", ediValue: "", mismoValue: "FullDocumentation", value: "Full Documentation" },
  { name: "Interest Rate Reduction Refinance Loan", ediValue: "", mismoValue: "InterestRateReductionRefinanceLoan", value: "Interest RateReduction Refinance Loan" },
  { name: "Streamline Without Appraisal", ediValue: "", mismoValue: "StreamlineWithoutAppraisal", value: "Streamline Without Appraisal" },
  { name: "Other", ediValue: "", mismoValue: "Other", value: "Other" },
];

export const ACCESS_RIGHTS_COMPONENTS = [
  {
    component: "manageUsers",
    canAccess: false,
    subSections: []
  },
  {
    component: "startNewLoan",
    canAccess: false,
    subSections: []
  },
  {
    component: "sharePipeline",
    canAccess: false,
    subSections: []
  },
  {
    component: "wemloLeads",
    canAccess: false,
    subSections: []
  },
  {
    component: "brokerDashboard",
    canAccess: false,
    subSections: []
  },
  {
    component: "allAccess",
    canAccess: false,
    subSections: []
  },
  {
    component: "list-processor-users",
    canAccess: false,
    subSections: []
  }
];
export const AUS = [
  //edit Code and misocode in not found but it can be use in future
  { ppeValue: "0", name: "List All", ediValue: "", mismoValue: "", value: "List All" },
  { ppeValue: "2", name: "DO / DU FNMA", ediValue: "", mismoValue: "", value: "DO / DU FNMA" },
  { ppeValue: "1", name: "LP FHLMC", ediValue: "", mismoValue: "", value: "LP FHLMC" },
  { ppeValue: "3", name: "Manual UW", ediValue: "", mismoValue: "", value: "Manual UW" }
];

export const PRODUCT_TYPE = [
  // { value: "55", name: "Conventional" },
  { ppeValue: "1", name: "Conforming", ediValue: "01", mismoValue: "Conventional", value: "Conventional" },
  { ppeValue: "5", name: "Affordable Products", ediValue: "01", mismoValue: "Affordable Products", value: "Conventional" },
  { ppeValue: "7", name: "Flex / All", ediValue: "01", mismoValue: "Flex / All", value: "Conventional" },
  { ppeValue: "2", name: "Non Conforming", ediValue: "01", mismoValue: "Non Conforming", value: "Conventional" },
  { ppeValue: "3", name: "FHA", ediValue: "03", mismoValue: "FHA", value: "FHA" },
  { ppeValue: "35", name: "FHA 203K", ediValue: "03", mismoValue: "FHA 203K", value: "FHA" },
  { ppeValue: "36", name: "FHA HUD S100 REO", ediValue: "03", mismoValue: "FHA HUD S100 REO", value: "FHA" },
  { ppeValue: "4", name: "VA", ediValue: "02", mismoValue: "VA", value: "VA" },
  { ppeValue: "54", name: "USDA Rural", ediValue: "04", mismoValue: "USDA Rural", value: "USDA Rural" },
  { ppeValue: "11", name: "Fannie A Minus L1", ediValue: "01", mismoValue: "Fannie A Minus L1", value: "Conventional" },
  { ppeValue: "12", name: "Fannie A Minus L2", ediValue: "01", mismoValue: "Fannie A Minus L2", value: "Conventional" },
  { ppeValue: "13", name: "Fannie A Minus L3", ediValue: "01", mismoValue: "Fannie A Minus L3", value: "Conventional" },
  { ppeValue: "15", name: "Freddie A Minus L1", ediValue: "01", mismoValue: "Freddie A Minus L1", value: "Conventional" },
  { ppeValue: "16", name: "Freddie A Minus L2", ediValue: "01", mismoValue: "Freddie A Minus L2", value: "Conventional" },
  { ppeValue: "17", name: "Freddie A Minus L3", ediValue: "01", mismoValue: "Freddie A Minus L3", value: "Conventional" },
  { ppeValue: "18", name: "Freddie A Minus L4", ediValue: "01", mismoValue: "Freddie A Minus L4", value: "Conventional" },
  { ppeValue: "19", name: "Freddie A Minus L5", ediValue: "01", mismoValue: "Freddie A Minus L5", value: "Conventional" },
  { ppeValue: "", name: "Other", ediValue: "07", mismoValue: "Other", value: "Other" },
];

export const PROPERTY_TYPE = [
  { ppeValue: "2", name: "Single Family Detached", ediValue: "01", mismoValue: "SingleFamilyDetached", value: "Single Family Detached" },
  { ppeValue: "1", name: "Single Family Attached", ediValue: "02", mismoValue: "SingleFamilyAttached", value: "Single Family Attached" },
  { ppeValue: "6", name: "Manufactured - Single Wide", ediValue: "10", mismoValue: "Manufactured-Single Wide", value: "Manufactured - Single Wide" },
  { ppeValue: "7", name: "Manufactured - Double Wide", ediValue: "08", mismoValue: "Manufactured-Double Wide", value: "Manufactured - Double Wide" },
  { ppeValue: "8", name: "Coop under 5 Stories", ediValue: "05", mismoValue: "Coop under 5 Stories", value: "Coop under 5 Stories" },
  { ppeValue: "9", name: "Coop 5-8 Stories", ediValue: "05", mismoValue: "Coop 5-8 Stories", value: "Coop 5-8 Stories" },
  { ppeValue: "10", name: "Coop Over 8 Stories", ediValue: "05", mismoValue: "Coop Over 8 Stories", value: "Coop Over 8 Stories" },
  { ppeValue: "13", name: "Condo Under 5 Stories", ediValue: "03", mismoValue: "Condo Under 5 Stories", value: "Condo Under 5 Stories" },
  { ppeValue: "14", name: "Condo 5-8 Stories", ediValue: "03", mismoValue: "Condo 5-8 Stories", value: "Condo 5-8 Stories" },
  { ppeValue: "15", name: "Condo Over 8 Stories", ediValue: "07", mismoValue: "Condo Over 8 Stories", value: "Condo Over 8 Stories" },
  { ppeValue: "16", name: "Condotel", ediValue: "03", mismoValue: "Condotel", value: "Condotel" },
  { ppeValue: "23", name: "Detached Condo", ediValue: "09", mismoValue: "Detached Condo", value: "Detached Condo" },
  { ppeValue: "18", name: "Planned Unit Development", ediValue: "04", mismoValue: "Planned Unit Development", value: "Planned Unit Development" },
]

export const ADDRESS_UNIT_DESIGNATOR_TYPE  = [
  { ppeValue: "", name: "Apartment", ediValue: "", mismoValue: "Apartment", value: "Apartment" },
  { ppeValue: "", name: "Basement", ediValue: "", mismoValue: "Basement", value: "Basement" },
  { ppeValue: "", name: "Building", ediValue: "", mismoValue: "Building", value: "Building" },
  { ppeValue: "", name: "Condo", ediValue: "", mismoValue: "Condo", value: "Condo" },
  { ppeValue: "", name: "Department", ediValue: "", mismoValue: "Department", value: "Department" },
  { ppeValue: "", name: "Floor", ediValue: "", mismoValue: "Floor", value: "Floor" },
  { ppeValue: "", name: "Front", ediValue: "", mismoValue: "Front", value: "Front" },
  { ppeValue: "", name: "Hangar", ediValue: "", mismoValue: "Hangar", value: "Hangar" },
  { ppeValue: "", name: "Key", ediValue: "", mismoValue: "Key", value: "Key" },
  { ppeValue: "", name: "Lobby", ediValue: "", mismoValue: "Lobby", value: "Lobby" },
  { ppeValue: "", name: "Lot", ediValue: "", mismoValue: "Lot", value: "Lot" },
  { ppeValue: "", name: "Lower", ediValue: "", mismoValue: "Lower", value: "Lower" },
  { ppeValue: "", name: "Office", ediValue: "", mismoValue: "Office", value: "Office" },
  { ppeValue: "", name: "Penthouse", ediValue: "", mismoValue: "Penthouse", value: "Penthouse" },
  { ppeValue: "", name: "Pier", ediValue: "", mismoValue: "Pier", value: "Pier" },
  { ppeValue: "", name: "Rear", ediValue: "", mismoValue: "Rear", value: "Rear" },
  { ppeValue: "", name: "Room", ediValue: "", mismoValue: "Room", value: "Room" },
  { ppeValue: "", name: "Side", ediValue: "", mismoValue: "Side", value: "Side" },
  { ppeValue: "", name: "Space", ediValue: "", mismoValue: "Space", value: "Space" },
  { ppeValue: "", name: "Stop", ediValue: "", mismoValue: "Stop", value: "Stop" },
  { ppeValue: "", name: "Suite", ediValue: "", mismoValue: "Suite", value: "Suite" },
  { ppeValue: "", name: "Trailer", ediValue: "", mismoValue: "Trailer", value: "Trailer" },
  { ppeValue: "", name: "Unit", ediValue: "", mismoValue: "Unit", value: "Unit" },
  { ppeValue: "", name: "Upper", ediValue: "", mismoValue: "Upper", value: "Upper" },
];

export const REAL_ESTATE = [
  ...PROPERTY_TYPE,

  // 14 = Single Family
  // 04 = Condominium
  // 16 = Townhouse
  // 13 = Co-operative
  // 15 = Two-to-four unit property
  // 18 = Multifamily (more than 4 units)
  // 08 = Manufactured/Mobile Home
  // 02 = Commercial – Non-Residential
  // F1 = Mixed Use – Residential
  // 05 = Farm
  // 03 = Home and Business Combined
  // 07 = Land
];

export const OCCUPANCY = [
  { ppeValue: "2", name: "Second Home", ediValue: "2", mismoValue: "SecondHome", value: "Second Home" },
  { ppeValue: "3", name: "Investment", ediValue: "D", mismoValue: "Investment", value: "Investment" },
  { ppeValue: "1", name: "Owner Occupied", ediValue: "1", mismoValue: "PrimaryResidence", value: "Primary Residence" } // Primary residence
];

export const TITLE_MANNER_HELD = [
  { name: "Joint Tenant" },
  { name: "Joint Tenants With Rights Of Survivorship" },
  { name: "Married" },
  { name: "Single Man" },
  { name: "Single Woman" },
  { name: "Tenants By Entirety" },
  { name: "Tenants In Common" },
  { name: "To Be Determined" },
  { name: "Unmarried Woman" },
  { name: "Unmarried Man"},
  { name: "Other" }
];

export const MISMO_TITLE_MANNER_HELD = [
  { name: "Individual", mismoValue: "Individual" },    
  { name: "Joint Tenants With Right Of Survivorship", mismoValue: "JointTenantsWithRightOfSurvivorship"},
  { name: "Life Estate", mismoValue: "LifeEstate" },  
  { name: "Tenants By The Entirety", mismoValue: "TenantsByTheEntirety" },
  { name: "Tenants In Common", mismoValue: "TenantsInCommon" },  
  { name: "Other", mismoValue: "Other" }
];


export const AMORTIZATION_TYPE = [
  { ppeValue: "1", name: "Fixed", ediValue: "05", mismoValue: "Fixed", value: "Fixed Rate" },
  { ppeValue: "6", name: "Other", ediValue: "13", mismoValue: "Other", value: "Other" },
  { ppeValue: "7", name: "GPM", ediValue: "06", mismoValue: "GPM", value: "GPM" },
  { ppeValue: "8", name: "GEM", ediValue: "04", mismoValue: "GEM", value: "GEM" },
  { ppeValue: "2", name: "ARM", ediValue: "01", mismoValue: "AdjustableRate", value: "Adjustable Rate" },
  // { value: "3", name: "Ballon" },
  // { ppeValue: "4", name: "Fixed Buydown 2/1" },
  // { ppeValue: "5", name: "Fixed Buydown 1/0" }
];

export const LOAN_PURPOSE = [
  //in the case of cash-out refinance and R/T refi(limited cash) the refinance will be save on both the
  { ppeValue: "1", name: "Purchase", ediValue: "16", mismoValue: "Purchase", value: "Purchase" },
  { ppeValue: "2", name: "Cash-Out Refinance", ediValue: "05", mismoValue: "Cash-OutRefinance", value: "Cash-Out Refinance" },
  { ppeValue: "3", name: "R/T Refi (Limited Cash-Out)", ediValue: "05", mismoValue: "R/TRefi(Limited Cash-Out)", value: "R/T Refi (Limited Cash-Out)" }
  //   "Construction",
  //   "Construction-Permanent",
  //   "Other"
];

export const LOAN_TERM = [
  { ppeValue: "1", name: "10 Years", ediValue: "", mismoValue: "10", value: 12 * 10 },
  { ppeValue: "2", name: "15 Years", ediValue: "", mismoValue: "15", value: 12 * 15 },
  { ppeValue: "3", name: "20 Years", ediValue: "", mismoValue: "20", value: 12 * 20 },
  { ppeValue: "4", name: "25 Years", ediValue: "", mismoValue: "25", value: 12 * 25 },
  { ppeValue: "5", name: "30 Years", ediValue: "", mismoValue: "30", value: 12 * 30 },
  { ppeValue: "6", name: "35 Years", ediValue: "", mismoValue: "35", value: 12 * 35 },
  { ppeValue: "7", name: "40 Years", ediValue: "", mismoValue: "40", value: 12 * 40 }
];

export const EMPLOYMENT_TYPE = [
  { ppeValue: "1", name: "Wage Earner", ediValue: "", mismoValue: "WageEarner", value: "Wage Earner" },
  { ppeValue: "2", name: "Self Employed", ediValue: "", mismoValue: "SelfEmployed", value: "Self Employed" },
  { ppeValue: "3", name: "Fixed Income", ediValue: "", mismoValue: "FixedIncome", value: "Fixed Income" }
];

export const ESCROW_TYPE = [
  { ppeValue: "1", name: "None Waived", ediValue: "N", mismoValue: "NoneWaived", value: "None Waived" },
  { ppeValue: "2", name: "All Waived", ediValue: "Y", mismoValue: "AllWaived", value: "All Waived" },
  { ppeValue: "3", name: "Hazard Insurance", ediValue: "Y", mismoValue: "HazardInsurance", value: "Hazard Insurance" },
  { ppeValue: "4", name: "Property Taxes Waived", ediValue: "Y", mismoValue: "PropertyTaxesWaived", value: "Property Taxes Waived" },
];

export const TRANSACTION_OTHER_CREDITS = [
  { name: "Cash Deposit on sales contract", ediValue: "01", mismoValue: "Cash Deposit on sales contract", value: "Cash Deposit on sales contract" },
  { name: "Seller Credit", ediValue: "02", mismoValue: "Seller Credit", value: "Seller Credit" },
  { name: "Lender Credit", ediValue: "03", mismoValue: "Lender Credit", value: "Lender Credit" },
  { name: "Relocation Funds", ediValue: "04", mismoValue: "Relocation Funds", value: "Relocation Funds" },
  { name: "Employer Assistance", ediValue: "05", mismoValue: "Employer Assisted Housing", value: "Employer Assistance" },
  { name: "Lease Purchase Fund", ediValue: "06", mismoValue: "Lease Purchase Fund", value: "Lease Purchase Fund" },
  { name: "Other", ediValue: "07", mismoValue: " Other", value: "Other" },
  { name: "Borrower Paid Fees", ediValue: "08", value: "Borrower Paid Fees" }
]

export const MISMO_TRANSACTION_OTHER_CREDITS = [
  { name: 'Subordinate Financing Proceeds', ediValue: '', mismoValue: 'SubordinateFinancingProceeds', value: 'Subordinate Financing Proceeds' },
  { name: 'Repairs', ediValue: '', mismoValue: 'repairs', value: 'Repairs' },
  { name: 'Sellers Mortgage Insurance Assumption', ediValue: '', mismoValue: 'SellersMortgageInsuranceAssumption', value: 'Sellers Mortgage Insurance Assumption' },
  { name: 'Sellers Reserve Account Assumption', ediValue: '', mismoValue: 'SellersReserveAccountAssumption', value: 'Sellers Reserve Account Assumption' },
  { name: 'Proceeds Of Subordinate Liens', ediValue: '', mismoValue: 'ProceedsOfSubordinateLiens', value: 'Proceeds Of Subordinate Liens' },
  { name: 'Satisfaction Of Subordinate Lien', ediValue: '', mismoValue: 'SatisfactionOfSubordinateLien', value: 'Satisfaction Of Subordinate Lien' },
  { name: 'Repair Completion Escrow Holdback', ediValue: '', mismoValue: 'RepairCompletionEscrowHoldback', value: 'Repair Completion Escrow Holdback' },
  { name: 'Other', ediValue: '', mismoValue: 'other', value: 'Other' },
  { name: 'Unpaid Utility Escrow Holdback', ediValue: '', mismoValue: 'UnpaidUtilityEscrowHoldback', value: 'Unpaid Utility Escrow Holdback' },
  { name: 'Tenant Security Deposit', ediValue: '', mismoValue: 'TenantSecurityDeposit', value: 'Tenant Security Deposit' },
  { name: 'Rebate Credit', ediValue: '', mismoValue: 'RebateCredit', value: 'Rebate Credit' },
  { name: 'Relocation Funds', ediValue: '', mismoValue: 'RelocationFunds', value: 'Relocation Funds' },
  { name: 'Seller Credit', ediValue: '', mismoValue: 'SellerCredit', value: 'Seller Credit' },
  { name: 'Sellers Escrow Assumption', ediValue: '', mismoValue: 'SellersEscrowAssumption', value: 'Sellers Escrow Assumption' },
  { name: 'Lender Credit', ediValue: '', mismoValue: 'LenderCredit', value: 'Lender Credit' },
  { name: 'Fuel Costs', ediValue: '', mismoValue: 'FuelCosts', value: 'Fuel Costs' },
  { name: 'Services', ediValue: '', mismoValue: 'services', value: 'Services' },
  { name: 'Trade Equity', ediValue: '', mismoValue: 'TradeEquity', value: 'Trade Equity' },
  { name: 'Gift', ediValue: '', mismoValue: 'gift', value: 'Gift' },
  { name: 'Rent From Subject Property', ediValue: '', mismoValue: 'RentFromSubjectProperty', value: 'Rent From Subject Property' },
  { name: 'Grant', ediValue: '', mismoValue: 'grant', value: 'Grant' },
  { name: 'Title Premium Adjustment', ediValue: '', mismoValue: 'TitlePremiumAdjustment', value: 'Title Premium Adjustment' },
  { name: 'Sweat Equity', ediValue: '', mismoValue: 'SweatEquity', value: 'Sweat Equity' },
];

export const TITLE_HOLD_TYPE = [
  { name: "S", ediValue: "01", mismoValue: "Solo", value: "S" },
  { name: "SP", ediValue: "25", mismoValue: "JointWithSpouse", value: "SP" },
  { name: "O", ediValue: "26", mismoValue: "JointWithOtherThanSpouse", value: "O" }
];

export const DOCUMENT_TYPE = [
  { ppeValue: "1", name: "Full Doc", ediValue: "", mismoValue: "FullDoc", value: "Full Doc" },
  { ppeValue: "2", name: "SIVA - Stated", ediValue: "", mismoValue: "SIVA-Stated", value: "SIVA - Stated" },
  { ppeValue: "6", name: "SISA", ediValue: "", mismoValue: "SISA", value: "SISA" },
  { ppeValue: "4", name: "NIVA - No Ratio", ediValue: "", mismoValue: "SISA", value: "SISA" },
  { ppeValue: "5", name: "NINA - No Doc", ediValue: "", mismoValue: "NINA- No Doc", value: "NINA - No Doc" },
  { ppeValue: "3", name: "NINANE - True No Doc", ediValue: "", mismoValue: "NINANE-True No Doc", value: "NINANE - True No Doc" }
];

export const PRE_PAY_PENALTY = [
  { ppeValue: "1", name: "No PPP", ediValue: "", mismoValue: "NoPPP", value: "No PPP" },
  { ppeValue: "2", name: "1 Year & Under", ediValue: "", mismoValue: "1 Year & Under", value: "1 Year & Under" },
  { ppeValue: "3", name: "2 Year & Under", ediValue: "", mismoValue: "2 Year & Under", value: "2 Year & Under" },
  { ppeValue: "4", name: "3 Year & Under", ediValue: "", mismoValue: "3 Year & Under", value: "3 Year & Under" },
  { ppeValue: "5", name: "4 Year & Under", ediValue: "", mismoValue: "4 Year & Under", value: "4 Year & Under" },
  { ppeValue: "6", name: "5 Year & Under", ediValue: "", mismoValue: "5 Year & Under", value: "5 Year & Under" }
];

export const INVESTOR_OPTION = [
  { ppeValue: "0", name: "Select All Investor", ediValue: "", mismoValue: "Select All Investor", value: "Select All Investor" },
  { ppeValue: "-1", name: "Select From List", ediValue: "", mismoValue: "Select From List", value: "Select From List" }
];

export const GIFT_SOURCE_TYPE = [
  { name: "Community Nonprofit", mismoValue: "CommunityNonProfit", value: "CommunityNonProfit" },
  { name: "Employer", mismoValue: "Employer", value: "Employer" },
  { name: "Federal Agency", mismoValue: "FederalAgency", value: "FederalAgency" },
  { name: "Lender", mismoValue: "Lender", value: "Lender" },
  { name: "Local Agency", mismoValue: "LocalAgency", value: "LocalAgency" },
  { name: "Other", mismoValue: "Other", value: "Other" },
  { name: "Relative", mismoValue: "Relative", value: "Relative" },
  { name: "Religious Nonprofit", mismoValue: "ReligiousNonProfit", value: "ReligiousNonProfit" },
  { name: "State Agency", mismoValue: "StateAgency", value: "StateAgency" },
  { name: "Unmarried Partner", mismoValue: "UnmarriedPartner", value: "UnmarriedPartner" },
];
export const GIFT_TYPE = [
  { name: "Cash Gift", mismoValue: "GiftOfCash", value: "Cash Gift " },
  { name: "Gift of Equity", mismoValue: "GiftOfPropertyEquity", value: "Gift of Equity" },
  { name: "Grant", mismoValue: "Grant", value: "Grant" }];


export const CITIZEN_SHIP = [
  {
    name: "U.S. Citizen",
    value: "U.S. Citizen",
    mismoValue: "USCitizen",
    ediValue: "01"
  },
  {
    name: "Permanent Resident Alien",
    value: "Permanent Resident Alien",
    mismoValue: "PermanentResidentAlien",
    ediValue: "03"
  },
  {
    name: "Non-Permanent Resident Alien",
    value: "Non-Permanent Resident Alien",
    mismoValue: "NonPermanentResidentAlien",
    ediValue: "05"
  }
];

export const MARITAL_STATUS = [
  { name: "Married", ediValue: "M", mismoValue: "Married", value: "Married" },
  { name: "Separated", ediValue: "S", mismoValue: "Separated", value: "Separated" },
  { name: "Unmarried", ediValue: "U", mismoValue: "Unmarried", value: "Unmarried" }
];

export const MARITAL_STATUS_UNMARRIED = [
  { name: "Single", ediValue: "S", mismoValue: "Single", value: "Single" },
  { name: "Divorced", ediValue: "D", mismoValue: "Divorced", value: "Divorced" },
  { name: "Widowed", ediValue: "W", mismoValue: "Widowed", value: "Widowed" }
];

export const UNMARRIED_RELATIONSHIP = [
  { name: "Yes", ediValue: "Y", mismoValue: "Yes", value: true },
  { name: "No", ediValue: "N", mismoValue: "No", value: false },
];


export const SELF_EMPLOYED_STATUS = [
  { name: "SCH-C", ediValue: "SH", mismoValue: "SCH-C", value: "SCH-C" },
  { name: "Partnership", ediValue: "D", mismoValue: "Partnership", value: "Partnership" },
  { name: "S-Corp", ediValue: "SC", mismoValue: "S-Corp", value: "S-Corp" }
];

export const SELF_EMPLOYED_STATUS_LABELS = {
  'SCH-C': 'Schedule C',
  'Partnership': 'Partnership',
  'S-Corp': 'S-Corp',
};

export const REFINANCE_EFFORT = [
  { name: "Yes - Ask for document", value: true },
  { name: "No", value: false },
];


export const DOWN_PAYMENT_TYPE = [
  { name: "My own real estate", value: "My own real estate" },
  { name: "Non liquid assets", value: "Non liquid assets" },
];

export const RESIDENCE_BASIS_TYPE = [
  { name: "No Primary Housing Expense", ediValue: "X", mismoValue: "LivingRentFree", value: "No Primary Housing Expense" },
  { name: "Own", ediValue: "O", mismoValue: "Own", value: "Own" },
  { name: "Rent", ediValue: "R", mismoValue: "Rent", value: "Rent" }];


export const CONSTRUCTION_CLOSING_TYPE = [
  { name: "Single Closing", value: "Single Closing" },
  { name: "Two Closing", value: "Two Closing" },
];


export const REAL_ESTATE_STATUS_TYPE = [
  { name: "Sold", ediValue: "S", mismoValue: "Sold", value: "Sold" },
  { name: "Retained", ediValue: "H", mismoValue: "Retain", value: "Retained" },
  { name: "Pending Sale", ediValue: "P", mismoValue: "PendingSale", value: "Pending Sale" },
  { name: "Rental", ediValue: "R", mismoValue: "Rental", value: "Rental" },
];

export const MISMO_REAL_ESTATE_STATUS_TYPE = [
  { name: "Sold", ediValue: "S", mismoValue: "Sold", value: "Sold" },
  { name: "Retained", ediValue: "H", mismoValue: "Retain", value: "Retained" },
  { name: "Pending Sale", ediValue: "P", mismoValue: "PendingSale", value: "Pending Sale" },
];

export const REAL_ESTATE_MORTGAGE_TYPE = ["FHA", "VA", "Conventional", "USDA-RD", "Other"];


export const ANOTHER_PROPERTY_TYPE = [
  { name: "IP", ediValue: "D", mismoValue: "Investment", value: "Investment" },
  { name: "PR", ediValue: "1", mismoValue: "PrimaryResidence", value: "Primary Residence" },
  { name: "SH", ediValue: "2", mismoValue: "SecondHome", value: "Second Home" },
  { name: "SR", ediValue: "3", mismoValue: "FHASecondaryResidence", value: "FHASecondaryResidence" }
]

export const LEGAL_DESCRIPTION = [
  { name: "Other", ediValue: "F1" },
  { name: "Metes and Bounds", ediValue: "02" },
]

export const DOWN_PAYMENT_SOURCE = [
  { name: "Checking/Savings", ediValue: "F1" },
  { name: "Deposit on Sales Contract", ediValue: "F2" },
  { name: "Equity Sold on Property", ediValue: "F3" },
  { name: "Equity Pending from Sale", ediValue: "03" },
  { name: "Equity Pending from Subject Property", ediValue: "F4" },
  { name: "Gift Funds", ediValue: "04" },
  { name: "Stocks and bonds", ediValue: "F5" },
  { name: "Lot Equity", ediValue: "10" },
  { name: "Bridge Loan", ediValue: "09" },
  { name: "Unsecured Borrowed Funds", ediValue: "01" },
  { name: "Trust Funds", ediValue: "F6" },
  { name: "Retirement Funds", ediValue: "F7" },
  { name: "Rent with option to purchase", ediValue: "11" },
  { name: "Life insurance cash value", ediValue: "F8" },
  { name: "Sale of Chattel", ediValue: "14" },
  { name: "Trade Equity", ediValue: "07" },
  { name: "Sweat Equity", ediValue: "06" },
  { name: "Cash on Hand", ediValue: "02" },
  { name: "Secured Borrowed Funds", ediValue: "28" },
  { name: "FHA – Gift – Source N/A", ediValue: "H0" },
  { name: "FHA – Gift – Source Relative", ediValue: "H1" },
  { name: "FHA – Gift – Source Government Assistance", ediValue: "H3" },
  { name: "FHA – Gift – Source Employer", ediValue: "H6" },
  { name: "FHA – Gift – Source Nonprofit/Religious/Community – Seller Funded", ediValue: "H4" },
  { name: "FHA – Gift – Source Nonprofit/Religious/Community – Non-Seller Funded", ediValue: "H5" },
  { name: "Other", ediValue: "13" },
]


export const BROKER_NAV_LINKS = () => [
  {
    name: "Dashboard",
    route: "/broker/dashboard",
    icon: "icon-wemlo10 font__size14"
  },
  {
    name: "Loans",
    route: "/broker/borrower-list",
    icon: "icon-wemlo3 font__size14"
  },
  {
    name: "Closing Schedule",
    route: "/broker/closing-schedule",
    icon: "icon-wemlo2 font__size14"
  },
  {
    name: "Compliance",
    route: "/broker/compliance",
    icon: "icon-wemlo6 font__size14"
  },
  // {
  //   name: "Lender Relations",
  //   route: "/broker/lender-relations",
  //   icon: "icon-wemlo11 font__size14"
  // },
  // {
  //   name: "Borrower Qualifier",
  //   route: "/broker/borrower-qualifier",
  //   icon: "icon-wemlo23 font__size14"
  // },
  // {
  //   name: "Manage Users",
  //   route: "/broker/manage-users",
  //   icon: "icon-wemlo7 font__size14"
  // },
  // {
  //   name: "My Account",
  //   route: "/broker/my-account",
  //   icon: "icon-wemlo4 font__size14"
  // },
  // {
  //   name: "My Account",
  //   route: "/broker/my-wemlo-account",
  //   icon: "icon-wemlo4 font__size14"
  // },
  // {
  //   name: "Manage Vendors",
  //   route: "/broker/manage-vendors",
  //   icon: "icon-wemlo8 font__size14"
  // },
  

  {
    name: "Scenario Desk",
    route: "/broker/scenario-desk-message-list",
    icon: "icon-wemlo20 font__size18"
  },

  {
    name: "WeHelp List",
    route: "/broker/we-help-list",
    icon: "icon-wemlo87 font__size14"
  },
  {
    name: "WeLearn",
    route: "/broker/we-learn",
    icon: "learn-icon"
  },
  // {
  //   name: "Customer Retention",
  //   route: "/broker/customer-retention",
  //   icon: "icon-wemlo69 font__size14"
  //   // icon: "icon-lender"
  // },
  {
    name: "My Account",
    route: "/broker/my-wemlo-account",
    icon: "icon-wemlo4 font__size14"
  },

];

export const MLO_NAV_LINKS = () => [
  {
    name: "Dashboard",
    route: "/mlo/dashboard",
    icon: "icon-wemlo10 font__size14"
  },
  {
    name: "Loans",
    route: "/mlo/borrower-list",
    icon: "icon-wemlo3 font__size14"
  },
  {
    name: "Closing Schedule",
    route: "/mlo/closing-schedule",
    icon: "icon-wemlo2 font__size14"
  },
  // {
  //   name: "Lender Relations",
  //   route: "/mlo/lender-relations",
  //   icon: "icon-wemlo11 font__size14"
  // },
  // {
  //   name: "Compliance",
  //   route: "/mlo/compliance",
  //   icon: "icon-wemlo6 font__size18"
  // },
  // {
  //   name: "Borrower Qualifier",
  //   route: "/mlo/borrower-qualifier",
  //   icon: "icon-wemlo23 font__size14"
  // },
  // {
  //   name: "Manage Users",
  //   route: "/mlo/manage-users",
  //   icon: "icon-wemlo7 font__size14"
  // },
  // {
  //   name: "My Account",
  //   route: "/mlo/my-wemlo-account",
  //   icon: "icon-wemlo4 font__size14"
  // },
  // {
  //   name: "Manage Vendors",
  //   route: "/mlo/manage-vendors",
  //   icon: "icon-wemlo8 font__size14"
  // },
  
  {
    name: "Scenario Desk",
    route: "/mlo/scenario-desk-message-list",
    icon: "icon-wemlo20 font__size14"
  },
  {
    name: "WeHelp List",
    route: "/mlo/we-help-list",
    icon: "icon-wemlo87 font__size14"
  },
  {
    name: "WeLearn",
    route: "/mlo/we-learn",
    icon: "learn-icon"
  },
  {
    name: "My Account",
    route: "/mlo/my-wemlo-account",
    icon: "icon-wemlo4 font__size14"
  },
];

export const ADMIN_STAFF_NAV_LINKS = () => [
  {
    name: "Dashboard",
    route: "/admin-staff/dashboard",
    icon: "icon-wemlo10 font__size14"
  },
  {
    name: "Loans",
    route: "/admin-staff/borrower-list",
    icon: "icon-wemlo3 font__size14"
  },
  {
    name: "Closing Schedule",
    route: "/admin-staff/closing-schedule",
    icon: "icon-wemlo2 font__size14"
  },
  // {
  //   name: "Lender Relations",
  //   route: "/admin-staff/lender-relations",
  //   icon: "icon-wemlo11 font__size14"
  // },
  // {
  //   name: "Compliance",
  //   route: "/admin-staff/compliance",
  //   icon: "icon-wemlo6 font__size18"
  // },
  // {
  //   name: "Borrower Qualifier",
  //   route: "/admin-staff/borrower-qualifier",
  //   icon: "icon-wemlo23 font__size14"
  // },
  // {
  //   name: "Manage Users",
  //   route: "/admin-staff/manage-users",
  //   icon: "icon-wemlo7 font__size14"
  // },
  // {
  //   name: "My Account",
  //   route: "/admin-staff/my-wemlo-account",
  //   icon: "icon-wemlo4 font__size14"
  // },
  // {
  //   name: "Manage Vendors",
  //   route: "/admin-staff/manage-vendors",
  //   icon: "icon-wemlo8 font__size14"
  // },
   {
    name: "Scenario Desk",
    route: "/admin-staff/scenario-desk-message-list",
    icon: "icon-wemlo20 font__size14"
  },
  {
    name: "WeHelp List",
    route: "/admin-staff/we-help-list",
    icon: "icon-wemlo87 font__size14"
  },
  {
    name: "WeLearn",
    route: "/admin-staff/we-learn",
    // icon: "icon-wemlo87 font__size14"
    icon: "learn-icon"

  },
  {
    name: "My Account",
    route: "/admin-staff/my-wemlo-account",
    icon: "icon-wemlo4 font__size14"
  },
];

export const SUPER_ADMIN_NAV_LINKS = (entitlements = {}) => [
  {
    name: "Dashboard",
    route: "/super-admin/dashboard",
    icon: "icon-wemlo10 font__size14"
  },
  {
    name: "Brokers List",
    route: "/super-admin/broker-list",
    icon: "icon-wemlo8 font__size14"
  },
  {
    name: "Broker Loan Setup",
    route: "/super-admin/broker-loan-detail",
    icon: "icon-wemlo79 font__size14"
  },
  {
    name: "Notifications",
    route: "/super-admin/notification",
    icon: "icon-wemlo53 font__size14"
  },
  {
    name: "Loans",
    route: "/super-admin/borrower-list",
    icon: "icon-wemlo3 font__size14"
  },
//   {
//     name: "Change Password",
//     route: "/super-admin/change-password",
//     icon: "icon-wemlo80 font__size14"
//   },
  {
    name: "Processors",
    route: "/super-admin/list-processor-users",
    icon: "icon-wemlo8 font__size14"
  },
  {
    name: "Twilio Numbers",
    route: "/super-admin/manage-twilio",
    icon: "icon-wemlo34 font__size14"
  },
//
//   {
//     name: "Roles",
//     route: "/super-admin/manage-Roles",
//     icon: "icon-wemlo71 font__size14"
//   },
  {
    name: "WeHelp List",
    route: "/super-admin/we-help-list",
    icon: "icon-wemlo87 font__size14"
  },
//   {
//     name: "Wemlo Vendors",
//     route: "/super-admin/wemlo-vendors",
//     icon: "icon-wemlo8 font__size14"
//   },
  {
    name: "Report Setting",
    route: "/super-admin/report-setting",
    icon: "icon-wemlo4 font__size14"
  },
  {
    name: "Clear Activity Logs",
    route: "/super-admin/clear-activity-logs",
    icon: "icon-wemlo31 font__size14"
  },
  {
    name: "Daily Reports",
    route: "/super-admin/daily-reports",
    icon: "icon-wemlo31 font__size14"
  },
  {
    name: "Manage All User",
    route: "/super-admin/manage-all-user",
    icon: "icon-wemlo7 font__size14"
  },
  // ,
  // {
  //   name: "OCR",
  //   route: "/super-admin/review-ocr-response",
  //   icon: "icon-wemlo31 font__size14"
  // },
  {
    name: "Setting",
    route: "/super-admin/setting",
    icon: "icon-wemlo4 font__size14"
  }, 
  {
     name: "PDF Constructor",
     route: "/super-admin/pdf-constructor",
     icon: "icon-wemlo31 font__size14",
     entitlements:['pdfConstructor']
  }
].filter(e => {
        if(e.entitlements) {
            return e.entitlements.filter(item => !entitlements[item]).length === 0
        } else return true
    });

export const WEMLO_PROCESSOR_NAV_LINKS = () => [
  {
    name: "Dashboard",
    route: "/processor/dashboard",
    icon: "icon-wemlo10 font__size14"
  },
  {
    name: "Brokers List",
    route: "/processor/broker-list",
    icon: "icon-wemlo8 font__size14"
  },
  {
    name: "Loans",
    route: "/processor/borrower-list",
    icon: "icon-wemlo3 font__size14"
  },
  /**
   * This is Enabled in Next Release
   */
  // {
  //   name: "Leave Requests",
  //   route: "/processor/leave-request",
  //   icon: "icon-wemlo92"
  // },
  // {
  //   name: "My Account",
  //   route: "/processor/my-account",
  //   icon: "icon-wemlo4 font__size18"
  // },
  {
    name: "Compliance",
    route: "/processor/compliance",
    icon: "icon-wemlo6 font__size14"
  },
  {
    name: "Processors",
    route: "/processor/list-processor-users",
    icon: "icon-wemlo8 font__size18"
  },
  {
    name: "My Account",
    route: "/processor/my-account",
    icon: "icon-wemlo4 font__size14"
  },
];

export const BORROWER_NAV_LINKS = () => [
  {
    name: "Dashboard",
    route: "/borrower/dashboard",
    icon: "icon-wemlo10 font__size14"
  },
  // {
  //   name: "My Account",
  //   route: "/borrower/my-account",
  //   icon: "icon-wemlo4 font__size14"
  // },
  {
    name: "Notifications",
    route: "/borrower/notifications",
    icon: "icon-wemlo53 font__size14"
  },
  {
    name: "My Account",
    route: "/borrower/my-account",
    icon: "icon-wemlo4 font__size14"
  },
];


export const FNM_TAB_lINKS = [
  { name: "General", value: "General", class: "active" },
  { name: "Borrower", value: "PersonalInfo", class: "active" },
  { name: "Property", value: "LoanAndProperty", class: "active" },
  { name: "Income", value: "Income", class: "active" },
  { name: "Expenses", value: "Expense", class: "active" },
  { name: "Assets", value: "Asset", class: "active" },
  { name: "Liabilities", value: "Liabilities", class: "active" },
  { name: "REO", value: "RealEstateOwned", class: "active" },
  { name: "Transaction", value: "Transaction", class: "active" },
  { name: "Declarations", value: "Declaration", class: "active" },
  { name: "Monitoring", value: "Demographic", class: "active" },
  { name: "Order Service", value: "OrderService", class: "active" },
  { name: "Fee Worksheet", value: "FeeWorksheet", class: "active" },
  { name: "Forms", value: "Forms", class: "active" }
];

export const BROKER_LOAN_DOCUMENTS_EXTENSION = [
  { fileExt: "fnm" },
  { fileExt: "FNM" },
  { fileExt: "pdf" },
  { fileExt: "PDF" },
  { fileExt: "jpg" },
  { fileExt: "JPG" },
  { fileExt: "jpeg" },
  { fileExt: "JPEG" },
  { fileExt: "png" },
  { fileExt: "PNG" },
  { fileExt: "doc" },
  { fileExt: "DOC" },
  { fileExt: "docx" },
  { fileExt: "DOCX" },
  { fileExt: "xlsx" },
  { fileExt: "XLSX" },
  { fileExt: "ods" },
  { fileExt: "ODS" },
  { fileExt: "txt" },
  { fileExt: "TXT" }

];

export const CREDIT_AGENCIES = [
  { name: "Experian" },
  { name: "Transunion" },
  { name: "Equifax" }
]

export const DEMOGRAPHIC_EHINICITY = {
  hispanicOrLatino: { name: "Hispanic Or Latino", ediValue: "1", mismoValue: "HispanicOrLatino" },
  notHispanicOrLatino: { name: "Not Hispanic Or Latino", ediValue: "2", mismoValue: "NotHispanicOrLatino" },
  informationNotProvidedByApplicantMailInternetOrTelephoneApplication: { name: "Information not provided by applicant mail internet or telephone application", ediValue: "3", mismoValue: "InformationNotProvidedByApplicantInMailInternetOrTelephoneApplication" },
  notApplicable: { name: "Not Applicable", ediValue: "4", mismoValue: "NotApplicable" },
}

export const DEMOGRAPHIC_GENDER = {
  female:{ name: 'Female', ediValue: 'F', mismoValue: 'Female' },
  male:{ name: 'Male', ediValue: 'M', mismoValue: 'Male' },
  informationNotProvidedByApplicantMailInternetOrTelephoneApplication:{ name: "Information not provided by applicant mail internet or telephone application", ediValue: "I", mismoValue: "InformationNotProvidedByApplicantInMailInternetOrTelephoneApplication" },
  notApplicable:{ name: 'Not Applicable', ediValue: 'N', mismoValue: 'NotApplicable' }
}

export const MISMO_DEMOGRAPHIC_GENDER = {
  female: { name: 'Female', ediValue: 'F', mismoValue: 'Female' },
  male: { name: 'Male', ediValue: 'M', mismoValue: 'Male' },
  applicantSelectedBothMaleAndFemale: { name: 'Both male and female', ediValue: '', mismoValue: 'ApplicantSelectedBothMaleAndFemale' },
  informationNotProvidedUnknown: { name: "Information not provided", ediValue: "", mismoValue: "InformationNotProvidedUnknown" },
  notApplicable: { name: 'Not Applicable', ediValue: 'N', mismoValue: 'NotApplicable' },
  other: { name: 'Other', ediValue: '', mismoValue: 'Other' },
}

export const DEMOGRAPHIC_RACE = {
  americanIndian: { name: "American Indian Or Alaska Native", ediValue: "1", mismoValue: "AmericanIndianOrAlaskaNative" },
  asian: { name: "Asian", ediValue: "2", mismoValue: "Asian" },
  blackOrAfrican: { name: "Black Or African American", ediValue: "3", mismoValue: "BlackOrAfricanAmerican" },
  nativeHawaiianOrOtherPacific: { name: "Native Hawaiian or Other Pacific Islander", ediValue: "4", mismoValue: "NativeHawaiianOrOtherPacificIslander" },
  white: { name: "White", ediValue: "5", mismoValue: "White" },
  informationNotProvidedByApplicantMailInternetOrTelephoneApplication: { name: "Information not provided by applicant mail internet or telephone application", ediValue: "6", mismoValue: "InformationNotProvidedByApplicantInMailInternetOrTelephoneApplication" },
  notApplicable: { name: "Not Applicable", ediValue: "7", mismoValue: "NotApplicable" }
}


export const LENDER_TABS=[
  { name: "Integrated Lenders",class: "active"},{ name: "Other Lenders",class: ""},
 { name: "Custom Lenders",class: ""}
]



export const LOAN_ESTIMATE_OTHER_FIELDS = {
  'B' : [
    "Appraisal Fee",
    "Credit Report Fee",
    "Flood Certification",
    "Tax Service Fee",
    "3rd party processing Fee",
    "Property Condition Report",
    "Final Inspection",
    "Attorney Doc Prep",
    "HOA/Condo/PUD Certification",
    "Subordination Fee",
    "VOE",
    "Mortgage Insurance Premium (FHA)",
    "VA Funding Fee (VA)",
    "Homebuyers Course"
  ],
  "C": [
    "Title - Courier/Wire/Email fee",
    "Title - Endorsement Fee",
    "Title – Messenger/Carrier Fee",
    "Title – Premium for Lenders Coverage",
    "Title – Recording Fee",
    "Title – Settlement or Closing Fee",
    "Title – Abstract or Title Search",
    "Title – Title examination",
    "Title – Document Prep",
    "Title – Mobile Notary Fee",
    "Title – Lenders Attorney Fee",
    "Title – Closing Protection Letter Fee",
    "Title – Archiving Fee",
    "Title – Chain of Title",
    "Title – Escrow Fee"
  ],
  "H": [
    "Title – Owners Title Policy",
    "Attorney Fee – Borrower",
    "Home Warranty",
    "Escrow Holdback",
    "Homeowners Association Fees",
    "HOA Transfer Fee",
    "Home Inspection Fee",
    "Solar Release Fee",
    "Inspection Fee – Environmental",
    "Survey Fee",
    "Pest Inspection",
    "Compliance or Admin Fee"
  ]
}

export const LOAN_FLOW_STEPS = [
  {
    "stepNo": 1,
    "stepId": "lender",
    "stepName": "Lender",
    "hidden": false
  },
  {
    "stepNo": 2,
    "stepId": "closeDate",
    "stepName": "Close Date",
    "hidden": false
  },
  {
    "stepNo": 3,
    "stepId": "fees",
    "stepName": "Fees",
    "hidden": false
  },
  {
    "stepNo": 4,
    "stepId": "titleCo",
    "stepName": "Title",
    "hidden": false
  },
  {
    "stepNo": 5,
    "stepId": "loanEstimates",
    "stepName": "Fee Worksheet",
    "hidden": false
  },
  {
    "stepNo": null,
    "stepId": "disclosures",
    "stepName": "Disclosures",
    "hidden": false
  },
  {
    "stepNo": 6,
    "stepId": "processing",
    "stepName": "Processing",
    "hidden": false
  },
  {
    "stepNo": 7,
    "stepId": "submission",
    "stepName": "Submission",
    "hidden": false
  }
]



export const ON_BOARDING_STEPS = [
  {
    "stepNo": -1,
    "stepId": "welcome",
    "stepName": "Welcome",
    "checkUser": "MLO",
    hidden: false
  },
  {
    "stepNo": 0,
    "stepId": "lenderInfo",
    "stepName": "Lender Info",
    hidden: false
  },
  {
    "stepNo": 1,
    "stepId": "creditVendor",
    "stepName": "Credit Vendor",
    hidden: false
  },
  {
    "stepNo": 2,
    "stepId": "appraisal",
    "stepName": "Appraisal",
    hidden: false
  },
  {
    "stepNo": 3,
    "stepId": "aus",
    "stepName": "AUS",
    hidden: false
  },
  {
    "stepNo": 4,
    "stepId": "agreement",
    "stepName": "Agreement",
    "checkUser": "MLO",
    hidden: false
  },
  {
    "stepNo": 5,
    "stepId": "team",
    "stepName": "Team",
    "checkUser": "MLO",
    hidden: false
  },
  {
    "stepNo": 6,
    "stepId": "management",
    "stepName": "Management",
    "checkUser": "MLO",
    hidden: false
  },
  {
    "stepNo": 7,
    "stepId": "disclosures",
    "stepName": "Disclosures",
    "checkUser": "MLO",
    hidden: false
  },
  {
    "stepNo": 8,
    "stepId": "compliance",
    "stepName": "Compliance",
    "checkUser": "MLO",
    hidden: false
  },
  {
    "stepNo": 9,
    "stepId": "schedule",
    "stepName": "Schedule",
    "checkUser": "MLO",
    hidden: false
  },
  {
    "stepNo": 10,
    "stepId": "onboarding",
    "stepName": "Onboarding",
    hidden: true
  }
]

export function getNextOnboardingStepId(store, mov){
  let currentStep = _.get(store.state,  'sessionObject.userInfo.completedPreRequisites');

  let stepIndex = ON_BOARDING_STEPS.findIndex(stp => {
    return currentStep == stp.stepId;
  })
  let nextIndex = mov == 1 ? -1 : +1;
  stepIndex = stepIndex+nextIndex;
  return ON_BOARDING_STEPS[stepIndex].stepId
}

export function isPriorStepsComplete(currentStepId, nextStepId) {
  let currentStepIdx = ON_BOARDING_STEPS.findIndex(stp => {
    return stp.stepId == currentStepId; 
  })
  let nextStepIdx = ON_BOARDING_STEPS.findIndex(stp => {
    return nextStepId == stp.stepId;
  })
  return currentStepIdx < nextStepIdx;
}

export function isStepsNotComplete(currentStepId, nextStepId) {
  if(typeof currentStepId == 'undefined') return;
  let currentStepIdx = ON_BOARDING_STEPS.findIndex(stp => {
     return stp.stepId == currentStepId; 
  })
  let nextStepIdx = ON_BOARDING_STEPS.findIndex(stp => {
    return nextStepId == stp.stepId;
  })
  return currentStepIdx < nextStepIdx;
}

export function isStepCompleted(currentStepId, completedStepId) {
  let currentStepIdx = ON_BOARDING_STEPS.findIndex(stp => {
    return stp.stepId == currentStepId; 
  })
  let completedStepIdx = ON_BOARDING_STEPS.findIndex(stp => {
    return completedStepId == stp.stepId;
  })
  return currentStepIdx === completedStepIdx;
}

const OLD_BOARDING_STEPS_MAP = {
  "0": "lenderInfo",
  "1": "creditVendor",
  "2": "appraisal",
  "3": "aus",
  "4": "agreement",
  "5": "team",
  "6": "management",
  "7": "schedule",
  "-1": "welcome",
  "8": "onboarding"
}

export const getOnboardingStep = function (STATE) {
  let userInfo = _.get(STATE, "sessionObject.userInfo", null);
  if(!userInfo){
    return;
  }

  if (STATE.sessionObject.userInfo.completedPreRequisitesId) {
    return STATE.sessionObject.userInfo.completedPreRequisitesId;
  }

  if (typeof STATE.sessionObject.userInfo.completedPreRequisites === 'number') {
    return OLD_BOARDING_STEPS_MAP[STATE.sessionObject.userInfo.completedPreRequisites || -1];
  }

  return STATE.sessionObject.userInfo.completedPreRequisites; 
}

export const isOnboardingCompleted = (state) => {
  const sessionObject = state.sessionObject;
  const isBrokerSession = sessionObject.type === 'Broker';
  const isMLOSession = sessionObject.type === 'MLO';
  const isValidSession = isBrokerSession || isMLOSession;
  const userCompletedPreRequisitesId = getOnboardingStep(state);
  const isBrokerOnboardingStepCompleted = isBrokerSession && isStepCompleted(userCompletedPreRequisitesId, 'onboarding');
  const isMLOAgreementStepCompleted = isMLOSession && isStepCompleted(userCompletedPreRequisitesId, 'agreement');
  const isCompleted = isBrokerOnboardingStepCompleted || isMLOAgreementStepCompleted;
  return isValidSession && userCompletedPreRequisitesId && isCompleted;
};

export const US_STATES = [
  { name: 'Alabama', abbrev: 'AL' },
  { name: 'Alaska', abbrev: 'AK' },
  { name: 'Arizona', abbrev: 'AZ' },
  { name: 'Arkansas', abbrev: 'AR' },
  { name: 'California', abbrev: 'CA' },
  { name: 'Colorado', abbrev: 'CO' },
  { name: 'Connecticut', abbrev: 'CT' },
  { name: 'Delaware', abbrev: 'DE' },
  { name: 'Florida', abbrev: 'FL' },
  { name: 'Georgia', abbrev: 'GA' },
  { name: 'Hawaii', abbrev: 'HI' },
  { name: 'Idaho', abbrev: 'ID' },
  { name: 'Illinois', abbrev: 'IL' },
  { name: 'Indiana', abbrev: 'IN' },
  { name: 'Iowa', abbrev: 'IA' },
  { name: 'Kansas', abbrev: 'KS' },
  { name: 'Kentucky', abbrev: 'KY' },
  { name: 'Louisiana', abbrev: 'LA' },
  { name: 'Maine', abbrev: 'ME' },
  { name: 'Maryland', abbrev: 'MD' },
  { name: 'Massachusetts', abbrev: 'MA' },
  { name: 'Michigan', abbrev: 'MI' },
  { name: 'Minnesota', abbrev: 'MN' },
  { name: 'Mississippi', abbrev: 'MS' },
  { name: 'Missouri', abbrev: 'MO' },
  { name: 'Montana', abbrev: 'MT' },
  { name: 'Nebraska', abbrev: 'NE' },
  { name: 'Nevada', abbrev: 'NV' },
  { name: 'New Hampshire', abbrev: 'NH' },
  { name: 'New Jersey', abbrev: 'NJ' },
  { name: 'New Mexico', abbrev: 'NM' },
  { name: 'New York', abbrev: 'NY' },
  { name: 'North Carolina', abbrev: 'NC' },
  { name: 'North Dakota', abbrev: 'ND' },
  { name: 'Ohio', abbrev: 'OH' },
  { name: 'Oklahoma', abbrev: 'OK' },
  { name: 'Oregon', abbrev: 'OR' },
  { name: 'Pennsylvania', abbrev: 'PA' },
  { name: 'Rhode Island', abbrev: 'RI' },
  { name: 'South Carolina', abbrev: 'SC' },
  { name: 'South Dakota', abbrev: 'SD' },
  { name: 'Tennessee', abbrev: 'TN' },
  { name: 'Texas', abbrev: 'TX' },
  { name: 'Utah', abbrev: 'UT' },
  { name: 'Vermont', abbrev: 'VT' },
  { name: 'Virginia', abbrev: 'VA' },
  { name: 'Washington', abbrev: 'WA' },
  { name: 'West Virginia', abbrev: 'WV' },
  { name: 'Wisconsin', abbrev: 'WI' },
  { name: 'Wyoming', abbrev: 'WY' },
];


export const LIEN_TYPE = [
  { name: "First Lien", mismoValue: 'FirstLien'},  
  { name: "Second Lien", mismoValue: 'SecondLien'},  
  { name: "Third Lien", mismoValue: 'ThirdLien'},  
  { name: "Fourth Lien", mismoValue: 'FourthLien'},  
];

export const MISMO_OTHER_INCOME_SOURCE = [
  { name: "Accessory Unit Income", ediValue: "", mismoValue: "AccessoryUnitIncome", value: "Accessory Unit Income" },
  { name: "Alimony", ediValue: "", mismoValue: "Alimony", value: "Alimony" },
  { name: "Automobile Allowance", ediValue: "", mismoValue: "AutomobileAllowance", value: "Automobile Allowance" },
  { name: "Boarder Income", ediValue: "", mismoValue: "BoarderIncome", value: "Boarder Income" },
  { name: "Capital Gains", ediValue: "", mismoValue: "CapitalGains", value: "Capital Gains" },
  { name: "Child Support", ediValue: "", mismoValue: "ChildSupport", value: "Child Support" },
  { name: "Defined Contribution Plan", ediValue: "", mismoValue: "DefinedContributionPlan", value: "Defined Contribution Plan" },
  { name: "Disability", ediValue: "", mismoValue: "Disability", value: "Disability" },
  { name: "Dividends Interest", ediValue: "", mismoValue: "DividendsInterest", value: "Dividends Interest" },
  { name: "Employment Related Account", ediValue: "", mismoValue: "EmploymentRelatedAccount", value: "Employment Related Account" },
  { name: "Foster Care", ediValue: "", mismoValue: "FosterCare", value: "Foster Care" },
  { name: "Housing Allowance", ediValue: "", mismoValue: "HousingAllowance", value: "Housing Allowance" },
  { name: "Housing Choice Voucher Program", ediValue: "", mismoValue: "HousingChoiceVoucherProgram", value: "Housing Choice Voucher Program" },
  { name: "Mortgage Credit Certificate", ediValue: "", mismoValue: "MortgageCreditCertificate", value: "Mortgage Credit Certificate" },
  { name: "Mortgage Differential", ediValue: "", mismoValue: "MortgageDifferential", value: "Mortgage Differential" },
  { name: "Non Borrower Household Income", ediValue: "", mismoValue: "NonBorrowerHouseholdIncome", value: "Non Borrower Household Income" },
  { name: "Notes Receivable Installment", ediValue: "", mismoValue: "NotesReceivableInstallment", value: "Notes Receivable Installment" },
  { name: "Other", ediValue: "", mismoValue: "Other", value: "Other" },
  { name: "Pension", ediValue: "", mismoValue: "Pension", value: "Pension" },
  { name: "Public Assistance", ediValue: "", mismoValue: "PublicAssistance", value: "Public Assistance" },
  { name: "Royalties", ediValue: "", mismoValue: "Royalties", value: "Royalties" },
  { name: "Separate Maintenance", ediValue: "", mismoValue: "SeparateMaintenance", value: "Separate Maintenance" },
  { name: "Social Security", ediValue: "", mismoValue: "SocialSecurity", value: "Social Security" },
  { name: "Temporary Leave", ediValue: "TL", mismoValue: "TemporaryLeave", value: "Temporary Leave" },
  { name: "Tip Income", ediValue: "", mismoValue: "TipIncome", value: "Tip Income" },
  { name: "Trust", ediValue: "", mismoValue: "Trust", value: "Trust" },
  { name: "Unemployment", ediValue: "", mismoValue: "Unemployment", value: "Unemployment" },
  { name: "VA Benefits Non Educational", ediValue: "", mismoValue: "VABenefitsNonEducational", value: "VA Benefits Non Educational" }
];

export const ATTACHMENT_TYPE = [
  { name: "Attached", ediValue: "", mismoValue: "Attached", value: "Attached" },
  { name: "Detached", ediValue: "", mismoValue: "Detached", value: "Detached" },  
];

export const COUNSELING_EVENT_TYPE = [
  { name: 'Counseling', ediValue: '', mismoValue: 'Counseling', value: 'Counseling' },
  { name: 'Education', ediValue: '', mismoValue: 'Education', value: 'Education' },
];

export const COUNSELING_EVENT_FORMAT_TYPE = [
  { name: 'FaceToFace', ediValue: '', mismoValue: 'FaceToFace', value: 'FaceToFace' },
  { name: 'Internet', ediValue: '', mismoValue: 'Internet', value: 'Internet' },
  { name: 'Telephone', ediValue: '', mismoValue: 'Telephone', value: 'Telephone' },
];

export const MISMO_PROPERTY_ESTATE_TYPE = [
  { name: 'Fee Simple', ediValue: '', mismoValue: 'FeeSimple', value: 'FeeSimple' },
  { name: 'Leasehold', ediValue: '', mismoValue: 'Leasehold', value: 'Leasehold' }
];

export const MISMO_TAXPAYER_IDENTIFICATION_TYPE = [
  { name: 'SSN', ediValue: '', mismoValue: 'SSN', value: 'SSN' },
  { name: 'ITIN', ediValue: '', mismoValue: 'ITIN', value: 'ITIN' }
];

export const MISMO_ACT_TYPE = [
  { name: "203B", ediValue: "", mismoValue: "203B", value: "203B" },
  { name: "203B251", ediValue: "", mismoValue: "203B251", value: "203B251" },
  { name: "203K", ediValue: "", mismoValue: "203K", value: "203K" },
  { name: "203K251", ediValue: "", mismoValue: "203K251", value: "203K251" },
  { name: "234C", ediValue: "", mismoValue: "234C", value: "234C" },
  { name: "234C251", ediValue: "", mismoValue: "234C251", value: "234C251" },
];

export const MISMO_INCOME_SOURCE = [
  { name: "Base", value: "base", isMilitary: false, disabled: false },
  { name: "Bonuses", value: "bonus", isMilitary: false, disabled: false },
  { name: "Commission", value: "commission", isMilitary: false, disabled: false },
  { name: "ContractBasis", value: "contractbasis", isMilitary: false, disabled: false },
  { name: "Overtime", value: "overtime", isMilitary: false, disabled: false },
  { name: "Other", value: "other", isMilitary: false, disabled: false },
  { name: "Military Base Pay", value: "militaryBasePay", isMilitary: true, disabled: false },
  { name: "Military Clothes Allowance", value: "militaryClothesAllowance", isMilitary: true, disabled: false },
  { name: "Military Combat Pay", value: "militaryCombatPay", isMilitary: true, disabled: false },
  { name: "Military Flight Pay", value: "militaryFlightPay", isMilitary: true, disabled: false },
  { name: "Military Hazard Pay", value: "militaryHazardPay", isMilitary: true, disabled: false },
  { name: "Military Overseas Pay", value: "militaryOverseasPay", isMilitary: true, disabled: false },
  { name: "Military Prop Pay", value: "militaryPropPay", isMilitary: true, disabled: false },
  { name: "Military Quarters Allowance", value: "militaryQuartersAllowance", isMilitary: true, disabled: false },
  { name: "Military Rations Allowance", value: "militaryRationsAllowance", isMilitary: true, disabled: false },
  { name: "Military Variable Housing Allowance", value: "militaryVariableHousingAllowance", isMilitary: true, disabled: false }
];

export const MISMO_CONSTRUCTION_METHOD_TYPE = [
  { name: "Manufactured", ediValue: "", mismoValue: "Manufactured", value: "Manufactured" },
  { name: "Site Built", ediValue: "", mismoValue: "SiteBuilt", value: "SiteBuilt" },
  { name: "Other", ediValue: "", mismoValue: "Other", value: "Other" },
];

export const MISMO_CONSTRUCTION_METHOD_TYPE_OTHER_DESCRIPTION = [
  { name: "MH Advantage", ediValue: "", mismoValue: "MHAdvantage", value: "MHAdvantage" },
];

export const MISMO_GIFTS_FUNDS_SOURCE_TYPE_FHA = [
  { name: "Non Profit Instrumentality Of Government", ediValue: "", mismoValue: "NonProfitInstrumentalityOfGovernment", value: "NonProfitInstrumentalityOfGovernment" }
];
export const ALLOWED_FILE_EXTENSIONS = '.pdf, .jpg, .jpeg, .png, .doc, .docx, .xlsx, .ods';
export const ALLOWED_IMAGES_EXTENSIONS = '.jpg, .jpeg, .png';
export const ALLOWED_OCR_EXTENSIONS = '.pdf, .jpg, .jpeg, .png';

export const SORTING_TYPES = {
  NAME: 'byName', 
  TASK: 'byTask', 
  STATUS: 'byStatus', 
  LOCK_DATE: 'byLockDate', 
  CLOSING_DATE: 'byClosingDate'
};

export const MISMO_INVESTOR_PRODUCT_PLAN_IDENTIFIER = [
  { name: "57", ediValue: "", mismoValue: "57", value: "57" },
  { name: "251", ediValue: "", mismoValue: "251", value: "251" },
  { name: "649", ediValue: "", mismoValue: "649", value: "649" },
  { name: "650", ediValue: "", mismoValue: "650", value: "650" },
  { name: "651", ediValue: "", mismoValue: "651", value: "651" },
  { name: "652", ediValue: "", mismoValue: "652", value: "652" },
  { name: "660", ediValue: "", mismoValue: "660", value: "660" },
  { name: "661", ediValue: "", mismoValue: "661", value: "661" },
  { name: "710", ediValue: "", mismoValue: "710", value: "710" },
  { name: "720", ediValue: "", mismoValue: "720", value: "720" },
  { name: "721", ediValue: "", mismoValue: "721", value: "721" },
  { name: "750", ediValue: "", mismoValue: "750", value: "750" },
  { name: "751", ediValue: "", mismoValue: "751", value: "751" },
  { name: "861", ediValue: "", mismoValue: "861", value: "861" },
  { name: "1423", ediValue: "", mismoValue: "1423", value: "1423" },
  { name: "1437", ediValue: "", mismoValue: "1437", value: "1437" },
  { name: "3252", ediValue: "", mismoValue: "3252", value: "3252" },
  { name: "3846", ediValue: "", mismoValue: "3846", value: "3846" },
  { name: "FHAHY", ediValue: "", mismoValue: "FHAHY", value: "FHAHY" },
  { name: "GEN06", ediValue: "", mismoValue: "GEN06", value: "GEN06" },
  { name: "GEN10", ediValue: "", mismoValue: "GEN10", value: "GEN10" },
  { name: "GEN1A", ediValue: "", mismoValue: "GEN1A", value: "GEN1A" },
  { name: "GEN1B", ediValue: "", mismoValue: "GEN1B", value: "GEN1B" },
  { name: "GEN3", ediValue: "", mismoValue: "GEN3", value: "GEN3" },
  { name: "GEN5", ediValue: "", mismoValue: "GEN5", value: "GEN5" },
  { name: "GEN7", ediValue: "", mismoValue: "GEN7", value: "GEN7" },
  { name: "VAARM", ediValue: "", mismoValue: "VAARM", value: "VAARM" },
  { name: "VA1YR", ediValue: "", mismoValue: "VA1YR", value: "VA1YR" },
];

export const PROJECT_LEGAL_STRUCTURE_TYPE = [
  { name: "Condominium", ediValue: "", mismoValue: "Condominium", value: "Condominium" },
  { name: "Cooperative", ediValue: "", mismoValue: "Cooperative", value: "Cooperative" },
];

export const REFINANCE_PURPOSE_MISMO = [
  { name: "Debt Consolidation", ediValue: "", mismoValue: "DebtConsolidation", value: "DebtConsolidation" },
  { name: "Home Improvement", ediValue: "", mismoValue: "HomeImprovement", value: "HomeImprovement" },
  { name: "Other", ediValue: "", mismoValue: "Other", value: "Other" },
];

export const REFINANCE_IMPROVEMENT_MISMO = [
  { name: "Made", ediValue: "", mismoValue: "MADE", value: "Made" },
  { name: "ToBeMade", ediValue: "", mismoValue: "TO_BE_MADE", value: "ToBeMade" },
  { name: "Unknown", ediValue: "", mismoValue: "UNKNOWN", value: "Unknown" },
];

export const PROJECT_DESIGN_TYPE = [
  { name: "Highrise Project", ediValue: "", mismoValue: "HighriseProject", value: "HighriseProject" },
];

export const MISMO_COMMUNITY_LENDING_PRODUCT_TYPE = [
  { name: "HFA Preferred", ediValue: "", mismoValue: "HFAPreferred", value: "HFAPreferred" },
  { name: "HFA Preferred Risk Sharing", ediValue: "", mismoValue: "HFAPreferredRiskSharing", value: "HFAPreferredRiskSharing" },
  { name: "Home Ready", ediValue: "", mismoValue: "HomeReady", value: "HomeReady" },
];

export const MISMO_COMMUNITY_SECONDS_REPAYMENT_TYPE = [
  { name: "Deferred Fully Forgiven", ediValue: "", mismoValue: "DeferredFullyForgiven", value: "DeferredFullyForgiven" },
  { name: "Deferred Not Fully Forgiven", ediValue: "", mismoValue: "DeferredNotFullyForgiven", value: "DeferredNotFullyForgiven" },
  { name: "Non Deferred", ediValue: "", mismoValue: "NonDeferred", value: "NonDeferred" },
];

export const FILE_UPLOADING_ERROR_MESSAGES = {
  VIRUS: 'The file is infected. To send the message, delete the file and add another',
  UNSUPPORTED: 'Unsupported file type. To send a message, delete the file and add another'
}

export const VENDOR_TYPES = ['Appraisal', 'AUS Vendor', 'Credit Vendor', 'Loan Sifter', 'Other'];
