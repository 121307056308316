





























































































































































































































































































































































































































































































































































































































































import {
    Vue,
    Component,
    Watch,
    Prop
} from "vue-property-decorator";
import {
    BASE_API_URL
} from "@/config";
import Axios from "axios";
import {
    PopoverPlugin
} from "bootstrap-vue";
import {
    Device
} from "twilio-client";
import {
    mixins
} from "vue-class-component";
import CommonMixin from "../mixins/common.mixin";
import {
    HollowDotsSpinner
} from "epic-spinners";
import striptags from "striptags";
import DisplayFiles from "@/views/DisplayDocument.vue";
export const EventBus = new Vue();
import stores from "@/store/store";
import Multiselect from "vue-multiselect";
const store: any = stores;

Vue.use(PopoverPlugin);

@Component({
    components: {
        HollowDotsSpinner,
        DisplayFiles,
        Multiselect
    }
})
export default class CommunicationPopup extends mixins(CommonMixin) {
    public notifications: any = [];
    public show = false;
    public callingStatus = "Connecting...";
    public callInProgress = false;
    public inProcess = false;
    public loadingNotification = false;
    public loadingScrollNotificationData = false;
    public incomingCallDetail: any = null;
    public incomingCallInProgress = false;
    public borrowers: any = [];
    //Communication Related Variables
    public communicationNumber = null;
    public communicationLoanTxnId = null;
    public communicationUserId = null;
    public communicationType = null;
    public communicationBody = null;
    public communicationRecording = null;
    public communicationRecordingLength = null;
    public communicationId = null;
    public communicationDate = null;
    public communicationsAppliedToBorrowers = [{ userId: null, loanTxnId: null }];
    public communicationUserType = null;
    public audioPlaying = false;
    public isReplied = false;
    public communicationActiveStatus = false;
    public attachement = [];
    public type = null;
    //=============================================================
    //Review & View File Variables
    public fileIndex = 0;
    public fileArray = [];
    public fileToDisplay = null;
    public dotLoader = null;
    public fileType = null;
    public showNext = false;
    public showPrev = false;
    public props: any = {};
    public documents: any = null;
    public comments: any = null;
    public currentReviewLoanId = "";
    public currentNotificationId = "";
    public currentFileCategory = null;
    public finishedLoan = false;
    public commModuleId = null;
    //=============================================================
    public notificationsCount = 0;
    public notificationShower = {
        loanTxnId: null,
        compensationType: null,
        compensation: null,
        closingDate: null,
        rateType: null,
        waive: null,
        matchLenderToBpc: null,
        description: null
    };
    public wemloNumber = null;
    public wemloNumberSid = null;
    public showChecker: Boolean = null;
    public notificationDataLimit = 0;
    public notificatationLoadTime = null;

    @Prop()
    public clickedOutside;

    @Prop()
    public borrowerNotificationCount: string;

    public timeInLocalTimeString(date) {
        let event = new Date(date);
        return event.toLocaleTimeString("en-US");
    }

    public async showMoreDataOnScroll() {
      if (this.notificationLength > this.notificationDataLimit) {
        await this.getProcessorsNotifications(true);
      }
    }

    public async sendTextMessage() {
        try {
            if (!this.wemloNumber) {
                this.$snotify.error(
                    "You have not been assigned wemlo number yet.Please contact admin."
                );
                return;
            }
            if (this.message == null) {
                this.$snotify.error("Message body cannot be empty.");
                return;
            }
            this.inProcess = true;
            let toUsers = [{
                number: this.communicationNumber,
                userId: this.communicationUserId,
                type: this.communicationUserType
            }];
            let response = await Axios.post(
                BASE_API_URL + "communication/sendTextMessage", {
                    message: this.message,
                    fromUserId: this.$userId,
                    fromType: "Wemlo Processor",
                    loanTxnId: this.communicationLoanTxnId,
                    wemloNumber: this.wemloNumber,
                    toUsers: toUsers
                });
            this.message = null;
            this.isReplied = true;
            this.$snotify.success("Message successfully sent.");
            this.inProcess = false;
        } catch (error) {
            this.inProcess = false;
        }
    }

    public async saveAndGetCallDetails(tempCallId) {
        try {
            let response = await Axios.post(
                BASE_API_URL + "communication/saveAndGetCallDetails", {
                    tempCallId: tempCallId,
                    fromUserId: this.$userId,
                    fromType: "Wemlo Processor",
                    toUserType: this.communicationUserType,
                    toUserId: this.communicationUserId,
                    loanTxnId: this.communicationLoanTxnId
                });

            this.isReplied = true;
        } catch (error) {
            console.log(error.response);
        }
    }

    public openApplyCommunicationToUserModal() {
        this.$modal.hide("borrowerCommunicationModal");
        this.$modal.show("applyCommunicationToUserModal");
        this.getBorrower();
    }

    public async addBorrowerSelect() {
      this.searchByBorrowerName('');
      if (!this.isAnyBorrowerNotAssigned) {
        this.communicationsAppliedToBorrowers.push({ userId: null, loanTxnId: null });
      }
    }

    get isBorrowerAssignedList() {
      return this.communicationsAppliedToBorrowers.map(el => el.userId === null || el.loanTxnId === null);
    }

    get isAnyBorrowerNotAssigned() {
      return this.isBorrowerAssignedList.some(isBorrowerAssigned => isBorrowerAssigned);
    }

    get uncheckedBorrowers() {
      return this.borrowers.filter(el => {
        return !this.communicationsAppliedToBorrowers.find(
          item => item.userId === el.userId
        )
      });
    }

    public async applyCommunicationToUser() {
      if (this.isAnyBorrowerNotAssigned) {
        return;
      }

      let communications = this.callLogs.filter(
        communication => communication.fromNumber == this.communicationNumber
      );

      if (communications.length > 1) {
        let confirmAssignAll: boolean = false;

        await this.$dialog
          .confirm(
            "You have other communications also with this number. Do you want to apply all those to selected borrower."
          )
          .then(() => {
            confirmAssignAll = true;
          })
          .catch(() => {
            confirmAssignAll = false;
          });

        if (!confirmAssignAll) {
          communications = communications.filter(
            communication => communication._id === this.communicationId
          );
        }
      }

      let communicationIds = communications.map(communication => communication.moduleId);

      const communicationData = this.communicationsAppliedToBorrowers.map((item) => {
        return { loanTxnId: item.loanTxnId };
      });

      await Axios.post(`${BASE_API_URL}communication/applyCommunicationToBorrowers`, {
        communicationIds,
        communicationData,
      });

      this.callLogs = this.callLogs.filter(
        call => !communicationIds.includes(call.moduleId)
      );
      this.notificationLength = this.callLogs.length;
      
      this.closeApplyCommunicationToUserModal();
      this.$snotify.success("Call assigned to borrower successfully");
    }

    public closeApplyCommunicationToUserModal() {
        this.$modal.hide("applyCommunicationToUserModal");
        this.closeBorrowerCommunicationModal();
    }

    get twilioClientName() {
        return this.$store.state.sessionObject.userInfo.twilioClientName;
    }

    public async getWemloNumber() {
        let response = await Axios.post(
            BASE_API_URL + "communication/getWemloNumber", {
                userId: this.$store.state.sessionObject.userInfo.userId
            });
        this.wemloNumber = response.data.wemloNumber;
        this.wemloNumberSid = response.data.wemloNumberSid;
    }

    // get wemloNumberSid() {
    //   return this.$store.state.sessionObject.userInfo.wemloNumberSid;
    // }

    get twilioToken() {
        return this.$store.state.twilioToken;
    }

    @Watch("twilioToken")
    onTwilioTokenChange(twilioToken) {
        if (twilioToken) {
            this.setupTwilioDevice();
        }
    }

    get twilioActiveStatus() {
        return this.$store.state.twilioActiveStatus;
    }

    @Watch("twilioActiveStatus")
    onTwilioActiveStatusChange(twilioActiveStatus) {
        this.callingStatus = twilioActiveStatus;
    }

    public viewCommunicationDetail(commId) {
        this.callLogs.forEach(async e => {
            if (e._id == commId) {
                this.communicationDate = e.createdOn;
                this.communicationLoanTxnId = e.loanTxnId;
                this.communicationId = e._id;
                this.commModuleId = e.moduleId;
                if (e.moduleInfo == "Pending documents") {
                    this.type = "document";
                    this.currentNotificationId = e._id;
                    this.communicationUserId = e.fromUserId;
                    this.communicationUserType = e.fromType;
                    this.communicationType = e.moduleInfo;
                    let loader = this.$loading.show();
                    //this.fileArray = e.attachments;    //As in current structure this.fileArray will not come.
                    await this.fetchDocuments(e.moduleId, e.loanTxnId);
                    loader.hide();
                } else if (e.moduleInfo == "Loan estimate") {
                    let msg = e.description;

                    msg = striptags(msg);
                    this.$refs.dfs["setInitialValuesForShowMessage"](msg, false);
                } else if (e.moduleInfo == "3.2") {
                    this.notificationShower = e;
                    this.showChecker = true;
                    this.$modal.show("3.2");
                } else {
                    let loader = this.$loading.show();
                    let response = await Axios.get(
                        BASE_API_URL +
                        "communication/getCommunicationNotificationDetails/" +
                        e.moduleId);
                    this.communicationType = response.data.type;
                    this.communicationUserId = response.data.fromUserId;
                    this.communicationUserType = response.data.fromType;
                    if (
                        response.data.type == "call" ||
                        response.data.type == "voiceMail"
                    ) {
                        this.communicationNumber = response.data.fromNumber;
                        if (response.data.recordingUrl) {
                            this.communicationRecording = response.data.recordingUrl;
                            this.communicationRecordingLength = response.data.duration;
                        }
                        if (response.data.type == "voiceMail") {
                            if (
                                response.data.callTranscript &&
                                response.data.callTranscript != ""
                            ) {
                                this.communicationBody = response.data.callTranscript;
                            }
                        }
                    }

                    if (response.data.type == "sms") {
                        this.communicationBody = response.data.body;
                        this.communicationNumber = response.data.fromNumber;
                    }

                    if (response.data.type == "wemloMessage") {
                        this.type = response.data.type;
                        this.communicationBody = response.data.body;
                        this.attachement = response.data.attachments;
                    }

                    if (response.data.activeStatus == "Unknown") {
                        this.communicationActiveStatus = true;
                    }

                    loader.hide();
                    this.$modal.show("borrowerCommunicationModal");
                }
            }
        });
        this.show = false;
    }

    public toggleShow(check) {
        this.show = check;
        this.showChecker = null;
    }

    public async closeBorrowerCommunicationModal() {
        let releaseNotification = false;
        if (this.isReplied) {
            await this.$dialog
                .confirm("Do you want to clear this notification?")
                .then(e => {
                    releaseNotification = true;
                })
                .catch(f => {
                    releaseNotification = false;
                });
        }

        if (releaseNotification) {
            this.changeNotificationisReadStatus(this.communicationId);
        }
        this.showMessageError = false;
        this.$modal.hide("borrowerCommunicationModal");
        this.communicationNumber = null;
        this.communicationType = null;
        this.communicationBody = null;
        this.communicationRecording = null;
        this.communicationRecordingLength = null;
        this.audioStatus = null;
        this.communicationLoanTxnId = null;
        this.communicationUserId = null;
        this.communicationUserType = null;
        this.communicationDate = null;
        this.communicationId = null;
        this.commModuleId = null;
        this.audioPlaying = false;
        this.communicationsAppliedToBorrowers = [{ userId: null, loanTxnId: null }];
        this.show = true;
        this.isReplied = false;
        this.communicationActiveStatus = false;
    }

    public getTrimmedMessageBody(message) {
        let msg = striptags(message, [], " "); //To strip html into normal text.
        msg = msg.replace(/\s\s+/g, " "); //To remove multiple white spaces as above function create multiple white spaces in case of <ol> and <ul> tags.
        if (msg.length > 25) {
            return msg.slice(0, 24) + "...";
        } else {
            return msg;
        }
    }

    public async changeNotificationisReadStatus(notificationId) {
        try {
            this.callLogs = this.callLogs.filter(e => e._id != notificationId);
            this.callLogs = JSON.parse(JSON.stringify(this.callLogs));
            this.notificationLength = this.callLogs.length;
            let unReadedCommunications = [notificationId];
            let response = await Axios.post(
                BASE_API_URL + "communication/changeNotificationisReadStatus", {
                    unReadedCommunications: unReadedCommunications
                });
            this.closeBorrowerCommunicationModal();
        } catch (error) {
            console.log(error.data);
        }
    }

    public async fetchDocuments(docId, loanTxnId) {
        try {
            this.currentReviewLoanId = loanTxnId;
            let data = {
                loanTxnId: loanTxnId
            };
            let response = await Axios.post(
                BASE_API_URL + "wemloProcessor/fetchDocuments",
                data);
            this.documents = response.data.docs;
            this.comments = response.data.comments;
            this.finishedLoan = response.data.status;

            let reviewDoc: any = {};
            let check = false;
            response.data.docs.forEach(doc => {
                if (doc._id == docId) {
                    reviewDoc = doc;
                }
            });
            if (
                reviewDoc.fileHistory.length > 0 &&
                reviewDoc.fileHistory[reviewDoc.fileHistory.length - 1].status ==
                "Uploaded" &&
                !this.finishedLoan
            ) {
                check = true;
            }
            this.$refs.dfs["setInitialValues"](
                this.documents,
                this.comments,
                reviewDoc,
                check,
                loanTxnId,
                this.show
            );
        } catch (error) {
            console.log(error);
        }
    }

    public audioStatus = null;
    public playAudio(event) {
        this.audioPlaying = true;
        this.showAudioPlayingStatus();
    }

    /**
     * showAudioLength
     */
    public showAudioPlayingStatus() {
        let audio: any = document.getElementById("audioPlayer");
        let previousAudioStatus = this.audioStatus;
        if (audio) {
            if (!this.audioStatus) this.audioStatus = "Loading...Please wait.";
            if (audio.readyState == 0) {
                this.audioStatus = "Loading...Please wait.";
            } else {
                let buffered = audio.buffered;
                let audioLength = null;
                if (
                    this.communicationRecordingLength &&
                    this.communicationRecordingLength != "0"
                ) {
                    audioLength = this.communicationRecordingLength;
                }
                if (buffered.length) {
                    if (audio.duration != "Infinity") {
                        audioLength = audio.duration;
                    }
                    if (audioLength && this.communicationRecordingLength != 0) {
                        let loaded = Math.round((100 * buffered.end(0)) / audioLength);
                        let played = Math.round((100 * audio.currentTime) / audioLength);
                        if (loaded > 100) loaded = 100;
                        if (played > 100) played = 100;
                        this.audioStatus =
                            loaded + " % loaded  &  " + played + " % played.";
                    } else {
                        let loaded: any = Math.round(buffered.end(0));
                        let played: any = Math.round(audio.currentTime);
                        if (loaded > 60) {
                            let min = Math.round(loaded / 60);
                            let sec = Math.round(loaded % 60);
                            loaded = min + " minutes" + sec + " seconds";
                        } else {
                            loaded = loaded + " seconds loaded";
                        }

                        if (played > 60) {
                            let min = Math.round(played / 60);
                            let sec = Math.round(played % 60);
                            played = min + " minutes" + sec + " seconds";
                        } else {
                            played = played + " seconds played.";
                        }

                        this.audioStatus = loaded + " & " + played;
                    }
                }
            }
            if (
                this.audioStatus == "Loading...Please wait." ||
                (this.audioStatus != "Loading...Please wait." &&
                    this.audioStatus != previousAudioStatus)
            ) {
                setTimeout(() => {
                    this.showAudioPlayingStatus();
                }, 1000);
            }
        }
    }

    public stopPlayingAudio(event) {
        this.audioPlaying = false;
        this.audioStatus = null;
    }

    public getCommunicationToUserName(users) {
        let userArray = [];
        users.forEach(element => {
            userArray.push(element.toName);
        });
        return userArray.toString();
    }

    public dateInProperFormat(date) {
        let event = new Date(date);
        let day = event.getDate() < 10 ? "0" + event.getDate() : event.getDate();
        let month =
            event.getMonth() < 10 ? "0" + event.getMonth() : event.getMonth();
        let year = event.getFullYear();
        return day + "-" + month + "-" + year;
    }

    //Now this function is being called on click of bell icon not on page mount.
    public async getProcessorsNotifications(isLoadMore) {
        if (this.loadingScrollNotificationData || this.loadingNotification) return
        try {
            if (isLoadMore) {
                this.loadingScrollNotificationData = true;
            } else {
                this.notificationDataLimit = 0;
                this.loadingNotification = true;
                this.notificatationLoadTime = null;
                await this.getProcessorsNotificationsCount();
            }

            let response = await Axios.post(
                BASE_API_URL + "communication/getProcessorsNotifications", {
                    userId: this.$userId,
                    notificationDataLimit: this.notificationDataLimit,
                    notificatationLoadTime: this.notificatationLoadTime
                });
            this.notificatationLoadTime = response.data.notificatationLoadTime;
            if (isLoadMore) {
                this.callLogs = this.callLogs.concat(response.data.data);
                this.notificationDataLimit += response.data.data.length;
            } else {
                this.callLogs = response.data.data;
                this.notificationDataLimit = response.data.data.length;
            }
        } catch (error) {
            console.log(error);
        } finally {
          this.loadingNotification = false;
          this.loadingScrollNotificationData = false;
        }
    }

    /**************************************************************************************************
     *                           FETCH COUNT NOTIFICATION FOR HEADER                                   *
     **************************************************************************************************/
    public async getProcessorsNotificationsCount() {
        try {
            let response = await Axios.get(
                BASE_API_URL + "communication/getProcessorsNotificationCount");
            this.notificationLength = response.data.notificationCount;
        } catch (error) {
            console.log(error);
        }
    }

    get userType() {
        return this.$store.state.sessionObject.type;
    }

    public async toggleOverLay() {
        this.$emit("toggleOverLayPopOver");
        this.show = !this.show;
        if (this.show && this.$userType == "Wemlo Processor") {
            this.getProcessorsNotifications(false);
        }
    }

    public customLabel({ name, loanTxnId }) {
        if (!name || !loanTxnId) {
          return '';
        }
        return `${name} - ${loanTxnId}`;
    }

    public onApplyBorrowerSelectInput(value, i) {
      this.communicationsAppliedToBorrowers.splice(i, 1, value || { userId: null, loanTxnId: null });
    }

    public searchByBorrowerName(event) {
        this.getBorrower(event);
    }

    public async getBorrower(searchByName: any = "") {
        try {
            let response = await Axios.post(
                BASE_API_URL + "common/getAllBorrowers", {
                    searchByName: searchByName
                });
            this.borrowers = response.data;
        } catch (error) {
            console.log(error.response);
        }
    }

    public loadMoreNotifications() {
        this.$router.push("/super-admin/notification");
    }

    /**
     * It will request only if there is no twilio token, this will prevent the request of tokens when the user just change the page
     */
    public async initializeTwilioToken() {
        if (this.twilioToken) {
            this.setupTwilioDevice();
        } else if (this.wemloNumber) {
            await this.generateTwilioToken();
        }
    }

    public setupTwilioDevice() {
        Device.setup(this.twilioToken);
    }

    public async generateTwilioToken() {
        try {
            let response = await Axios.post(
                BASE_API_URL + "communication/generateTwilioToken", {
                    wemloNumber: this.wemloNumber,
                    wemloNumberSid: this.wemloNumberSid,
                    twilioClientName: this.twilioClientName
                });
            this.$store.dispatch("setTwilioToken", response.data.token);
        } catch (error) {
            console.log(error);
        }
    }

    public async callToCustomer() {
        try {
            if (!this.wemloNumber) {
                this.$snotify.error(
                    "You have not been assigned wemlo number yet.Please contact admin."
                );
                return;
            }
            this.callingStatus = "Dialing...";
            let tempCallId = Math.floor(Math.random() * 100000000000000000);
            var params = {
                wemloNumber: this.wemloNumber,
                tempCallId: tempCallId,
                phoneNumber: this.communicationNumber,
                fromUserId: this.$userId,
                fromType: "Wemlo Processor",
                toUserType: this.communicationUserType,
                toUserId: this.communicationUserId,
                loanTxnId: this.communicationLoanTxnId,
                responseFor: this.commModuleId
            };
            Device.connect(params);
            Device.once("connect", (connection) => {
                this.callingStatus = "In call with " + connection.message.phoneNumber;
                this.callInProgress = true;
            });
            Device.once("disconnect", async () => {
                this.callingStatus = "Disconnected";
                this.callInProgress = false;
                setTimeout(() => {
                    this.callingStatus = "Ready";
                }, 200);
                await this.saveAndGetCallDetails(tempCallId);
            });
        } catch (error) {
            console.log(error);
        }
    }

    public async disconnectCall() {
        try {
            Device.disconnectAll();
        } catch (error) {
            console.log(error);
        }
    }

    public showMessageError = false;

    public async addMessage() {
        if (this.message) {
            this.sendMessage({
                toUserType: this.communicationUserType,
                toUserId: this.communicationUserId,
                loanTxnId: this.communicationLoanTxnId,
                isCalledFromPopup: true
            });
            this.isReplied = true;
        } else {
            this.showMessageError = true;
        }
    }

    public incommingCallDisconnected() {
        try {
            this.incomingCallDetail = null;
            this.incomingCallInProgress = false;
            this.callingStatus = "Ready";
            this.$modal.hide("incomingCallModal");
        } catch (error) {
            console.log(error);
        }
    }

    public async disconnectIncomingCall() {
        try {
            Device.disconnectAll();
            this.incommingCallDisconnected();
        } catch (error) {
            console.log(error);
        }
    }

    public async acceptIncomingCall(conn) {
        this.incomingCallDetail.accept();
        this.incomingCallInProgress = true;
        this.callingStatus = "In call with " + this.incomingCallDetail.parameters.From;
    }

    public async rejectIncomingCall(conn) {
        this.incomingCallDetail.reject();
        this.incomingCallDetail = null;
        this.incomingCallInProgress = false;
        this.callingStatus = "Ready";
        this.$modal.hide("incomingCallModal");
    }

    public async viewAttachments(data) {
        try {
            this.$refs.dfs["setInitialValues"](
                [], [], {
                    fileHistory: [{
                        files: data,
                        status: "Approved"
                    }]
                },
                false,
                this.communicationLoanTxnId
            );
        } catch (error) {
            console.log(error);
        }
    }
    public hideDisplayFile() {
        this.show = false;
        this.$modal.hide("displayNotificationFile");
    }

    public get wemloToken(): string {
        return this.$store.state.sessionObject.token;
    }

    public async moveCommunicationToPipeline() {
        try {
            let comm = this.callLogs.filter(
                element => element._id == this.communicationId
            );
            let response = await Axios.post(
                BASE_API_URL + "communication/moveCommunicationToPipeline", {
                    communicationId: comm[0].moduleId
                });
            this.callLogs = this.callLogs.filter(
                element => element._id != this.communicationId
            );
            this.notificationLength = this.callLogs.length;
            this.closeBorrowerCommunicationModal();
            this.$snotify.success("Communication moved to pipeline successfully.");
        } catch (error) {
            console.log(error);
        }
    }

    public async setCallerByPhoneNumber(phone) {
      try {
        let response = await Axios.get(BASE_API_URL + "communication/getCallerByPhoneNumber", {
          params: {
            phone,
          },
        });

        if (this.incomingCallDetail) {
          this.incomingCallDetail.parameters.From = response.data.caller;
        }
      } catch (error) {
        console.log(error);
      }
    }

    /**************************************************************************************************
     *                                    IF DOCUMENT NOT LOADED THEN                                  *
     **************************************************************************************************/
    public ifDocumentNotLoad(time: number) {
        this.dotLoader = true;
        try {
            setTimeout(() => {
                let checkLoader = document.getElementById("checkLoader");
                if (checkLoader != null) {
                    let iframe: any = document.getElementById("checkElement");
                    if (iframe.localName == "iframe") {
                        iframe.src =
                            "https://docs.google.com/gview?url=" +
                            this.fileToDisplay +
                            "&embedded=true";
                    } else {
                        iframe.src = this.fileToDisplay;
                    }
                    this.ifDocumentNotLoad(time + 1000);
                }
            }, time);
        } catch (error) {
            console.log(error);
            this.dotLoader = false;
        }
    }

    @Watch("clickedOutside")
    watch(newVal) {
        if (newVal) {
            this.show = false;
        }
    }

    @Watch("borrowerNotificationCount")
    watchNotification(newVal) {
        this.notificationsCount = newVal;
    }

    @Watch("wemloToken")
    onWemloTokenChange(newVal) {
        if (newVal && this.userType == "Wemlo Processor") {
            this.generateTwilioToken();
        }
    }

    async mounted() {
        EventBus.$on("someChanges", () => {
            this.show = true;
        });

        if (this.userType == "Wemlo Processor") {
            await this.getWemloNumber();
            await this.initializeTwilioToken();
            await this.getProcessorsNotificationsCount();

            Device.on("incoming", (connection) => {
                this.callingStatus = "Incoming call";
                this.incomingCallDetail = connection;
                this.$modal.show("incomingCallModal");

                /**
                 * Load who is calling from number
                 */
                this.setCallerByPhoneNumber(connection.parameters.From);
            });

            Device.on("cancel", () => {
                this.incommingCallDisconnected();
            });

            Device.on("disconnect", () => {
                this.incommingCallDisconnected();
            });

            Device.on("ready", () => {
                this.$store.dispatch("setTwilioActiveStatus", "Ready");
                this.callingStatus = "Ready";
            });

            Device.on("error", (error) => {
                this.$store.dispatch("setTwilioActiveStatus", "Cannot connect now.");

                this.callingStatus = "Cannot connect now.";
                this.callInProgress = false;
                this.incomingCallInProgress = false;

                /**
                 * Error occurred while accessing microphone
                 */
                if (error.code === 31201) {
                    this.$snotify.error("Error occurred while accessing microphone.");
                }

                /**
                 * Token expired error
                 */
                if (error.code === 31205) {
                    /**
                     * Generate a new twilio token
                     */
                    this.generateTwilioToken();
                }

                console.error(error);
            });
        }

        window.addEventListener("beforeunload", function(e) {
            let data = JSON.parse(localStorage["state.WeMLO"]);
            if (data.removeLocalFiles.length > 0) {
                store.dispatch("removeLocalFiles", data.removeLocalFiles);
            }
        });
    }

    /**************************************************************************************************
     *                                    IF FILES NOT LOADED IN DATABASE                              *
     **************************************************************************************************/
    async beforeDestroy() {
        Device.destroy();
        let data = JSON.parse(localStorage["state.WeMLO"]);
        if (data.removeLocalFiles.length > 0) {
            this.$store.dispatch("removeLocalFiles", data.removeLocalFiles);
        }
    }
}
