









































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import stores from "@/store/store";
const store: any = stores;
import Axios from "axios";
import { HollowDotsSpinner } from "epic-spinners";


@Component({ components: { HollowDotsSpinner } })
export default class EmailToBrokerComponent extends Vue {
  @Prop()
  public invitationLink;

  public user: any = {};
  public emailExists = null;
  public inProcessing = false;

  // computed(){
  //   this.invitationLink==true?this.$modal.show('addNewBroker'):this.$modal.hide('addNewBroker');
  // }
  @Watch("invitationLink")
  showHideModal() {
    this.invitationLink == true
      ? this.$modal.show("addNewBroker")
      : this.$modal.hide("addNewBroker");
  }

  public async addNewBroker() {
    try {
      this.inProcessing = true;
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        this.inProcessing = false;
        return;
      }
      this.user["fromUserType"] = this.$userType;
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/saveBrokerFromSuperAdmin",
        this.user);
      if (response.data.message) {
        this.emailExists = response.data.message;
        this.inProcessing = false;
      } else {
        this.$snotify.success("Information saved successfully");
        this.user = {};
        this.$modal.hide("addNewBroker");
        this.$emit('closeModal',false)
        this.inProcessing = false;
      }
    } catch (error) {
      this.inProcessing = false;
      console.log(error);
    }
  }
  public closeModal() {
    this.user = {};
    this.$emit("closeModal", false);
    this.$modal.hide("addNewBroker");
  }
  mounted() {
    // this.$modal.show("addNewBroker");
  }
}
