<template>
  <div>
    <div class="identity-mode-banner text-center"  v-show="isVisible">
      Identity assumed for {{ this.$store.state.userInfoForIdentityAssumedMode.userType }}
      {{ this.$store.state.userInfoForIdentityAssumedMode.firstName }}
      {{ this.$store.state.userInfoForIdentityAssumedMode.lastName }}.
      All actions are recorded. Please click
      <i title="end the user session"
         class="icon-wemlo1 p-2 fg-color__blue cursor-pointer"
         aria-hidden="true"
         @click="logOutIdentityAssumedMode"/>
      to end the user session.
      <i title="hide banner"
         class="icon-wemlo127 fg-color__blue cursor-pointer ml-1"
         aria-hidden="true"
         @click="close(false)"/>
    </div>
    <div class="burger-close" v-show="!isVisible" @click="close(true)">
      <em class="icon-wemlo128 fg-color__blue"/>
    </div>
  </div>
  
</template>

<script>
import {setInstanceVariables} from "@/config";

export default {
  data: function () {
    return {
      isVisible: true,
    }
  },
  methods: {
    async logOutIdentityAssumedMode() {
      try {
        await this.$store.dispatch("setIdentityAssumedMode", false);
        await this.$store.dispatch("setUserInfoForIdentityAssumedMode", {});
        await this.$store.dispatch("setSession", this.$store.state.copySuperAdminSessionObject);
        await setInstanceVariables();
        await this.$store.dispatch("setSidenavLinks", this.$store.state.copySuperAdminSessionObject);
        await this.$router.push({path: this.$getCurrentUserTypePath("manage-all-user")});
      } catch (error) {
        console.log(error)
      }
    },
    close(type) {
      this.isVisible = type;
    }
  }
}
</script>

<style lang="scss">
.identity-mode-banner {
  position: absolute;
  background-color: #dc3545;
  padding: 4px 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-box-shadow: inset 0 0 4px 2px #dc3545;
  box-shadow: inset 0 0 4px 2px #dc3545;
  top: 0;
  right: 20%;
  left: 20%;
  z-index: 9999;
  color: #fff;
}
.burger-close {
  position: absolute;
  padding-bottom: 2px;
  cursor: pointer;
  top: -4px;
  left: 48%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dc3545;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 9999;
}
</style>
