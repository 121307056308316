import posRoutes from './pos.routes';
import stores from '@/store/store';
const store: any = stores;
import { getCurrentUserTypePath } from "./broker.routes";
import commonRoutes from './common.routes';
import Component from 'vue-class-component';

export const beforeEachEnter = async (to, from, next) => {
    if (store.state.sessionObject.type == "Wemlo Processor") {
        next()
    } else {
        let path = await getCurrentUserTypePath();
        next(path)
    }
};

export default [
    {
        path: '/processor',
        name: 'Processor',
        component: () => import(/* webpackChunkName: "wemlo-processor" */'@/components/broker/Broker.vue'),
        redirect: { name: 'ProcessorDashBoard' },
        meta: {
            requiresAuth: true,
            title: "Dashboard"
        },
        beforeEnter: beforeEachEnter,
        children: [
            {
                path: "dashboard",
                name: "ProcessorDashBoard",
                component: () => import(/* webpackChunkName: "wemlo-processor-dashboard" */'@/components/wemlo-processor/dashboard/Dashboard.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Dashboard"
                },
            },
            {
                path: 'broker-list',
                name: 'WemloProcessorBrokerList',
                component: () => import(/* webpackChunkName: "superadmin-broker-list" */'@/components/wemlo-processor/broker-list/BrokerList.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Broker List"
                }
            },
            {
                path: 'broker-detail',
                name: 'WemloProcessorbrokerDetail',
                component: () => import(/* webpackChunkName: "superadmin-broker-details" */'@/components/wemlo-processor/broker-details/brokerDetails.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Broker Detail"
                }
            },
            {
                path: 'borrower-detail',
                name: 'BorrowerDetail',
                component: () => import(/* webpackChunkName: "wemloprocessor-borrower-detail" */'@/components/wemlo-processor/borrower-detail/BorrowerDetail.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Borrower Detail"
                }
            },
            {
                path: 'borrower-list',
                name: 'BorrowerList',
                component: () => import(/* webpackChunkName: "wemloprocessor-borrower-list" */'@/views/borrower-list/BorrowerList.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Borrower List"
                }
            },
            {
                path: "hoi",
                name: "Hoi",
                component: () => import(/* webpackChunkName: "wemloprocessor-loanprocess" */'@/components/wemlo-processor/tasks/additional-tasks/hoi/Hoi.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Hoi"
                }
            },
            {
                path: 'my-account',
                name: 'MyAccount',
                component: () => import(/* webpackChunkName: "wemloprocessor" */'@/components/wemlo-processor/my-account/MyAccount.vue'),
                meta: {
                    requiresAuth: true,
                    title: "My Account"
                }
            },
            {
                path: 'leave-request',
                name: 'LeaveRequest',
                component: () => import(/* webpackChunkName: "wemloprocessor" */'@/components/wemlo-processor/leave-request/LeaveRequest.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Leave Request"
                }
            },
            {
                path: 'loan-estimate',
                name: 'LoanEstimate',
                component: () => import(/* webpackChunkName: "review-loan-estimate" */'@/views/reviewLoanEstimate/reviewLoanEstimate.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Fee Worksheet"
                }
            },
            {
                path: 'juniors-leave-requests',
                name: 'JuniorsLeaveRequests',
                component: () => import(/* webpackChunkName: "wemloprocessor" */'@/components/wemlo-processor/junior-leave-requests/JuniorLeaveRequests.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Leave Request"
                }
            },
            {
                path: "uw-tasks",
                name: "UWTasks",
                component: () => import(/* webpackChunkName: "wemloprocessor-loanprocess" */'@/components/wemlo-processor/loan-process/uw-task/UWTask.vue'),
                meta: {
                    requiresAuth: true,
                    title: "UW Task"
                }
            },
            {
                path: "uw-task",
                name: "UWTask",
                component: () => import(/* webpackChunkName: "wemloprocessor-loanprocess" */'@/components/wemlo-processor/tasks/uw-task/UwTask.vue'),
                meta: {
                    requiresAuth: true,
                    title: "UW Task"
                }
            },
            {
                path: "conditional-approval",
                name: "ConditionalApproval",
                component: () => import(/* webpackChunkName: "wemloprocessor-loanprocess" */'@/components/wemlo-processor/tasks/conditional-approval/ConditionalApproval.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Conditional Approval"
                }
            }, {
                path: "clear-to-close",
                name: "ClearToClose",
                component: () => import(/* webpackChunkName: "wemloprocessor-loanprocess" */'@/components/wemlo-processor/tasks/clear-to-close/ClearToClose.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Clear To Close"
                } 
            },
            {
                path: "suspended",
                name: "Suspended",
                component: () => import(/* webpackChunkName: "wemloprocessor-loanprocess" */'@/components/wemlo-processor/tasks/suspended/Suspended.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Suspended"
                }
            },
            {
                path: "closing",
                name: "Closing",
                component: () => import(/* webpackChunkName: "wemloprocessor-closing" */'@/components/wemlo-processor/tasks/closing/Closing.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Closing"
                }
            },
            {
                path: "initial-disclosure",
                name: "Initial Disclosure",
                component: () => import(/* webpackChunkName: "wemloprocessor-loanprocess" */'@/components/wemlo-processor/tasks/initial-disclosure/InitialDisclosure.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Initial Disclosure"
                }
            },
            {
                path: "voe",
                name: "Voe",
                component: () => import(/* webpackChunkName: "wemloprocessor-voe" */'@/components/wemlo-processor/tasks/additional-tasks/voe/Voe.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Voe"
                }
            },
            {
                path: "task-funding",
                name: "Task Funding",
                component: () => import(/* webpackChunkName: "wemloprocessor-loanprocess" */'@/components/wemlo-processor/tasks/pending-funding/PendingFunding.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Task Funding"
                }
            },
            {
                path: "task-funding-v1",
                name: "Task Funding V1",
                component: () => import(/* webpackChunkName: "wemloprocessor-loanprocess" */'@/components/wemlo-processor/tasks/pending-funding/PendingFundingV1.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Task Funding"
                }
            },
            {
                path: "loan-setup",
                name: "LoanSetup",
                component: () => import(/* webpackChunkName: "wemloprocessor-loanprocess-loan-setup" */'@/components/wemlo-processor/tasks/loan-setup/LoanSetup.vue'),
                meta: {
                    requiresAuth: true,
                    title: "LoanSetup"
                }
            },
            {
                path: "order-title",
                name: 'OrderTitle',
                component: () => import(/*webpackChunkName: "wemloprocessor-loanprocess-order-title" */'@/components/wemlo-processor/tasks/additional-tasks/order-title/OrderTitle.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Order-Title"
                }
            },
            {
                path: "title-received",
                name: 'TitleReceived',
                component: () => import(/* webpackChunkName: "wemloprocessor-loanprocess-order-title" */'@/components/wemlo-processor/tasks/additional-tasks/title-received/TitleReceived.vue'),
                meta: {
                    requiresAuth: true,
                    title: "TitleReceived"
                }
            },
            {
                path: "appraisal",
                name: 'Appraisal',
                component: () => import(/*webpackChunkName: "wemloprocessor-loanprocess-order-title" */'@/components/wemlo-processor/tasks/additional-tasks/appraisal/Appraisal.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Appraisal"
                }
            },
            {
                path: "appraisal-received",
                name: "AppraisalReceived",
                component: () => import('@/components/wemlo-processor/tasks/additional-tasks/appraisal-received/AppraisalReceived.vue'),
                meta: {
                    requiresAuth: true,
                    title: "AppraisalReceived"
                }

            },
            {
                path: "custom-task",
                name: "CustomTask",
                component: () => import(/* webpackChunkName: "wemloprocessor-loanprocess-loan-setup" */'@/components/wemlo-processor/tasks/additional-tasks/custom-task/CustomTask.vue'),
                meta: {
                    requiresAuth: true,
                    title: "ReportTo"
                }
            },
            {
                path: "voe-response",
                name: "VoeResponse",
                component: () => import(/* webpackChunkName: "wemloprocessor-loanprocess-loan-setup" */'@/components/wemlo-processor/tasks/additional-tasks/voeResponse/VoeResponse.vue'),
                meta: {
                    requiresAuth: true,
                    title: "VoeResponse"
                }
            },
            {
                path: 'borrower-contact',
                name: 'WemloProcessorBorrowerContact',
                component: () => import(/* webpackChunkName: "borrower-contact" */'@/views/borrower-contact/BorrowerContactForm.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Borrower Contact"
                }
            },
            {
                path: 'aus-task',
                name: "AUSTask",
                component: () => import(/* webpackChunkName: "wemloprocessor-borrower-contact" */'@/components/wemlo-processor/tasks/run-aus/Run-Aus.vue'),
                meta: {
                    requiresAuth: true,
                    title: "AUS Task"
                }
            },
            {
                path: 'denial',
                name: 'Denial',
                component: () => import(/* webpackChunkName: "wemloprocessor-borrower-contact" */'@/components/wemlo-processor/tasks/denial/Denial.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Denial"
                }
            },
            {
                path: "compliance",
                name: "Compliance",
                component: () => import(/* webpackChunkName: "compliance" */'@/components/wemlo-processor/compliance/Compliance.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Compliance"
                }
            },
            ...posRoutes('WemloProcessor'),
            ...commonRoutes('WemloProcessor')
        ]
    }

]