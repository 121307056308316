
export default [
    {
        path: "/login",
        name: "Login",
        component: () => import(/* webpackChunkName: "login" */'@/components/authentication/login/Login.vue'),
        meta: { title: "Login" },
    },
    {
        path: "/",
        name: "SignUp",
        component: () => import(/* webpackChunkName: "signup" */'@/components/authentication/signup/SignUp.vue'),
        meta: { title: "Sign Up" },
    },
    {
        path: "/thank-you",
        name: "ThankYou",
        component: () => import(/* webpackChunkName: "thankyou" */'@/components/authentication/thank-you/ThankYou.vue'),
        props: true,
        beforeEnter(to, from, next) {
            if(!to.params || !to.params['userId'] || !to.params['userEmail']) {
                return next('/');
            }

            next({
                userId: to.params.userId,
                userEmail: to.params.userEmail
            });
        },
        meta: { title: "Thank You" },
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () => import(/* webpackChunkName: "forgot-password" */'@/components/authentication/forgot-password/ForgotPassword.vue'),
        meta: { title: "Forgot Password" },
    },
    {
        path: "/activation-page",
        component: () => import(/* webpackChunkName: "activation-page" */'@/components/authentication/activation-page/ActivationPage.vue'),
        name: "ActivationPage",
        meta: { title: "Authentication" },
    },
    {
        path: "/terms-and-conditions",
        component: () => import(/* webpackChunkName: "terms-and-conditions" */'@/components/authentication/terms-and-conditions/TermsAndConditions.vue'),
        name: "TermsAndConditions",
        meta: { title: "Terms And Conditions" },
    },
    {
        path: '/scenario-desk-response',
        name: 'ScenarioDeskResponse',
        component: () => import(/* webpackChunkName: "manage-users" */'@/components/authentication/scenario-desk-response/ScenarioDeskResponse.vue'),
        meta: { title: "ScenarioDeskResponse" }
    },
    {
        path: '/email-reply',
        name: 'EmailReply',
        component: () => import(/* webpackChunkName: "manage-users" */'@/components/authentication/email-reply/EmailReply.vue'),
        meta: { title: "EmailReply" }
    },
    {
        path: '/reply-from-wemloSupport',
        name: 'ReplyFromWemloSupport',
        component: () => import(/* webpackChunkName: "reply-from-wemloSupport" */'@/components/authentication/reply-from-wemloSupport/ReplyFromWemloSupport.vue'),
        meta: { title: "ReplyFromWemloSupport" }
    },
    {
        path: '/title-order-confirmation',
        name: 'TitleOrderConfirmation',
        component: () => import(/* webpackChunkName: "reply-from-wemloSupport" */'@/components/authentication/title-order-confirmation/TitleOrderConfirmation.vue'),
        meta: { title: "TitleOrderConfirmation" }
    },
    {
        path: '/title-order-reports',
        name: 'TitleOrderReports',
        component: () => import(/* webpackChunkName: "title-order-reports" */'@/components/authentication/title-order-reports/RequestTitleOrderReport.vue'),
        meta: { title: "TitleOrderReports" }
    }
]
