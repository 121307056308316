
















































import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import { HollowDotsSpinner } from "epic-spinners";
import Axios from "axios";
import {LogIn} from "@/models/login.model";
import { identifyUserSession } from "@/logrocket";

@Component({ components: { HollowDotsSpinner } })
export default class ReAuthenticationModal extends Vue {
  public login = new LogIn();
  public password = null;
  public authAttempts = 0
  public errorMsg = null;
  public errorType = null;
  public prevRoute = null;

  public get hasError() {
    return this.errorType === 'password' && this.password !== ''
  }

  public get email() {
    return this.$store.state.sessionObject.email;
  }

  public async validateAndReLogin(evt) {
    this.$store.commit('SET_WEMLO_LOADER', true)
    try {
      this.errorMsg = null;
      this.errorType = null;

      /**
       * Run form validation
       */
      let formValid = await this.$validator.validateAll();
      if (!formValid) {
        this.$store.commit('SET_WEMLO_LOADER', false)
        return;
      }

      /**
       * Normal login
       */
      let form = {
        email: this.email,
        password: this.password,
        isRememberMe: !!this.$store.state.sessionObject.rememberMeToken,
      };

      /**
       * Submit login form
       */
      const response = await Axios.post(BASE_API_URL + "authentication/login", form);

      if (response.data.token) {
        this.password = null;
        this.authAttempts = 0;
        this.errorMsg = null;
        this.saveRememberMeData(response.data.rememberMeToken);

        /**
         * Configure LogRocket to capture basic user information.
         */
        identifyUserSession(response.data);

        /**
         * Update user's token in vuex data store
         */
        this.$store.commit("SET_SESSION_TOKEN", response.data.token);

        /**
         * Check if we stored original link that has been hit by a user. If yes, redirect to it.
         */
        const originalSessionUrl = sessionStorage.getItem('original_url');

        this.$modal.hide('reAuthenticationModal')

        if (originalSessionUrl) {
          sessionStorage.removeItem('original_url');
          await this.$router.push(originalSessionUrl);
          this.$store.commit('SET_WEMLO_LOADER', false)
          return;
        }
      }
    } catch (error) {
      this.authAttempts += 1
      const errorData = error.response ? error.response.data : {};

      if (errorData.status) {
        if (errorData.type === "token") {
          this.removeRememberMeData();
        }

        this.errorMsg = errorData.message;
        this.errorType = errorData.type;
        if (this.authAttempts >= 3) {
          await this.logOut()
        }
      }
    } finally {
      this.$store.commit('SET_WEMLO_LOADER', false)
    }
  }

  public saveRememberMeData(rememberMeToken: string) {
    if (rememberMeToken) {
      localStorage.setItem("rememberMeToken", rememberMeToken);
    } else {
      this.removeRememberMeData();
    }
  }

  public removeRememberMeData() {
    localStorage.removeItem("rememberMeToken");
  }

  async logOut() {
    const currentPath = this.$route.fullPath;
    sessionStorage.setItem('original_url', currentPath);
    localStorage.setItem("logout-event", "user_logged_out" + Math.random());
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    localStorage.removeItem("rememberMeToken");
    await this.$store.dispatch("clearSession");
    this.$modal.hide('reAuthenticationModal');
    if (this.$route.name != "Login") await this.$router.push({ name: "Login" });
    this.clearState();
  }

  public clearState() {
    this.login = { ...new LogIn() };
    this.password = null;
    this.errorMsg = null;
    this.errorType = null;
    this.authAttempts = 0;
  }
}
