





































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL,EventBus } from "@/config";

import Axios from "axios";
import { PopoverPlugin } from "bootstrap-vue";
import { Device } from "twilio-client";
import { mixins } from "vue-class-component";
import print from "print-js";
import CommonMixin from "../mixins/common.mixin";
import CommunicationPopup from "../views/CommunicationPopup.vue";
import { properties } from "@syncfusion/ej2-vue-dropdowns/src/drop-down-list/dropdownlist.component";
import AddNewBroker from "@/views/AddNewBroker.vue";

Vue.use(PopoverPlugin);

@Component({
  components: {
    CommunicationPopup,
    AddNewBroker,
  }
})
export default class WeMLOHeaderComponent extends mixins(CommonMixin) {
  @Prop()
  public headerInfo: string;
  
  @Prop()
  public preText: string;

  @Prop()
  public borrowerName: string;

  @Prop()
  public wesignParty: string;

  @Prop()
  public borrowerNotificationCount: string;

  @Prop()
  public showLogOut: string;

  @Prop()
  public editCompliance: any;

  // Lifecycle Hooks here
  //public callLogs: any = [];
  public isChecked = true;
  public ediFile: any = null;
  public clickedOutside = false;
  public invitationLink = false;
  //Function for play learn more video .
  private playWeLearnVideo() {
    EventBus.$emit("openWeLearn", {
      taskName: "Processing Platform",
      videoUrl:
        "https://wemlo.s3.amazonaws.com/Common/taskdemo/ProcessingPlatform.mp4"
    });
  }
  public logout() {

    localStorage.setItem("logout-event", "user_logged_out" + Math.random());
    this.$store.dispatch("clearSession");
    if (this.$route.name != "Login")
      this.$router.push({
        name: "Login"
      });
  }

  public async switchBalancing(val) {
    try {
      let sure = false;
      let msg = !val
        ? "Are you sure you want to stop sending new loan?"
        : "Are you sure you want to send new loan?";
      await this.$dialog
        .confirm(msg)
        .then(e => {
          sure = true;
        })
        .catch(f => {
          sure = false;
          this.isChecked = !this.isChecked;
          this.$snotify.info("No changes");
        });
      if (sure) {
        this.isChecked = val;
        await this.updateProcessorAudiTrail({
          performedBy: this.$store.state.sessionObject.userId,
          activity: this.isChecked,
          processorId: this.$store.state.sessionObject.userId
        });
      }
    } catch (error) {
      console.log(error, "switchBalancing");
    }
  }
  public async getProcessorAuditTrail() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "wemloStaff/getProcessorAuditTrail",
        {
          userId: this.$store.state.sessionObject.userId
        });
      this.isChecked = response.data.processorActivity.inLoadBalancing;
    } catch (error) {
      console.log(error, "getProcessorAuditTrail");
    }
  }

  public toggleOverLayPopOver(clickedOutside = false) {
    let overlay: any = this.$refs.overlay;
    if (overlay.style.display == "block") {
      overlay.style.display = "none";
    } else {
      overlay.style.display = "block";
    }

    if (clickedOutside) {
      this.clickedOutside = true;
    } else {
      this.clickedOutside = false;
    }
  }

  public goBack() {
    try {
      if (this.headerInfo == "Add BorrowerInfo") {
        this.$router.go(0);
      } else {
        this.$router.go(-1);
      }
    } catch (error) {}
  }

  //send a invitation link for new Broker from superAdmin
  public async sendInvitationToBroker() {
    this.invitationLink = true;
  }
  async mounted() {
    if (this.$store.state.sessionObject.type == "Wemlo Processor")
      this.getProcessorAuditTrail();
    if (
      this.$store.state.sessionObject &&
      this.$store.state.sessionObject.userId &&
      (this.$store.state.sessionObject.type == "MLO" ||
        this.$store.state.sessionObject.type == "Admin Staff" ||
        this.$store.state.sessionObject.type == "Wemlo Processor")
    ) {
      await this.$store.dispatch(
        "updateAccessRight",
        this.$store.state.sessionObject.userId
      );
      await this.$store.dispatch("setSidenavLinks", {
        type: this.$store.state.sessionObject.type,
        access: this.$store.state.sessionObject.access
      });
    }
  }
}
