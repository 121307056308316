import Vue from 'vue';
import './directives/dirty-field';

Vue.directive('num', {
    update: function (el: any, binding: any, vnode) {
        el.value = el.value.replace(/\D/g, '')
    }
})

/**
  Defines custom directive to close the vueJsModal on clicking the background/esc key press.
  Works only of modal with class "wemlo-dialog" and call action defined on close 'X' icon with class
  'wemlo-dialog__close'. Can be customized more as per the future needs.
*/
let handleClickOutside
Vue.directive('customClickToClose', {
  bind(el, binding, vnode) {
    handleClickOutside = (e) => {
      e.stopPropagation()
      let { handler, exclude, actionTargetCls, excludeTargetCls } = binding.value || {};
      let clickedOnExcludedEl = false
      actionTargetCls = actionTargetCls || 'wemlo-dialog__close';
      excludeTargetCls = excludeTargetCls || 'wemlo-dialog';
      // Gives you the ability to exclude certain elements if you want, pass as array of strings to exclude
      if (exclude) {
        exclude.forEach(refName => {
          if (!clickedOnExcludedEl) {
            const excludedEl = vnode.context.$refs[refName]
            clickedOnExcludedEl = excludedEl.contains(e.target)
          }
        })
      }
      let dialogEl = el.getElementsByClassName(excludeTargetCls)[0];
      if (dialogEl && !dialogEl.contains(e.target) && !clickedOnExcludedEl) {
        const $closeEl = el.getElementsByClassName(actionTargetCls);
        if($closeEl && $closeEl[0]) $closeEl[0].click();
        document.removeEventListener('click', handleClickOutside)
      }
    }
    document.addEventListener('click', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
  },

  unbind() {
    document.removeEventListener('click', handleClickOutside)
    document.removeEventListener('touchstart', handleClickOutside)
  }
})

class Observer {
  private readonly observer: IntersectionObserver;
  private readonly el: any;

  constructor(el, binding) {
    this.el = el;
    this.observer = new IntersectionObserver(
        async entries => {
          const entry = entries[0];
          const isWemloLoaderUsed = binding.modifiers?.['wemlo-loader'];
          if (entry.isIntersecting) {
            if (isWemloLoaderUsed) {
              Vue.prototype.$store.commit('SET_WEMLO_LOADER', true);
            }
            await binding?.value?.();
            if (isWemloLoaderUsed) {
              Vue.prototype.$store.commit('SET_WEMLO_LOADER', false);
            }
          }
        },
        { threshold: 0.1 },
    )
    this.createLoader();
    this.setObserveElement();
  }

  createLoader() {
    this.el?.insertAdjacentHTML('beforeend', '<div class="infinite-scroll-loader"></div>');
  }

  setObserveElement() {
    const loader = this.el.querySelector('.infinite-scroll-loader');
    loader && this.observer?.observe(loader);
  }

  disconnect() {
    this.observer?.disconnect();
  }

}

const observers = {};
let observersCount;

Vue.directive('infinite-scroll', {
  inserted(el, binding) {
    if (!Object.keys(observers).length) {
      observersCount = 0;
    }
    observers[observersCount] = new Observer(el, binding);
    el.setAttribute('data-infinite-scroll', observersCount);
    observersCount++;
  },
  unbind(el) {
    const index = el.getAttribute('data-infinite-scroll')
    observers[index]?.disconnect();
    delete observers[index];
  }
});

Vue.directive('lock-field', (el, binding) => {
  //if loan modificator
  if (binding.modifiers.loan) {
    switch (el.tagName) {
      case ('INPUT'):
      case ('SELECT'):
      {
        if (binding?.value) {
          el.setAttribute('disabled', 'true');
          el.classList.add('cursor-not-allowed');
        } else if (!el.hasAttribute('disabled')) {
          el.removeAttribute('disabled');
          el.classList.remove('cursor-not-allowed');
        }
        break;
      }
      case ('EM'):
      case ('I'):
      {
        if (binding?.value) {
          el.classList.add('cursor-not-allowed', 'eventb');
        } else {
          el.classList.remove('cursor-not-allowed', 'eventb');
        }
        break;
      }
      case ('IMG'):
      {
        if (binding?.value) {
          el.classList.add('img-btn-disabled');
        } else {
          el.classList.remove('img-btn-disabled');
        }
        break;
      }
      case ('SPAN'):
      {
        if (binding?.value) {
          el.classList.add('eventb');
        } else {
          el.classList.remove('eventb');
        }
        break;
      }
      default:
        const elem = el.querySelector('input') || el.querySelector('select');
        let checkRound;
        if (elem.getAttribute('type') === 'radio') {
          checkRound = el.querySelector('.checkround');
        }
        if (binding?.value) {
          elem.setAttribute('disabled', 'true');
          elem.classList.add('cursor-not-allowed');
          if (checkRound) {
            checkRound.classList.add('cr_radio_disable');
            el.classList.add('cursor-not-allowed');
          }
        } else {
          elem.removeAttribute('disabled');
          elem.classList.remove('cursor-not-allowed');
          if (checkRound) {
            checkRound.classList.remove('cr_radio_disable');
            el.classList.remove('cursor-not-allowed');
          }
        }
    }
  }

  //add here another modificators

})
