import { Address } from './pos/common.model';
import { CityAndState } from './broker.model';

export class borrowerData {
  constructor() {
    this.userId = null;
    this.loanTxnId = null;
    this.brokerId = null;
    this.addedBy = null;
    this.originatorModelName = null;
    this.source = null;
    this.ediFile = null;
    this.loanStatus = JSON.parse(JSON.stringify(LoanStatus));
    this.loanInformation = new loanInfo();
    this.basicInformation = new basicInformation();
    this.additionalOption = new additionalOption();
    this.realtorInformation = new realtorInformation();
    this.creditAuthorization = false;
    this.impounds = false;
    this.isCreditAuthorizationUploaded = false;
    this.relationshipMapping = new relationshipMapping();
    this.updatedClosingDate = null;
    this.primaryBorrowerIndex = 0;
    this.wemloProcessing = false;
    // this.loanStatusHistory = new Array<LoanStatusSchema>();
    this.activeLoanStatus = new LoanStatusSchema;
    // this.additionalTaskLoanStatus = new Array<LoanStatusSchema>();
    this.isRatedAndSkipped = new isRatedAndSkipped();
    this.moveToLead = false;
    this.isTitleOrder = true;
    this.updatedRateType=null;
    this.updatedRateLockDate = null;
    this.updatedRateExpirationDate=null;
    this.updatedRateLockExtensionDate=null;
    // this.creditAuthorizationHistory = new creditDetail();
  }
  public userId: string;
  public loanTxnId: string;
  public brokerId: string;
  public addedBy: string;
  public originatorModelName: string;
  public source: string;
  public ediFile: [{
    path: { type: string },
    uploadedOn: { type: Date }
  }];
  public loanInformation: loanInfo;
  public basicInformation: basicInformation;
  public loanStatus: Array<any>;
  public agencyCaseNumber: string;
  public lenderCaseNumber: string;
  public additionalOption: additionalOption;
  public creditAuthorization: boolean;
  public impounds: boolean;
  public realtorInformation: realtorInformation;
  public isCreditAuthorizationUploaded: boolean;
  public relationshipMapping: relationshipMapping;
  public updatedClosingDate: any;
  public primaryBorrowerIndex: number;
  public wemloProcessing: boolean;
  public loanStatusHistory: Array<LoanStatusSchema>;
  public activeLoanStatus: LoanStatusSchema;
  public additionalTaskLoanStatus: Array<LoanStatusSchema>;
  public isRatedAndSkipped : isRatedAndSkipped
  public moveToLead: boolean;
  public creditAuthorizationHistory:creditDetail;
  public isTitleOrder: boolean;
  public updatedRateType:string;
  public updatedRateLockDate:{ type: Date };
  public updatedRateExpirationDate:{ type: Date };
  public updatedRateLockExtensionDate:{ type: Date };
}

export class creditDetail{
  public userId:{ type: String };
  public creditAuthorization:{ type: boolean };
  public updatedOn: { type: Date }
}
// class basicInformation {
//   constructor() {
//     this.borrower = new BasicDetail();
//     this.coBorrower = [new coBorrowerDetail()];
//     this.hasCoBorrower = false;
//   }
//   public borrower: BasicDetail;
//   public coBorrower: Array<coBorrowerDetail>;
//   public hasCoBorrower: boolean;
// }


class isRatedAndSkipped{
  constructor(){
      this.isRated = false,
      this.isSkipped = false
  
  }
    public isRated :boolean
    public isSkipped:boolean
  }

export class loanInfo {
  constructor() {
    this.lenderId = "";
    this.lenderName = "";
    this.lenderMortgageeClause = "";
    this.price = 0;
    this.rate = 0;
    this.AUS = null;
    this.propertyValue = 0;
    this.estimatedPropertyValue = 0;
    this.loanAmount = 0;
    this.compensationType = "";
    this.compensation = "";
    this.closingDate = null;
    this.rateType = null;
    this.waive = null;
    this.matchLenderToBpc = null;
    this.productType = null;
    this.LTV = 0;
    this.CLTV = 0;
    this.propertyType = { ppeValue: "2", name: "Single Family Detached", ediValue: "01", mismoValue: "SingleFamilyDetached", value: "Single Family Detached" };
    this.FICO = null;
    this.DTI = 0;
    this.occupancy = { ppeValue: "1", name: "Owner Occupied", ediValue: "1", mismoValue: "PrimaryResidence", value: "Primary Residence" } // Primary residence
    this.noFICO = false;
    this.lockDays = 30;
    this.nonOccupantCoBorrower = false;
    this.amortizationType = null;
    this.interestRateOnly = true;
    this.purpose = { ppeValue: "1", name: "Purchase", ediValue: "16", mismoValue: "Purchase", value: "Purchase" };
    this.term = { ppeValue: "5", name: "30 Years", ediValue: "", mismoValue: "30", value: 12 * 30 };
    this.feeInPrice = false;
    this.homePath = 'false';
    this.LPMI = false;
    this.escrows = null;
    this.wemloProcessingFee = true;
    this.doctype = null;
    this.prepayPenalty = null;
    this.investorOptions = null;
    this.zipCode = null;
    this.state = new CityAndState();
    this.county = "";
    this.minDownPayment = 0;
    this.minDownPaymentType = "$";
    this.cashout = 0;
    this.subjectPropertyAddress = new Address();
    this.mortgageMonthlyPayment = 0;
    // this.cashOutAmount = 0
    // this.currentLoanBalance = 0
    // this.militaryStatus = false
    // this.appliedVALoan = false
    this.tridApplicationDate = null;
  }

  lenderMortgageeClause :string;
  price: number;
  lenderId: string;
  lenderName: string;
  rate: number;
  AUS: object;
  propertyValue: number;
  estimatedPropertyValue: any;
  loanAmount: number;
  compensationType: any;
  compensation: string;
  closingDate: any;
  rateType: any;
  waive: any;
  matchLenderToBpc: any;
  productType: object;
  LTV: number;
  CLTV: number;
  propertyType: object;
  FICO: number;
  DTI: number;
  occupancy: object;
  noFICO: boolean;
  lockDays: number;
  nonOccupantCoBorrower: boolean;
  amortizationType: object;
  interestRateOnly: boolean;
  purpose: object;
  term: object;
  feeInPrice: boolean;
  homePath: string;
  LPMI: boolean;
  escrows: object;
  wemloProcessingFee: boolean;
  doctype: object;
  prepayPenalty: object;
  investorOptions: object;
  state: CityAndState;
  county: string;
  minDownPayment: any;
  minDownPaymentType: string;
  zipCode: string;
  cashout: number;
  subjectPropertyAddress: Address;
  // cashOutAmount: number;
  // currentLoanBalance: number;
  // militaryStatus: boolean;
  // appliedVALoan: boolean;
  mortgageMonthlyPayment: number;
  tridApplicationDate: Date;
}


class basicInformation {
  constructor() {
    this.borrower = new BasicDetail();
    this.coBorrower = [new coBorrowerDetail()];
    this.hasCoBorrower = false;
  }
  public borrower: BasicDetail;
  public coBorrower: Array<coBorrowerDetail>;
  public hasCoBorrower: boolean;
}
export class coBorrowerDetail {
  constructor() {
    this.firstName = null;
    this.lastName = null;
  }
  public firstName: string;
  public lastName: string;
}

export class BasicDetail {
  constructor() {
    this.firstName = null;
    this.lastName = null;
    this.phone = "";
    this.email = null;
    this.address = new Address();
  }
  public firstName: string;
  public lastName: string;
  public phone: string;
  public email: string;
  public address: Address;
}

class additionalOption {
  constructor() {
    this.nonPermResAlien = false;
    this.foreignNational = false;
    this.firstTimeHomeBuyer = false;
    this.seasoning = 24;
    this.acres = 0;
    this.reserveMOS = 24;
    this.propertiesFinanced = 1;
    this.employment = null;
    this.lates = new lates();
  }
  public nonPermResAlien: boolean;
  public foreignNational: boolean;
  public firstTimeHomeBuyer: boolean;
  public lates: lates;
  public seasoning: number;
  public acres: number;
  public reserveMOS: number;
  public propertiesFinanced: number;
  public employment: object;
  // prepayPenalty: { type: String },
  // investorOptions: { type: String }
}

class lates {
  constructor() {
    this.thirty = 0;
    this.sixty = 0;
    this.ninty = 0;
    this.oneHundredTwenty = 0;
  }
  public thirty: number;
  public sixty: number;
  public ninty: number;
  public oneHundredTwenty: number;
}

export class realtorInformation {
  constructor() {
    this.name = null;
    this.companyName = null;
    this.phone = null;
    this.email = null;
  }
  public name: string;
  public companyName: string;
  public phone: string;
  public email: string;
}

export class relationshipMapping {
  constructor() {

  }
  public borrowerIndex: number;
  public coBorrowerIndex: Array<number>;
}

class LoanStatusSchema{
  taskId: number ;
  statusId: number;
  createdOn: Date;
  updatedOn: Date;
  completedOn: Date;
  commentId: string;
  showOnTimeline: boolean;
  timelineNumber: number;                        //Only used for borrower stepper ( Loan History- borrowerprofile on broker )
}


const LoanStatus = [
  {
    // status- Prospect
    //task - null
    statusId: 1,
    taskId: null,
    timeline: false,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- PreQualification
    //task - null
    statusId: 2,
    taskId: null,
    timeline: false,
    statusCompleted: false,
    active: false,
    count: 0,
    commentIndex: null,
  },
  {
    // status- Application InProgress
    //task - Loan Setup
    statusId: 3,
    taskId: 14,
    timeline: false,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- Quote/PreQualification
    //task - null
    statusId: 4,
    taskId: null,
    timeline: false,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- Application Complete
    //task - Run Aus
    statusId: 5,
    taskId: 1,
    timeline: true,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- AUS
    //task - Run Aus
    statusId: 6,
    taskId: 1,
    timeline: false,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- AUS Hold
    //task - Run Aus
    statusId: 7,
    taskId: 1,
    timeline: true,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- AUS Approved
    //task - Initial Disclosure
    statusId: 8,
    taskId: 6,
    timeline: true,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- AUS N/A
    //task - Initial Disclosure
    statusId: 9,
    taskId: 6,
    timeline: true,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- Inactive Withdrawal
    //task - null
    statusId: 10,
    taskId: null,
    timeline: false,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- Initial Disclosure Sent
    //task - UW Response
    statusId: 11,
    taskId: 7,
    timeline: true,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- UW Submitted
    //task - UW Response
    statusId: 20,
    taskId: 7,
    timeline: true,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- Condition Submitted
    //task - UW Response
    statusId: 12,
    taskId: 7,
    timeline: true,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- Conditional Approval
    //task - Conditional Approval
    statusId: 14,
    taskId: 8,
    timeline: false,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- Suspended
    //task - Suspended
    statusId: 15,
    taskId: 9,
    timeline: false,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- Denial
    //task - Denial
    statusId: 16,
    taskId: 10,
    timeline: false,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- Clear To Close
    //task - Closing
    statusId: 13,
    taskId: 12,
    timeline: true,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- Inactive Denial
    //task - null
    statusId: 17,
    taskId: null,
    timeline: false,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- Pending Funding
    //task - Funding
    statusId: 18,
    taskId: 13,
    timeline: true,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- Finished & Archived
    //task - null
    statusId: 19,
    taskId: null,
    timeline: false,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  },
  {
    // status- Clear To Close
    //task - Clear To Close Filled
    statusId: 28, //this code has to be modified because we have a duplicate status name for clear to close 
    taskId: 19,
    timeline: false,
    statusCompleted: false,
    active: false,
    count: 0,
    additionalStatus: null,
    commentIndex: null
  }
]