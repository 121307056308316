import Vue from "vue";
import Router from "vue-router";
import AuthenticationRoutes from '@/routes/authentication.routes';
import BrokerRoutes from '@/routes/broker.routes';
import BorrowerRoutes from '@/routes/borrower.routes';
import POSRoutes from '@/routes/pos.routes';
import SuperAdminRoutes from '@/routes/super-admin.routes';
import WemloProcessorRoutes from '@/routes/wemlo-processor.routes';
import store from '@/store/store';
import { verifyMailVerificationToken, divertRoute, checkIfPreRequisiteCompleted } from '@/services/auth.service';
import adminStaffRoutes from './admin-staff.routes';
import mloRoutes from './mlo.routes';
import { isStepCompleted, getOnboardingStep } from "@/constants";
import _ from "lodash";
import Axios from "axios";
import {BASE_API_URL, setInstanceVariables} from "@/config";
import {identifyUserSession} from "@/logrocket";

/**
 * Load the vue router instance 
 */
Vue.use(Router);

/**
 * Load all routes definitions
 */
const ROUTER = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        ...AuthenticationRoutes,
        ...BrokerRoutes,
        ...BorrowerRoutes,
        ...adminStaffRoutes,
        ...mloRoutes,
        ...SuperAdminRoutes,
        ...WemloProcessorRoutes
    ]
});
async function checkTokenId() {
    const urlParams = new URLSearchParams(window.location.search);
    const authToken = urlParams.get('id_token');
    const response = await Axios.get(BASE_API_URL + "authentication/me", {
        headers: {
            'Authorization': authToken
        }
    });
    urlParams.delete('id_token');
    if (response.data.token || response.data.availableAccounts ) {
        await store.dispatch("setSession", response.data);
        /**
         * Configure LogRocket to capture basic user information.
         */
        identifyUserSession(response.data);

        /**
         * Set all the user's information to vuex data store
         */
        await store.dispatch("setSession", response.data);
        /**
         * Set user's id to instance to make it easily accessible
         */
        await setInstanceVariables();
        /**
         * Dispatch the information of the user which holds the access rights on to components
         * after onboarding/login.
         */
        await store.dispatch("setSidenavLinks", response.data);
    }
    return response.data?.availableAccounts;
}

/**
 * Authenticate the routes based on token & route permission
 */
ROUTER.beforeEach(async (to, from, next) => {
    // document.title = to.meta.title.replace(/([a-z])([A-Z])/g, '$1 $2');
    let state: any = store.state;
    let userInfo = _.get(state, "sessionObject.userInfo", null);
    if(userInfo){
        state.sessionObject.userInfo.completedPreRequisitesId = getOnboardingStep(state);
    }

    if (state.sessionObject.token && !to.meta.requiresAuth && to.name != "ActivationPage") {
        await divertRoute(ROUTER, to);
        return;
    }
    else if (state.sessionObject.token && to.meta.requiresAuth && state.sessionObject.type == 'Broker') {
        const isOnboardingCompleted = isStepCompleted(getOnboardingStep(state), "onboarding");
        
        //Added this condition to prevent users to navigate to any other route apart from pre-requisite if pre-requisite is not completed.-Aman Saurabh
        if (to.query.hasOwnProperty("email_verified")) {
            await checkIfPreRequisiteCompleted(ROUTER, to, from);
            return;
        } else if (to.meta.title !== 'Prerequisites' && !isOnboardingCompleted) {
            ROUTER.push({ path: '/broker/pre-requisites' });
            return;
        } else if (to.meta.title === 'Prerequisites' && isOnboardingCompleted) {
            ROUTER.push({ path: '/broker/dashboard' });
            return;
        }
    }
    else if (state.sessionObject.token && to.meta.requiresAuth && state.sessionObject.type == 'MLO') {
        const isOnboardingCompleted = isStepCompleted(getOnboardingStep(state), "agreement");

        if (to.meta.title !== 'Prerequisites' && !isOnboardingCompleted) {
            ROUTER.push({ path: '/mlo/pre-requisites' });
            return;
        } else if (to.meta.title === 'Prerequisites' && isOnboardingCompleted) {
            ROUTER.push({ path: '/mlo/dashboard' });
            return;
        }
    }

    if (to.query.hasOwnProperty("email_auth") || to.query.hasOwnProperty("broker_auth") || to.query.hasOwnProperty("email_verified")) { // broker signup and borrower-welcome page
        let data = {};
        if (to.query.hasOwnProperty("broker_auth")) {
            data['needUserInfo'] = true;
            data['token'] = to.query.broker_auth
        } else if (to.query.hasOwnProperty("email_verified")) {
            data['needUserInfo'] = true;
            data['token'] = to.query.email_verified;
        }
        else {
            data['needUserInfo'] = false;
            data['token'] = to.query.email_auth
        }
        let tokenValid = await verifyMailVerificationToken(data);
        if (tokenValid) {
            if( state.sessionObject && state.sessionObject.type == 'Borrower')
                await divertRoute(ROUTER, to);
            else
                ROUTER.replace({ name: to.name });
        } else {
            ROUTER.push({ name: "SignUp" });
            return;
        }
    }
    if (to.query.hasOwnProperty('id_token')) {
        await checkTokenId();
    }

    if (to.meta.requiresAuth) {
        sessionStorage.setItem('original_url', to.fullPath);

        if (state.sessionObject.token || to.query.email_auth || to.query.broker_auth || to.query.email_verified) {
            next();
        } else {
            ROUTER.replace({ name: "SignUp" });
        }
    } else {
        next();
    }
});

ROUTER.afterEach(async (to, from) => {
    let payload = {
        to: {
            path: to.path,
            fullPath: to.fullPath,
            hash: to.hash,
        },
        from: {
            path: from.path,
            fullPath: from.fullPath,
            hash: from.hash,
        },
    }
    store.commit("SET_ROUTE_HISTORY", payload);
});

export default ROUTER;