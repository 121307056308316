import posRoutes from './pos.routes';
import stores from '@/store/store';
const store: any = stores;
import { getCurrentUserTypePath } from "./broker.routes";
import commonRoutes from './common.routes';
export const beforeEachEnter = async (to, from, next) => {
    if (store.state.sessionObject.type == "Super Admin") {
        next()
    } else {
        let path = await getCurrentUserTypePath()
        next(path)
    }
};
export default [
    {
        path: '/super-admin',
        name: 'SuperAdmin',
        component: () => import(/* webpackChunkName: "super-admin" */'@/components/broker/Broker.vue'),
        redirect: { name: 'SuperAdminDashBoard' },
        meta: {
            requiresAuth: true,
            title: "Dashboard"
        },
        beforeEnter: beforeEachEnter,
        children: [
            {
                path: "dashboard",
                name: "SuperAdminDashBoard",
                component: () => import(/* webpackChunkName: "superadmin-dashboard" */'@/components/super-admin/report-dashboard/ReportDashboard.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Dashboard"
                },
            },
            {
                path: 'change-password',
                name: 'ChangePassword',
                component: () => import(/* webpackChunkName: "superadmin-change-password" */'@/components/super-admin/change-password/changePassword.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Change Password"
                }
            },
            {
                path: 'broker-loan-detail',
                name: 'BrokerLoanDetail',
                component: () => import(/* webpackChunkName: "superadmin-broker-loan-detail" */'@/components/super-admin/broker-loan-details/BrokerLoanDetails.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Broker Loan Detail"
                }
            },
            {
                path: 'broker-list',
                name: 'SuperAdminBrokerList',
                component: () => import(/* webpackChunkName: "superadmin-broker-list" */'@/components/super-admin/broker-list/BrokerList.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Broker List"
                }
            },
            {
                path: 'broker-detail',
                name: 'SuperAdminbrokerDetail',
                component: () => import(/* webpackChunkName: "superadmin-broker-details" */'@/components/super-admin/broker-details/brokerDetails.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Broker Detail"
                }
            },
            {
                path: 'borrower-list',
                name: 'SuperadminBorrowerList',
                component: () => import(/* webpackChunkName: "superadmin-borrower-list" */'@/components/super-admin/borrower-list/BorrowerList.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Borrower List"
                }
            },
            {
                path: 'manage-twilio',
                name: 'ManagerTwilio',
                component: () => import(/* webpackChunkName: "group-superadmin-borrower-det" */'@/components/super-admin/manage-twilio/ManageTwilio.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Manage Twilio Numbers"
                }
            },

            {
                path: 'manage-Roles',
                name: 'Manage Roles',
                component: () => import(/* webpackChunkName: "manage-role" */'@/components/super-admin/manage-Roles/ManageRole.vue'),
                meta: {
                    requiresAuth: true,
                    title: "manageRole"
                }
            },
            {
                path: 'report-setting',
                name: 'Report Setting',
                component: () => import(/* webpackChunkName: "report-setting" */'@/components/super-admin/report-setting/report-setting.vue'),
                meta: {
                    requiresAuth: true,
                    title: "reportSetting"
                }
            },
            {
                path: 'we-help-list',
                name: 'We Help List',
                component: () => import(/* webpackChunkName: "we-help-list" */'@/components/super-admin/we-help-list/WeHelpList.vue'),
                meta: {
                    requiresAuth: true,
                    title: "WeHelpList"
                }
            },
            {
                path: 'wemlo-vendors',
                name: 'Wemlo Vendors',
                component: () => import(/* webpackChunkName: "wemlo-vendors" */'@/components/super-admin/wemlo-vendors/WemloVendors.vue'),
                meta: {
                    requiresAuth: true,
                    title: "wemloVendors"
                }
            },
            {
                path: 'clear-activity-logs',
                name: 'Clear Activity Log',
                component: () => import(/* webpackChunkName: "clear-activity-logs" */'@/components/super-admin/clear-activity-logs/ClearActivityLogs.vue'),
                meta: {
                    requiresAuth: true,
                    title: "clearactivitylog"
                }
            },
            {
                path: 'schedule-jobs',
                name: 'Schedule Jobs',
                component: () => import(/* webpackChunkName: "schedule-jobs" */'@/components/super-admin/schedule-jobs/ScheduleJobs.vue'),
                meta: {
                    requiresAuth: true,
                    title: "reportlog"
                }
            },
            {
                path: 'state-licenses',
                name: 'state-licenses',
                component: () => import('@/components/super-admin/state-licenses/StateLicenses.vue'),
                meta: {
                    requiresAuth: true,
                    title: "statelicenses"
                }
            },
            {
                path: 'view-all-report',
                name: 'View All Report',
                component: () => import(/* webpackChunkName: "view-all-report" */'@/components/super-admin/view-all-report/ViewAllReport.vue'),
                meta: {
                    requiresAuth: true,
                    title: "reportdetails"
                }
            },
            {
                path: 'review-ocr-response',
                name: 'Review Ocr Response',
                component: () => import(/* webpackChunkName: "review-ocr-response" */'@/components/super-admin/review-ocr-response/ReviewOcrResponse.vue'),
                meta: {
                    requiresAuth: true,
                    title: "reportdetails"
                }
            },
            {
                path: 'daily-reports',
                name: 'Daily Reports',
                component: () => import(/* webpackChunkName: "daily-reports" */'@/components/super-admin/daily-reports/DailyReport.vue'),
                meta: {
                    requiresAuth: true,
                    title: "reportlog"
                }
            },
            {
                path: 'processor-details',
                name: 'Processor Details',
                component: () => import(/* webpackChunkName: "processor-details" */'@/components/super-admin/processor-details/ProcessorDetails.vue'),
                meta: {
                    requiresAuth: true,
                    title: "processordetails"
                }
            },
            {
                path: 'manage-all-user',
                name: 'Manage All User',
                component: () => import(/* webpackChunkName: "processor-details" */'@/components/super-admin/manage-all-user/ManageAllUser.vue'),
                meta: {
                    requiresAuth: true,
                    title: "managealluser"
                }
            },
            {
                path: 'user-logs',
                name: 'User Logs',
                component: () => import(/* webpackChunkName: "user-logs" */'@/components/super-admin/user-logs/UserLogs.vue'),
                meta: {
                    requiresAuth: true,
                    title: "userlogs"
                }
            },
            {
                path: 'credit-report-response',
                name: 'Credit Report Response',
                component: () => import(/* webpackChunkName: "credit-report-response" */'@/components/super-admin/credit-report-response/CreditReportResponse.vue'),
                meta: {
                    requiresAuth: true,
                    title: "creditreportresponse"
                }
            },
            {
                path: 'management',
                name: 'Management',
                component: () => import(/* webpackChunkName: "management" */'@/components/super-admin/management/Management.vue'),
                meta: {
                    requiresAuth: true,
                    title: "management"
                }
            },
            {
                path: 'wemlo-lender',
                name: 'Wemlo Lender',
                component: () => import(/* webpackChunkName: "wemlo-lender" */'@/components/super-admin/wemlo-lender/WemloLender.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Wemlo Lender"
                }
            },
            {
                path: 'broker-report',
                name: 'Broker Report',
                component: () => import(/* webpackChunkName: "management" */'@/components/super-admin/broker-reports/BrokerReport.vue'),
                meta: {
                    requiresAuth: true,
                    title: "brokerreport"
                }
            },
            {
                path: 'setting',
                name: 'Setting',
                component: () => import(/* webpackChunkName: "setting" */'@/components/super-admin/setting/Setting.vue'),
                meta: {
                    requiresAuth: true,
                    title: "My account"
                }
            },
            {
                path: 'pdf-constructor',
                name: 'PDFConstructor',
                component: () => import(/* webpackChunkName: "pdfconstructor" */'@/components/super-admin/pdf-constructor/PDFConstructorPage.vue'),
                meta: {
                    requiresAuth: true,
                    title: "PDF Constructor"
                }
            },
            {
                path: 'pdf-viewer',
                name: 'PDFConstructorReadOnlyPage',
                component: () => import(/* webpackChunkName: "pdfconstructorreadonlypage" */'@/components/super-admin/pdf-constructor/PDFConstructorReadOnlyPage.vue'),
                meta: {
                    requiresAuth: true,
                    title: "PDF Viewer"
                }
            },
            {
                path: 'communications',
                name: 'Communications',
                component: () => import(/* webpackChunkName: "communications" */'@/components/super-admin/communications/Communications.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Communications",
                },
            },
            {
                path: 'borrower-contact',
                name: 'SuperAdminBorrowerContact',
                component: () => import(/* webpackChunkName: "borrower-contact" */'@/views/borrower-contact/BorrowerContactForm.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Borrower Contact"
                }
            },
            ...posRoutes('SuperAdmin'),
            ...commonRoutes('SuperAdmin'),

        ]
    },
]