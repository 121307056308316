import { Address, DonorDetail } from './common.model';
import { DateDuration } from './borrower-info.model';

// export class LoanAndProperty {
//     constructor() {
//         this.borrowerInfo = [new LoanAndPropertyDetails()];
//         this.coBorrowerInfo = [new LoanAndPropertyDetails()];
//     }
//     public loanTxnId: string;
//     public borrowerInfo: Array<LoanAndPropertyDetails>;
//     public coBorrowerInfo: Array<LoanAndPropertyDetails>;
// }

export class LoanAndProperty {
    constructor() {
        this.propertyAddress = new PropertyAddressSchema();
        this.amortization = new AmortizationSchema();
        // this.refinancing = new RefinancingSchema();
        this.additionalLoans = [new AdditionalLoans()];
        this.gifts = [new Gift()];
        this.constructionCase = new ConstructionCase();
        this.refinanceCase = new RefinanceCase();
        this.titleInformation = new TitleInformationSchema();
        this.currentTitleInformation = new TitleInformationSchema();
        this.legalDescription = {
            code: null,
            explanation: null
        };
        this.sourceOfDownPayment = [new SourceOfDownPayment()];
        this.toBeDecidedProperty = false;
        this.appraisedValue = 0;
        this.livingArea = "";
        this.underConstruction = "";
        this.occupancyRate = "";
        this.yearBuilt = null;
        this.interestRate = 0;
        this.occupancy = { ppeValue: "1", name: "Owner Occupied", ediValue: "1", mismoValue: "PrimaryResidence", value: "Primary Residence" };
        this.veteranStatus = null;
        this.attachmentType = null;
        this.isPropertyExistingCleanEnergyLien = null;
    }
    public loanTxnId: string;
    public loanAmount: number;
    public loanPurpose: object;
    public otherSpecification: string;
    public propertyAddress: PropertyAddressSchema;
    public mortgageAppliedFor: object;
    public mortgageAppliedReason: string;
    public yearBuilts: string;
    public amortization: AmortizationSchema;
    public interestRate: number;
    public loanTerm: object;
    public occupancy: object;
    public mixedUseProperty: string;
    public manufacturedHome: string;
    public generateRentalIncome: string;
    public expectedRentalIncome: number;
    public expectedNetRentalIncome: number;
    public additionalLoans: Array<AdditionalLoans>;
    public legalDescription: object;
    public titleInformation: TitleInformationSchema;
    public currentTitleInformation: TitleInformationSchema;
    public receiveGift: string
    public gifts: Array<Gift>;
    public moreThanOneMortgage: string;
    public sourceOfDownPayment: Array<SourceOfDownPayment>
    public constructionCase: ConstructionCase;
    public refinanceCase: RefinanceCase;
    public yearBuilt: string;
    public cashOutAmount: { type: number, default: 0 };
    public currentLoanBalance: { type: number, default: 0 };
    public militaryStatus: { type: string, default: false };
    public appliedVALoan: { type: string, default: false };
    public veteranStatus: string;
    public toBeDecidedProperty: boolean;
    public appraisedValue: number;
    public livingArea: string;
    public underConstruction: string;
    public occupancyRate: string;
    public isPropertyExistingCleanEnergyLien: boolean;
    public projectLegalStructureType: string;
    public isPUD: boolean;
    public isPropertyInProject: boolean;
    public attachmentType: object;
    public msaIdentifier: string;

}

export class SourceOfDownPayment {
    constructor() {
        this.code = null;
        // this.amount = null;
        this.explanation = null;
    }
    public code: object;
    public amount: number;
    public explanation: string;
}
export class LegalDescription {
    constructor() {
        this.code = null;
        this.explanation = null;
    }
    public code: object
    public explanation: string
}
export class Gift {
    constructor() {
        this.assetType = null;
        this.isDeposited = null;
        this.source = null;
        this.otherSource = null;
        this.donorDetail = new DonorDetail();
        this.marketValue = null
    }
    otherSource: string;
    assetType: object;
    isDeposited: string;
    source: object;
    marketValue: number;
    donorDetail: DonorDetail;
}

// class MortgageAppliedFor {
//     VA: string;
//     Conventional: string;
//     Other: string;
//     FHA: string;
//     USDARural: string; 
// }

export class ConstructionCase {
    constructor() {
        this.yearLotAcquired = null;
        this.total = null
    }
    yearLotAcquired: string;
    constructionToPermanentClosingType: string;
    originalCost: number;
    amountExistingLiens: number;
    presentValueOfLot: number;
    costOfImprovements: number;
    total: string;
}

export class RefinanceCase {
    constructor() {
        this.yearAcquired = null;
        this.purposeOfRefinance = null;
        this.toBeMade = null;
        this.describeImprovements = null
    }
    yearAcquired: string;
    originalCost: number;
    amountExistingLiens: number;
    purposeOfRefinance: object;
    toBeMade: string;
    improvementCost: number;
    describeImprovements: string;
}

class PropertyAddressSchema {
    constructor() {
        this.address = new Address();
        this.numberOfUnits = null;
        this.propertyValue = 0;
        this.propertyAddressSameAsCurrentAddress = false;
    }
    address: Address;
    numberOfUnits: string;
    propertyValue: number;
    propertyAddressSameAsCurrentAddress: boolean;
}
export class AdditionalLoans {
    constructor(loan : {creditorName?: string, lienType?: string, monthlyPayment?: number, loanAmount?: number, isHeloc?: boolean, creditLimit?: number} = {}) {    
      Object.assign(this, loan)
    }
    public creditorName: string = "";
    public lienType: string = "";
    public monthlyPayment: number = 0;  
    public loanAmount: number = 0;  
    public isHeloc: boolean = false;
    public creditLimit?: number;  
  }
class AmortizationSchema {
    constructor() {
        this.ARMtype = null;
        this.reason = null;
    }
    type: object;
    ARMtype: string;
    reason: string;
}

class TitleInformationSchema {
    constructor() {
        this.estate = new Estate()
    }
    name: string;
    manner: string;
    estate: Estate
}
class Estate {
    constructor() {
        this.expirationDate = new DateDuration();
        this.type = 'Always Fee Simple';
    }
    type: string;
    expirationDate: DateDuration;
}