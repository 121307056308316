import { Name, Address, MonthYearDate, CompleteDate } from "./common.model";

export class BorrowerInformation {
  constructor() {
    this.borrowerInfo = [new BorrowerDetails()];
    // this.coBorrowerInfo = [new BorrowerDetails()]; Not used in Sprint 5
  }
  public loanTxnId: string;
  public borrowerInfo: Array<BorrowerDetails>;
  // public coBorrowerInfo: Array<BorrowerDetails>;   Not used in Sprint 5
}

class PersonalInfo {
  constructor() {
    this.alternateNames = [new Name()];
    this.maritalStatus = new MaritalStatus();
    this.unmarriedAddendum = new UnmarriedAddendum();
    this.address = new AddressType();
    this.militaryService = new MilitaryService();
    this.name = new Name();
    this.contactInformation = new ContactInformation();
    this.languagePreference = new LanguagePreference();
    this.dateOfBirth = new CompleteDate();
    this.relationWithBorrower = null;
    this.knownByAlternateName = "false";
    this.hasAdditionalBorrower = "false";
    this.addressSameAsBorrower = "false";
    this.payReceiveChildSupport = "false";
    this.ssn = null;
    this.citizenship = null;
    this.typeOfCredit = null;
    this.assetWithCoBorrower = null;
    this.jointWithCoBorrowerIndex = null;
    // this.taxName = null;
    // this.haveTaxEmployerIdentificationNumber = null;
    // this.taxAddress = null;
    // this.havePowerOfAttorney = null;
    // this.isFirstTimeHomeBuyer = null;
    this.isMarriedTo = new IsMarriedTo();
    // this.appliedVALoan = null;
    this.dependents = new DependentsDetails();


  }
  public name: Name;
  public payReceiveChildSupport: string;
  public relationWithBorrower: string;
  public knownByAlternateName: string;
  public alternateNames: Array<Name>;
  public ssn: string;
  // public taxName: string;
  // public haveTaxEmployerIdentificationNumber: string;
  // public taxAddress: string;
  // public havePowerOfAttorney: boolean;
  // public isFirstTimeHomeBuyer: boolean;
  public dateOfBirth: CompleteDate;
  public citizenship: object;
  public typeOfCredit: string;
  public maritalStatus: MaritalStatus;
  public unmarriedAddendum: UnmarriedAddendum;
  public contactInformation: ContactInformation;
  public address: AddressType;
  public militaryService: MilitaryService;
  public languagePreference: LanguagePreference;
  public hasAdditionalBorrower: string;
  public haveDependents: string;
  public addressSameAsBorrower: string;
  public yearsOfSchool: string;
  public assetWithCoBorrower: string;
  public jointWithCoBorrowerIndex: number;
  public isMarriedTo: IsMarriedTo;
  // public appliedVALoan: string;
  public dependents: DependentsDetails;

}

export class BorrowerDetails {
  constructor() {
    this.personalInfo = new PersonalInfo();
    this.currentEmployment = [new CurrentEmployment()];
    this.previousEmployment = [
      new PreviousEmployment()
    ];
    this.incomeFromOtherSources = new IncomeFromOtherSources();
    this.combinedMonthlyExpense = new CombinedMonthlyExpense();
    this.proposedCombinedMonthlyExpense = new CombinedMonthlyExpense();
    this.otherLiabilitiesAndExpenses = [new OtherLiabilitiesAndExpenses()];
  }
  public personalInfo: PersonalInfo;
  public currentEmployment: Array<CurrentEmployment>;
  public previousEmployment: Array<PreviousEmployment>;
  public hasOtherIncomeSource: string;
  public incomeFromOtherSources: IncomeFromOtherSources;
  public combinedMonthlyExpense: CombinedMonthlyExpense;
  public proposedCombinedMonthlyExpense: CombinedMonthlyExpense;
  public otherLiabilitiesAndExpenses: Array<OtherLiabilitiesAndExpenses>;
  public counselingEvents: Array<CounselingEvent>;
}

export class CounselingEvent {
  constructor() {
    this.counselingType = null;
    this.completedDate = null;
    this.confirmationIndicator = null;
    this.identifier = null;
    this.agency = null;
    this.formatType = null;
  }
  public counselingType: object;
  public completedDate: Date;
  public confirmationIndicator: boolean;
  public identifier: string;
  public agency: string;
  public formatType: object;
}
export class OtherLiabilitiesAndExpenses {
  constructor() {
    this.name = null;
    this.monthsLeftToPay = null;
    this.monthlyPayment = 0;
    this.otherDescription = null;
  }
  public name: object;
  public monthlyPayment: number;
  public monthsLeftToPay: string;
  public otherDescription: string; //Added as part of wem-3384 - add new other type desc
}

class CombinedMonthlyExpense {
  constructor() {
    this.hazardInsurance = {
      ediValue: "01",
      monthlyExpense: 0
    }
    this.realEstateTaxes = {
      ediValue: "14",
      monthlyExpense: 0
    };
    this.mortgageInsurance = {
      ediValue: "02",
      monthlyExpense: 0
    };
    this.homeOwnerAssociationDue = {
      ediValue: "06",
      monthlyExpense: 0
    };
    this.other = {
      ediValue: "23",
      monthlyExpense: 0
    };
    this.firstMortgagePandI = {
      ediValue: "26",
      monthlyExpense: 0
    };

    // otherMortgagePandI is also called  OtherFinancingP&I 

    this.otherMortgagePandI = {
      ediValue: "22",
      monthlyExpense: 0
    }
  }
  public hazardInsurance: object;
  public realEstateTaxes: object;
  public mortgageInsurance: object;
  public homeOwnerAssociationDue: object;
  public other: object;
  public firstMortgagePandI: object;
  public otherMortgagePandI: object
}

class UnmarriedAddendum {
  constructor() {
    this.domesticRelationshipIndicator = false;
    this.domesticRelationshipType = "";
    this.domesticRelationshipTypeOtherDescription = "";
    this.domesticRelationshipStateCode = "";
  }
  public domesticRelationshipIndicator: boolean;
  public domesticRelationshipType: string;
  public domesticRelationshipTypeOtherDescription:  string;
  public domesticRelationshipStateCode:  string;
}

class MaritalStatus {
  constructor() {
    this.status = null;
    this.unMarriedStatus = null;
  }
  public status: object;
  public unMarriedStatus: string;
}

class IsMarriedTo {
  constructor() {
    this.firstName = null;
    this.middleName = null;
    this.lastName = null;
  }
  public firstName: string;
  public middleName: string;
  public lastName: string;
}
export class DependentsDetails {
  constructor() {
    this.number = null;
    this.ages = null;
    this.details = [new Dependents()];
  }
  public number: string;
  public ages: string;
  public details: Array<Dependents>;
}

export class Dependents {
  constructor() {
    this.name = null;
    this.age = null;
  }
  public name: string;
  public age: string;
}

class ContactInformation {
  constructor() {
    this.homePhone = null;
    this.cellPhone = null;
    this.workPhone = null;
    this.workPhoneExtension = null;
    this.email = null;
  }
  public homePhone: string;
  public cellPhone: string;
  public workPhone: string;
  public workPhoneExtension: string;
  public email: string;
}

class AddressType {
  constructor() {
    this.currentAddress = new AddressTypeDetail();
    this.formerAddress = [new AddressTypeDetail()];
    this.mailingAddress = new Address();
    this.hasSameMailingAddress = "true";
  }
  public currentAddress: AddressTypeDetail;
  public formerAddress: Array<AddressTypeDetail>;
  public mailingAddress: Address;
  public hasSameMailingAddress: string;
}

export class AddressTypeDetail {
  constructor() {
    this.address = new Address();
    this.duration = new Duration();
    this.housing = new Housing();
  }
  public address: Address;
  public duration: Duration;
  public housing: Housing;
}

class Duration {
  constructor() {
    this.years = null;
    this.months = null;
  }
  public days: string;
  public years: string;
  public months: string;
}
class Housing {
  constructor() {
    this.type = null;
    this.rentPerMonth = 0;
  }
  public type: object;
  public rentPerMonth: number;
  public isWithFamily: string;
  public planForPrimaryResidence: string;
}

class MilitaryService {
  constructor() {
    this.armedService = new AppliedService();
    this.servedOrServing = "false";
  }
  public servedOrServing: string;
  public armedService: AppliedService;
}

export class AppliedService {
  constructor() {
    this.expirationDate = new DateDuration();
    this.currentlyServing = false;
    this.currentlyRetired = false;
    this.reserveForce = false;
    this.survivingSpouse = false;
  }
  public currentlyServing: boolean;
  public currentlyRetired: boolean;
  public reserveForce: boolean;
  public survivingSpouse: boolean;
  public expirationDate: DateDuration;
}

class LanguagePreference {
  constructor() {
    this.language = null;
    this.description = null;
  }
  public language: string;
  public description: string;
}

export class CurrentEmployment {
  constructor() {
    this.address = new Address();
    this.workingPeriod = new Duration();
    this.startDate = new DateDuration();
    this.selfEmployedIndicator = new SelfEmployedIndicator();
    this.grossMonthlyIncome = new GrossMonthlyIncome();
    this.employerName = null;
    this.phone = null;
    this.position = null;
    this.relationShipIndicator = "false";
    this.classificationType = null;
    this.foreignIncomeIndicator = null,
    this.seasonalIncomeIndicator = null,
    this.refusedInformation = false;
    this.currentEmployment = null;
    this.industry = null;
    this.isRetired = false;
    this.isNotEmployed = false;
    this.militaryIndicator = null;
  }
  public employerName: string;
  public isRetired: boolean;
  public isNotEmployed: boolean;
  public phone: string;
  public address: Address;
  public position: string;
  public startDate: DateDuration;
  public workingPeriod: Duration;
  public relationShipIndicator: string;
  public classificationType: string;
  public foreignIncomeIndicator: Boolean;
  public seasonalIncomeIndicator: Boolean;
  public selfEmployedIndicator: SelfEmployedIndicator;
  public grossMonthlyIncome: GrossMonthlyIncome;
  public refusedInformation: boolean;
  public currentEmployment: String;
  public industry: String;
  public militaryIndicator: boolean;
}

export class DateDuration {
  constructor(){
    this.day = null;
    this.month = null;
    this.year = null;
  }
  day: number;
  year: number;
  month: number;
}

class SelfEmployedIndicator {
  constructor() {
    this.isSelfEmployed = false;
    this.lessThanTwentyFive = false;
  }
  public isSelfEmployed: boolean;
  public lessThanTwentyFive: boolean;
  public monthlyIncome: number;
}

export class GrossMonthlyIncome {
  constructor() {
    this.base = new base();
    this.overtime = new overtime();
    this.bonus = new bonus();
    this.commission = new commission();
    this.militaryEntitlements = new militaryEntitlements();
    this.dividends = new dividends();;
    this.other = new other();
    this.netRentalIncome = new netRentalIncome();
    this.contractBasis = new Generic('contractBasis');
    this.militaryBasePay = new Generic('militaryBasePay');
    this.militaryClothesAllowance = new Generic('militaryClothesAllowance');
    this.militaryCombatPay = new Generic('militaryCombatPay');
    this.militaryFlightPay = new Generic('militaryFlightPay');
    this.militaryHazardPay = new Generic('militaryHazardPay');
    this.militaryOverseasPay = new Generic('militaryOverseasPay');
    this.militaryPropPay = new Generic('militaryPropPay');
    this.militaryQuartersAllowance = new Generic('militaryQuartersAllowance');
    this.militaryRationsAllowance = new Generic('militaryRationsAllowance');
    this.militaryVariableHousingAllowance = new Generic('militaryVariableHousingAllowance');
  }
  public base: base;
  public overtime: overtime;
  public bonus: bonus;
  public commission: commission;
  public militaryEntitlements: militaryEntitlements;
  public dividends: dividends;
  public other: other;
  public netRentalIncome: netRentalIncome;
  public contractBasis: Generic;
  public militaryBasePay: Generic;
  public militaryClothesAllowance: Generic;
  public militaryCombatPay: Generic;
  public militaryFlightPay: Generic;
  public militaryHazardPay: Generic;
  public militaryOverseasPay: Generic;
  public militaryPropPay: Generic;
  public militaryQuartersAllowance: Generic;
  public militaryRationsAllowance: Generic;
  public militaryVariableHousingAllowance: Generic;
}

class Generic {
  constructor(type) {
    this.name = type;
    this.ediValue = null;
    this.mismoValue = type;
    this.value = type;
    this.monthlyIncome = 0;
  }
  public name: string;
  public ediValue: string;
  public mismoValue: string;
  public value: string;
  public monthlyIncome: number;
}
class base {
  constructor() {
    this.name = "base";
    this.ediValue = "20";
    this.mismoValue = "Base";
    this.value = "base";
    this.monthlyIncome = 0;
  }
  public name: string;
  public ediValue: string;
  public mismoValue: string;
  public value: string;
  public monthlyIncome: number;
}

class overtime {
  constructor() {
    this.name = "Over Time";
    this.ediValue = "09";
    this.mismoValue = "Overtime";
    this.value = "Over Time";
    this.monthlyIncome = 0;
  }
  public name: string;
  public ediValue: string;
  public mismoValue: string;
  public value: string;
  public monthlyIncome: number;
}

class bonus {
  constructor() {
    this.name = "Bonus";
    this.ediValue = "08";
    this.mismoValue = "Bonus";
    this.value = "Bonus";
    this.monthlyIncome = 0;
  }
  public name: string;
  public ediValue: string;
  public mismoValue: string;
  public value: string;
  public monthlyIncome: number;
}

class commission {
  constructor() {
    this.name = "Commission";
    this.ediValue = "10";
    this.mismoValue = "Commissions";
    this.value = "Commissions";
    this.monthlyIncome = 0;
  }
  public name: string;
  public ediValue: string;
  public mismoValue: string;
  public value: string;
  public monthlyIncome: number;
}

class netRentalIncome {
  constructor() {
    this.name = "Net Rental Income";
    this.ediValue = "33";
    this.mismoValue = "NetRentalIncome";
    this.value = "Net Rental Income";
    this.monthlyIncome = 0;
  }
  public name: string;
  public ediValue: string;
  public mismoValue: string;
  public value: string;
  public monthlyIncome: number;
}

class dividends {
  constructor() {
    this.name = "Dividends";
    this.ediValue = "17";
    this.mismoValue = "Dividends";
    this.value = "Dividends";
    this.monthlyIncome = 0;
  }
  public name: string;
  public ediValue: string;
  public mismoValue: string;
  public value: string;
  public monthlyIncome: number;
}

class other {
  constructor() {
    this.name = "Others";
    this.ediValue = "45";
    this.mismoValue = "Others";
    this.value = "Others";
    this.monthlyIncome = 0;
  }
  public name: string;
  public ediValue: string;
  public mismoValue: string;
  public value: string;
  public monthlyIncome: number;
}

class militaryEntitlements {
  constructor() {
    this.name = "Military Entitlement";
    this.ediValue = "F1";
    this.mismoValue = "MilitaryEntitlement";
    this.value = "Military Entitlement";
    this.monthlyIncome = 0;
  }
  public name: string;
  public ediValue: string;
  public mismoValue: string;
  public value: string;
  public monthlyIncome: number;
}

export class PreviousEmployment {
  constructor() {
    this.address = new Address();
    this.startDate = new DateDuration();
    this.endDate = new DateDuration();
    this.employerName = null;
    this.phone = null;
    this.position = null;
    this.businessPhone = null;
    this.isSelfEmployed = false;
    this.refusedInformation = false;
    this.currentEmployment = null;
    this.industry = null;
    this.selfEmployedIndicator = {
      selfEmployedType: null,
    };
  }
  public employerName: string;
  public phone: string;
  public address: Address;
  public position: string;
  public businessPhone: string;
  public startDate: DateDuration;
  public endDate: DateDuration;
  public isSelfEmployed: boolean;
  public previousGrossMonthlyIncome: number;
  public refusedInformation: boolean;
  public currentEmployment: String;
  public industry: String;
  public selfEmployedIndicator: object;
}

class IncomeFromOtherSources {
  constructor() {
    this.incomeSource = [
      new IncomeSource()
    ];
  }
  public incomeSource: Array<IncomeSource>;
}

export class IncomeSource {
  constructor() {
    this.name = null;
  }
  public name: object;
  public monthlyIncome: number;
}
