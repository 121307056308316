import posRoutes from './pos.routes';
import commonRoutes from './common.routes';
import stores from '@/store/store';
const store:any  = stores;
import { getCurrentUserTypePath } from "./broker.routes";

export const beforeEachEnter = async(to, from, next) => {
    if (store.state.sessionObject.type == "Admin Staff") {
        next()
    } else {
        let path = await getCurrentUserTypePath();
        next(path)
    }
};

export default [
    {
        path: "/admin-staff",
        name: "AdminStaff",
        component: () => import(/* webpackChunkName: "admin-staff" */'@/components/broker/Broker.vue'),
        redirect: { name: 'AdminStaffDashBoard' },
        meta: {
            requiresAuth: true,
            title: "Dashboard"
        },
        beforeEnter: beforeEachEnter,
        children: [
            {
                path: "dashboard",
                name: "AdminStaffDashBoard",
                component: () => import(/* webpackChunkName: "admin-staff-dashboard" */'@/components/broker/dashboard/Dashboard.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Dashboard"
                },
            },
            {
                path: 'borrower-contact',
                name: 'AdminStaffBorrowerContact',
                component: () => import(/* webpackChunkName: "borrower-contact" */'@/views/borrower-contact/BorrowerContactForm.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Borrower Contact"
                }
            },
            ...posRoutes('AdminStaff'),
            ...commonRoutes('AdminStaff')
        ]
    },
]