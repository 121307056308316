import posRoutes from './pos.routes';
import commonRoutes from './common.routes';
import stores from '@/store/store';
const store:any  = stores;
import { getCurrentUserTypePath } from "./broker.routes";

export const beforeEachEnter = async(to, from, next) => {
    if (store.state.sessionObject.type == "MLO") {
        next()
    } else {
        let path = await getCurrentUserTypePath();
        next(path)
    }
};
export default [
    {
        path: "/mlo",
        name: "MLO",
        component: () => import(/* webpackChunkName: "mlo" */'@/components/broker/Broker.vue'),
        redirect: { name: 'MLODashBoard' },
        meta: {
            requiresAuth: true,
            title: "Dashboard"
        },
        beforeEnter: beforeEachEnter,
        children: [
            {
                path: "dashboard",
                name: "MLODashBoard",
                component: () => import(/* webpackChunkName: "mlo-dashboard" */'@/components/broker/dashboard/Dashboard.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Dashboard"
                },
            },
            {
                path: 'mlo-pre-requisites',
                name: 'MLOPrerequisites',
                component: () => import(/* webpackChunkName: "pre-requisites" */'@/components/broker/pre-requisites/Prerequisites.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Prerequisites"
                }
            },
            {
                path: "disclosures",
                name: "MLODisclosures",
                component: () => import(/* webpackChunkName: "broker-disclosure" */'@/components/broker/disclosures/Disclosures.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Disclosures"
                }
            },
            {
                path: 'borrower-contact',
                name: 'MLOBorrowerContact',
                component: () => import(/* webpackChunkName: "borrower-contact" */'@/views/borrower-contact/BorrowerContactForm.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Borrower Contact"
                }
            },
            ...posRoutes('MLO'),
            ...commonRoutes('MLO')
        ]
    },
]