export default (namePrefix: string) => [

    {
        path: 'manage-vendors',
        name: namePrefix + 'ManageVendors',
        component: () => import(/* webpackChunkName: "manage-vendors" */'@/components/broker/manage-vendors/ManageVendors.vue'),
        meta: {
            requiresAuth: true,
            title: "Manage Vendors"
        }
    },
    {
        path: 'manage-users',
        name: namePrefix + 'ManageUsers',
        component: () => import(/* webpackChunkName: "manage-users" */'@/components/broker/manage-users/ManageUsers.vue'),
        meta: {
            requiresAuth: true,
            title: "Manage Users"
        }
    },
    {
        path: "new-loan",
        name: namePrefix + "NewLoan",
        component: () => import(/* webpackChunkName: "new-loan" */'@/components/broker/new-loan/StartNewLoan.vue'),
        meta: {
            requiresAuth: true,
            title: "New Loan"
        }
    },
    {
        path: "pipeline",
        name: namePrefix + "Pipeline",
        component: () => import(/* webpackChunkName: "pipeline" */'@/components/broker/pipeline/Pipeline.vue'),
        meta: {
            requiresAuth: true,
            title: "Pipeline"
        }
    },
    {
        path: "borrower-qualifier",
        name: namePrefix + "BorrowerQualifier",
        component: () => import(/* webpackChunkName: "borrower-qualifier" */'@/components/broker/borrower-qualifier/BorrowerQualifier.vue'),
        meta: {
            requiresAuth: true,
            title: "Borrower Qualifier"
        }
    },
    {
        path: "closing-schedule",
        name: namePrefix + "ClosingSchedule",
        component: () => import(/* webpackChunkName: "closing-schedule" */'@/components/broker/closing-schedule/ClosingSchedule.vue'),
        meta: {
            requiresAuth: true,
            title: "Closing Schedule"
        }
    },
    {
        path: "lender-relations",
        name: namePrefix + "LenderRelations",
        component: () => import(/* webpackChunkName: "lender-relations" */'@/components/broker/lender-relations/LenderRelations.vue'),
        meta: {
            requiresAuth: true,
            title: "Lender Relations"
        }
    },
    {
        path: 'my-account',
        name: namePrefix + 'MyAccount',
        component: () => import(/* webpackChunkName: "broker-account" */'@/components/broker/my-account/MyAccount.vue'),
        meta: {
            requiresAuth: true,
            title: "My Account"
        }
    },
    {
        path: 'my-wemlo-account',
        name: namePrefix + 'Myaccount',
        component: () => import(/* webpackChunkName: "My-wemlo-account" */'@/components/broker/My-wemlo-account/Mywemloaccount.vue'),
        meta: {
            requiresAuth: true,
            title: "My account"
        }
    },
    {
        path: "borrower-application",
        name: namePrefix + "BorrowerApplication",
        component: () => import(/* webpackChunkName: "borrower-application" */'@/components/broker/borrower-application/BorrowerApplication.vue'),
        meta: {
            requiresAuth: true,
            title: "Borrower Application"
        }
    },
    {
        path: "compliance",
        name: namePrefix + "Compliance",
        component: () => import(/* webpackChunkName: "compliance" */'@/components/broker/compliance/Compliance.vue'),
        meta: {
            requiresAuth: true,
            title: "Compliance"
        }
    },
    {
        path: "compliance-package",
        name: namePrefix + "CompliancePackage",
        component: () => import(/* webpackChunkName: "compliance" */'@/components/broker/compliance-package/CompliancePackage.vue'),
        meta: {
            requiresAuth: true,
            title: "Compliance"
        }
    },
    {
        path: "edit-compliance-package",
        name: namePrefix + "EditCompliancePackage",
        component: () => import(/* webpackChunkName: "compliance" */'@/components/broker/edit-compliance-package/EditCompliancePackage.vue'),
        meta: {
            requiresAuth: true,
            title: "Edit Compliance"
        }
    },
    {
        path: "scenario-desk",
        name: namePrefix + "ScenarioDesk",
        component: () => import(/* webpackChunkName: "scenario-desk" */'@/components/broker/scenario-desk/ScenarioDesk.vue'),
        meta: {
            requiresAuth: true,
            title: "Scenario Desk"
        }
    },
    {
        path: "borrower-list",
        name: namePrefix + "BorrowerList",
        component: () => import(/* webpackChunkName: "broker-borrower-list" */'@/views/borrower-list/BorrowerList.vue'),
        meta: {
            requiresAuth: true,
            title: "BorrowerList"
        }
    },
    {
        path: "ppe-results",
        name: namePrefix + "PpeResults",
        component: () => import(/* webpackChunkName: "ppp-result" */'@/components/broker/ppe-results/PpeResults.vue'),
        meta: {
            requiresAuth: true,
            title: "PpeResults"
        }
    },
    {
        path: 'notification',
        name: namePrefix + 'Notifications',
        component: () => import(/* webpackChunkName: "superadmin-notification" */'@/components/super-admin/notification/notification.vue'),
        meta: {
            requiresAuth: true,
            title: "Notification"
        }
    },
    {
        path: "lender-conversation",
        name: namePrefix + "LenderConversation",
        component: () => import(/* webpackChunkName: "lender-conversation" */'@/components/broker/lender-conversation/LenderConversation.vue'),
        meta: {
            requiresAuth: true,
            title: "Lender conversation "
        }
    },
    {
        path: "scenario-desk-message-list",
        name: namePrefix + "ScenarioDeskMessageList",
        component: () => import(/* webpackChunkName: "scenario-desk-message-list" */'@/components/broker/scenario-desk-message-list/ScenarioDeskMessageList.vue'),
        meta: {
            requiresAuth: true,
            title: "Scenario Desk "
        }
    },
    {
        path: 'we-help',
        name: namePrefix + 'WeHelp',
        component: () => import(/* webpackChunkName: "we-help" */'@/components/broker/we-help/WeHelp.vue'),
        meta: {
            requiresAuth: true,
            title: "WeHelp"
        }
    },
    {
        path: "ThankyouForMessage",
        name: namePrefix + "ThankyouForMessage",
        component: () => import(/* webpackChunkName: "thankyou-for-message" */'@/views/thankyouForMessage/ThankyouForMessage.vue'),
        meta: {
            requiresAuth: true,
            title: "ThankYou For Message "
        }
    },
    {
        path: 'we-help-list',
        name: namePrefix + 'We Help List',
        component: () => import(/* webpackChunkName: "we-help-list" */'@/components/broker/we-help-list/WeHelpList.vue'),
        meta: {
            requiresAuth: true,
            title: "WeHelpList"
        }
    },
    {
        path: "digital-mortgage",
        name: namePrefix + "DigitalMortgage",
        component: () => import(/* webpackChunkName: "new-loan" */'@/components/broker/new-loan/digital-mortgage/Digital-Mortgage.vue'),
        meta: {
            requiresAuth: true,
            title: "New Loan"
        }
    },
    {
        path: "import-3.2",
        name: namePrefix + "Import-3.2",
        component: () => import(/* webpackChunkName: "new-loan" */'@/components/broker/new-loan/import-3.2/Import3.2.vue'),
        meta: {
            requiresAuth: true,
            title: "Import-3.2"
        }
    },
    {
        path: "import-3.4",
        name: namePrefix + "Import-3.4",
        component: () => import(/* webpackChunkName: "new-loan" */'@/components/broker/new-loan/import-3.4/Import3.4.vue'),
        meta: {
            requiresAuth: true,
            title: "Import-3.4"
        }
    },
    {
        path: 'compare-borrowerInfo',
        name: namePrefix + "Compare-BorrowerInfo",
        component: () => import(/* webpackChunkName: "add-borrower" */'@/components/broker/new-loan/compare-borrowerInfo/CompareBorrowerInfo.vue'),
        meta: {
            requiresAuth: true,
            title: "Compare-BorrowerInfo"
        }
    },
    {
        path: "add-fnm-borrowerInfo",
        name: namePrefix + "Add-Fnm-BorrowerInfo",
        component: () => import(/* webpackChunkName: "new-loan" */'@/components/broker/new-loan/add-fnm-borrowerInfo/AddFnmBorrowerInfo.vue'),
        meta: {
            requiresAuth: true,
            title: "Add-Fnm-BorrowerInfo"
        }
    },
    {
        path: "add-mismo-borrowerInfo",
        name: namePrefix + "Add-Mismo-BorrowerInfo",
        component: () => import(/* webpackChunkName: "new-loan" */'@/components/broker/new-loan/add-mismo-borrowerInfo/AddMismoBorrowerInfo.vue'),
        meta: {
            requiresAuth: true,
            title: "Add-Mismo-BorrowerInfo"
        }
    },
    {
        path: "submission-form",
        name: namePrefix + "Submission Form",
        component: () => import(/* webpackChunkName: "new-loan" */'@/components/broker/new-loan/submission-form-new/SubmissionFormNew.vue'),
        meta: {
            requiresAuth: true,
            title: "Submission Form"
        }
    },
    {
        path: "pricing",
        name: namePrefix + "Pricing",
        component: () => import(/* webpackChunkName: "new-loan" */'@/components/broker/new-loan/pricing/Pricing.vue'),
        meta: {
            requiresAuth: true,
            title: "Pricing"
        }
    },
    {
        path: 'add-borrower-info',
        name: namePrefix + "Add-Borrower-Info",
        component: () => import(/* webpackChunkName: "add-borrower" */'@/components/broker//new-loan/add-borrower/AddBorrower.vue'),
        meta: {
            requiresAuth: true,
            title: "AddBorrower"
        }
    },

    {
        path: 'pre-qualification',
        name: namePrefix + "Pre-Qualification",
        component: () => import(/* webpackChunkName: "pre-qualification" */'@/components/broker/new-loan/pre-qualification/PreQualification.vue'),
        meta: {
            requiresAuth: true,
            title: "Pre Qualification"
        }
    },
    {
        path: 'apply-newloan',
        name: namePrefix + "Apply New Loan",
        component: () => import(/* webpackChunkName: "apply-newloan" */'@/components/broker/new-loan/apply-newloan/ApplyNewLoan.vue'),
        meta: {
            requiresAuth: true,
            title: "Apply New Loan"
        }
    },
    {
        path: 'broker-loan-documents',
        name: namePrefix + "Broker Loan Documents",
        component: () => import(/* webpackChunkName: "broker-loan-documents" */'@/components/broker/broker-loan-documents/LoanDocuments.vue'),
        meta: {
            requiresAuth: true,
            title: "Broker Loan Documents"
        }
    },
    {
        path: 'customer-retention',
        name: namePrefix + 'Customer Retention',
        component: () => import(/* webpackChunkName: "customer-retention" */'@/components/customer-retention/CustomerRetention.vue'),
        meta: {
            requiresAuth: true,
            title: "CustomerRetention"
        }
    },
    {
        path: "blank-backGround-popUp",
        name: namePrefix + "Blank BackGround Popup",
        component: () => import(/* webpackChunkName: "blank-backGround-popUp" */'@/views/BlankBackGroundPopup.vue'),
        meta: {
            requiresAuth: true,
            title: "Blank BackGround Popup"
        }
    },
    {
        path: "borrower-welcome",
        name: namePrefix + "BorrowerWelcome",
        component: () => import(/* webpackChunkName: "borrower-welcome" */'@/components/borrower/welcome/Welcome.vue'),
        meta: {
            requiresAuth: true,
            canAccess: [],
            title: "Borrower Welcome"
        }
    },
    {
        path: 'borrower-detail',
        name: namePrefix + 'Borrower Detail',
        component: () => import(/* webpackChunkName: "borrower-detail" */'@/components/super-admin/borrower-detail/BorrowerDetail.vue'),
        meta: {
            requiresAuth: true,
            title: "archivedBorrowerDetail"
        }
    },
   
    {
        path: 'list-processor-users',
        name: namePrefix + 'ProcessorListUsers',
        component: () => import(/* webpackChunkName: "group-superadmin-borrower-det" */'@/components/super-admin/manage-user/ManageUser.vue'),
        meta: {
            requiresAuth: true,
            title: "Processor List Users"
        }
    },
    {
        path: "manage-processor",
        name: namePrefix + "ManageProcessor",
        component: () => import(/* webpackChunkName: "manage-processor" */'@/components/broker/manage-processor/ManageProcessor.vue'),
        meta: {
            requiresAuth: true,
            title: "Manage Processor"
        }
    },
    {
        path: "manage-broker-processor",
        name: namePrefix + "ManageBrokerProcessor",
        component: () => import(/* webpackChunkName: "manage-broker-processor" */'@/components/broker/pre-requisites/manage-processor/ManageProcessor.vue'),
        meta: {
            requiresAuth: true,
            title: "Manage Broker Processor"
        }
    },
    {
        path: "processor-list",
        name: namePrefix + "ProcessorList",
        component: () => import(/* webpackChunkName: "manage-processor" */'@/components/broker/processor-list/ProcessorList.vue'),
        meta: {
            requiresAuth: true,
            title: "Processor List"
        }
    },

    {
        path: "we-learn",
        name: namePrefix + "WeLearn",
        component: () => import(/* webpackChunkName: "we-learn" */'@/views/we-learn/WeLearn.vue'),
        meta: {
            requiresAuth: true,
            title: "Processor List"
        }
    },
    {
        path: 'processor-report',
        name: namePrefix + 'Processor Report',
        component: () => import(/* webpackChunkName: "management" */'@/components/super-admin/processor-reports/ProcessorReport.vue'),
        meta: {
            requiresAuth: true,
            title: "processorreport"
        }
    },
]