import {
  initialState,
  urlaProps,
  posProperties,
  posSessionConfig
} from "./store";
import _ from "lodash";
import {ON_BOARDING_STEPS} from "@/constants";

// import { PendingQuestionList, PendingSectionList } from "@/models/common.model";

const SET_LOGIN_SESSION = (state: any, loginResponse: any) => {
  state.sessionObject = loginResponse;
};

const SET_LOANSIFTERID = (state: any, data: any) => {
  state.loanSifterId = data;
};

const CLEAR_SESSION = function (state: any) {

  localStorage.removeItem('rememberMeToken');

  const initial: any = initialState();

  state['isIdentityAssumedMode'] = undefined;
  state['copySuperAdminSessionObject'] = undefined;
  state['userInfoForIdentityAssumedMode'] = undefined;
  state['digitalSignature'] = undefined;

  Object.keys(initial).forEach(key => {
    state[key] = initial[key];
  });
};

const LOGOUT = () => {
  localStorage.setItem('logout-event', `user_logged_out${Math.random()}`);
  localStorage.removeItem('email');
  localStorage.removeItem('password');
  localStorage.removeItem('rememberMeToken');
};

const UPDATE_PIPELINE_FILTERS = function(state: any, data:any ) {
  state.pipelineFilters = Object.assign({}, state.pipelineFilters, data);
}

const UPDATE_SESSION_DATA = function (state: any, data: any) {
  state.sessionObject["userInfo"] = data;
};

const UPDATE_SESSION_USER_TYPE = function (state: any, data: string) {
  state.sessionObject["type"] = data;
};
const UPDATE_SESSION_USER_ID = function (state: any, data: string) {
  state.sessionObject["userId"] = data;
};

const UPDATE_CONSENT_TO_CONTACT = function (state: any, data: boolean) {
  state.sessionObject.pendingActions["consentToContact"] = data;
};

const UPDATE_E_DISCLOSURE_AGREEMENT = function (state: any, data: boolean) {
  state.sessionObject.pendingActions["eDisclosureAgreement"] = data;
};

const UPDATE_SESSION_STATES = function (state: any, data: any) {
  state.sessionObject["states"] = data;
};

const UPDATE_PRE_REQUISITE = function (state: any, data: any) {
  let step = ON_BOARDING_STEPS.find(step => step.stepId == data);
  let stepId = '';
  if(step && step.stepId){
    stepId = step.stepId
  }
  state.sessionObject.userInfo.completedPreRequisites = stepId || data;
  state.sessionObject.userInfo.completedPreRequisitesId = stepId || data;
};

const REMOVE_STATUS_OF_VERIFIED_TOKEN = function (state: any) {
  if (
    state.sessionObject.hasOwnProperty("status") &&
    state.sessionObject.status == "tokenVerified"
  ) {
    delete state.sessionObject.activationToken;
    delete state.sessionObject.status;
  }
};

const SET_POS_ANSWERS = function (state: any, answers) {
  state.posBasicInfo = answers
};

const SET_QUESTIONNAIRE_HISTORY = function (state: any, response) {
  let data = null;
  if (response.questionnaireHistory) {
    data = {
      loanTxnId: response.questionnaireHistory.loanTxnId,
      ...response.questionnaireHistory.borrowerInfo.find(a=> a.borrowerId == response.borrowerId),
    }
    UPDATE_QUESTIONNAIRE_HISTORY(state, data);
  }
};

const UPDATE_QUESTIONNAIRE_HISTORY = function (state: any, data) {
  if (data)
    Object.keys(data).forEach(key => {
      if (
        state.posSessionConfig.hasOwnProperty(key) &&
        (data[key] != undefined && data[key] != null)
      ) {
        state.posSessionConfig[key] = data[key];
      }
    });
};

const SET_SIDE_NAV_LINKS = function (state: any, links: Array<any>) {
  state.navigationLinks = links;
};

const RESET_POS_DATA = function (state: any) {
  const urlaFormData: any = urlaProps();
  const questionnaireData: any = posProperties();
  if (state.sessionObject.type != "Borrower") {
    const posSessionConfigData: any = posSessionConfig();
    state.posSessionConfig = posSessionConfigData;
    state.questionnaireData = questionnaireData;
    state.haveUrlaFormDataValue = false;
    state.urlaFormData = urlaFormData;
  }
};

const SET_PROFILE_PICTURE = function (state: any, obj: any) {
  state.sessionObject.userInfo.profilePicture = obj.path;
  state.profilePictures.cordinates = obj.data.cordinates;
  state.profilePictures.thumb = obj.data.thumb;
  state.profilePictures.small = obj.data.small;
  state.profilePictures.status = true;
};

const STATUS_OF_PROFILE_PICTURE = function (state: any, data) {
  state.profilePictures = data;
}

const SET_IMAGE_SMALL = function( state: any, data: String){
  state.profilePictures.small = data;
}

const SET_PIPELINE_DATA = function (state, data) {
  state.pipeline.allData = data;
};

const SET_PIPELINE_SEARCH_DATA = function (state, data) {
  state.pipeline.searchData = data;
};

const SET_TWILIO_TOKEN = function (state, data) {
  state.twilioToken = data;
};

const SET_TWILIO_ACTIVE_STATUS = function (state: any, data: any) {
  state.twilioActiveStatus = data;
};

const UPDATE_ACCESS_RIGHT = function (state: any, userAccess: any) {
  state.sessionObject.access = userAccess;
};

const REMOVE_LOCAL_FILES = function (state: any) {
  state.removeLocalFiles = [];
};

const SET_LOCAL_FILES = function (state: any, data: any) {
  data.forEach(file => {
    state.removeLocalFiles.push(file);
  });
};

const SET_FNM_URL = function(state: any, data: any){
  state.uploadFnmUrl=data;
}

const SET_WEMLO_LOADER = (state: any, val: boolean) => {
  state.wemloLoader = val;
}

const SET_SESSION_TOKEN = (state: any, val: string) => {
  state.sessionObject.token = val;
}

const SET_ROUTE_HISTORY = (state: any, val: any) => {
  state.routeHistory = val;
}

const SET_IDENTITY_ASSUMED_MODE = (state: any, val: any) => {
    state.isIdentityAssumedMode = val;
}

const SET_COPY_SUPER_ADMIN_SESSION_OBJECT = (state: any, data: any) => {
    state.copySuperAdminSessionObject = data;
}

const SET_USER_INFO_FOR_IDENTITY_ASSUMED_MODE = (state: any, data: any) => {
    state.userInfoForIdentityAssumedMode = data;
}


export default {
  SET_LOGIN_SESSION,
  CLEAR_SESSION,
  LOGOUT,
  UPDATE_PIPELINE_FILTERS,
  UPDATE_SESSION_DATA,
  UPDATE_CONSENT_TO_CONTACT,
  UPDATE_E_DISCLOSURE_AGREEMENT,
  UPDATE_SESSION_STATES,
  UPDATE_PRE_REQUISITE,
  REMOVE_STATUS_OF_VERIFIED_TOKEN,
  SET_POS_ANSWERS,
  SET_QUESTIONNAIRE_HISTORY,
  UPDATE_QUESTIONNAIRE_HISTORY,
  SET_SIDE_NAV_LINKS,
  RESET_POS_DATA,
  SET_PROFILE_PICTURE,
  STATUS_OF_PROFILE_PICTURE,
  SET_IMAGE_SMALL,
  SET_PIPELINE_DATA,
  SET_PIPELINE_SEARCH_DATA,
  SET_LOANSIFTERID,
  SET_TWILIO_TOKEN,
  SET_TWILIO_ACTIVE_STATUS,
  UPDATE_ACCESS_RIGHT,
  REMOVE_LOCAL_FILES,
  SET_LOCAL_FILES,
  SET_FNM_URL,
  SET_WEMLO_LOADER,
  SET_SESSION_TOKEN,
  SET_ROUTE_HISTORY,
  UPDATE_SESSION_USER_TYPE,
  UPDATE_SESSION_USER_ID,
  SET_IDENTITY_ASSUMED_MODE,
  SET_COPY_SUPER_ADMIN_SESSION_OBJECT,
  SET_USER_INFO_FOR_IDENTITY_ASSUMED_MODE
};
