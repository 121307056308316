export class Address {
    constructor() {
        this.designatorType = null;
        this.street = null;
        this.unit = null;
        this.city = null;
        this.state = null;
        this.zip = null;
        this.county = null;
        this.country = null;
    }
    public designatorType: string;
    public street: string;
    public unit: string;
    public city: string;
    public state: object;
    public zip: string;
    public county: string;
    public country: string;
}

export class DonorDetail {
    constructor() {
        this.name = null;
        this.phone = null;
        this.bank = null;
        this.accountNumber = null;
        this.address = new Address();
    }
    public name: string;
    public phone: string;
    public address: Address;
    public bank: string;
    public accountNumber: object;
}

export class Name {
    constructor() {
        this.firstName = null;
        this.middleName = null;
        this.lastName = null;
        this.nameSuffix = null;
        this.accountNumber = null;
        this.creditorName = null;
    }
    public firstName: string;
    public middleName: string;
    public lastName: string;
    public nameSuffix: string;
    public creditorName: string;
    public accountNumber: string;
}

export class MonthYearDate {
    constructor() {
        this.month = null;
        this.year = null;
    }
    public month: string;
    public year: string
}

export class CompleteDate {
    constructor() {
        this.date = null;
        this.month = null;
        this.year = null;
    }
    public date: string;
    public month: string;
    public year: string;
}