
export class BrokerInformation {
    constructor(){
        this.address = new Address();
    }
    public userId: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: number;
    public address: Address;
    public companyName: string;
    public NMLSNumber: string;
    public individualNMLSNumber: string;
    public eni: string;
    public createdOn: Date;
    public stateLicenseId: number;
    public token: string;
}

class Address {
    constructor() {
        this.street = '';
        this.unit = '';
        this.city = new CityAndState();
        this.state = new CityAndState();
        this.zip = '';
    }
    public street: string;
    public unit: string;
    public city: CityAndState;
    public state: CityAndState;
    public zip: string;
}

export class CityAndState {
    constructor(){
        this.name = '';
        this.code = '';
    }
    public name: string;
    public code: string;
}