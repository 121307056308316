export class DemographicInformation {
    constructor() {
        this.borrowerInfo = [new DemographicDetails()];
        // this.coBorrowerInfo = [new DemographicDetails()];   Not used in Sprint 5
    }
    public loanTxnId: string;
    public borrowerInfo: Array<DemographicDetails>;
    // public coBorrowerInfo: Array<DemographicDetails>;    Not used in Sprint 5
}

export class DemographicDetails {
    constructor() {
        this.ethnicity = { name: null, ediValue: null, mismoValue: null };
        this.race = { name: null, ediValue: null, mismoValue: null };
        this.basedOnSurname = new BasedOnSurname();
    }
    public ethnicity: object;
    public race: object;
    public gender: object;
    public basedOnSurname: BasedOnSurname;
    public informationProvidedThrough: object;
    public ethnicities: Array<EthnicityData>;
    public races: Array<RaceData>;
}

export class EthnicityData {
    constructor() {
        this.ethnicity = { name: null, ediValue: null, mismoValue: null };
        this.subtypes = [];
    }
    public ethnicity: object;
    public subtypes: Array<SubType>;
}

export class RaceData {
    constructor() {
        this.race = { name: null, ediValue: null, mismoValue: null };
        this.subtypes = [];
    }
    public race: object;
    public subtypes: Array<SubType>;
}

export class SubType {
    constructor() {
        this.name = null;
        this.description = null;
    }
    public name: string;
    public description: string;
}

class BasedOnSurname {
    public ethnicity: boolean;
    public race: boolean;
    public gender: boolean;
}

/**
 *   Only used outside model
 */
export class Ethnicity {
    constructor() {
        this.hispanicOrLatino = new HispanicOrLatino();
        this.notHispanicOrLatino = new notHispanicOrLatino();
        this.informationNotProvidedForEthinicity = new informationNotProvidedForEthinicity();
    }
    public hispanicOrLatino: HispanicOrLatino;
    public notHispanicOrLatino: notHispanicOrLatino;
    public informationNotProvidedForEthinicity: informationNotProvidedForEthinicity;
}

export class HispanicOrLatino {
    constructor() {
        this.isHispanicOrLatino = new isHispanicOrLatino();
        this.mexican = false;
        this.puertoRican = false;
        this.cuban = false;
        this.other = false;
        this.description = null;
    }
    public isHispanicOrLatino: isHispanicOrLatino;
    public mexican: boolean;
    public puertoRican: boolean;
    public cuban: boolean;
    public other: boolean;
    public description: string;
}

class notHispanicOrLatino {
    constructor() {
        this.name = "Not Hispanic Or Latino";
        this.ediValue = "2";
        this.mismoValue = "NotHispanicOrLatino";
    }
    public name: string;
    public ediValue: string;
    public mismoValue: string;
}

class isHispanicOrLatino {
    constructor() {
        this.name = "Hispanic Or Latino";
        this.ediValue = "1";
        this.mismoValue = "HispanicOrLatino";
    }
    public name: string;
    public ediValue: string;
    public mismoValue: string;
}

class refusedInformationForEthinicity {
    constructor() {
        this.name = "Not Applicable";
        this.ediValue = "4";
        this.mismoValue = "NotApplicable";
    }
    public name: string;
    public ediValue: string;
    public mismoValue: string;
}
class informationNotProvidedForEthinicity {
    constructor() {
        this.name = "Information not provided by applicant mail internet or telephone application";
        this.ediValue = "3";
        this.mismoValue = "InformationNotProvidedByApplicantMailInternetOrTelephoneApplication";
    }
    public name: string;
    public ediValue: string;
    public mismoValue: string;
}





class OtherInfo {
    constructor() {
        this.americanIndianOrAlaskaNative = new americanIndian();
    }
    public americanIndianOrAlaskaNative: americanIndian;
    public description: string;
}

export class Race {
    constructor() {
        this.americanIndian = new OtherInfo();
        this.nativeHawaiian = new NativeHawaiian();
        this.asian = new Asian();
        this.blackOrAfricanAmerican = new blackOrAfricanAmerican();
        this.white = new white();
        this.refusedInformation = new refusedInformationforRace();
        this.informationNotProvidedForRace = new informationNotProvidedForRace();
    }
    public americanIndian: OtherInfo;
    public asian: Asian;
    public blackOrAfricanAmerican: blackOrAfricanAmerican;
    public nativeHawaiian: NativeHawaiian;
    public white: white;
    public refusedInformation: refusedInformationforRace;
    public informationNotProvidedForRace: informationNotProvidedForRace;

}

export class Asian {
    constructor() {
        this.isAsian = new isAsian();
    }
    public isAsian: isAsian;
    public asianIndian: boolean;
    public chinese: boolean;
    public filipino: boolean;
    public japanese: boolean;
    public korean: boolean;
    public vietnamese: boolean;
    public other: boolean;
    public description: string;
}

export class NativeHawaiian {
    constructor() {
        this.isNativeHawaiianOrOtherPacific = new isNativeHawaiianOrOtherPacific();
    }
    public isNativeHawaiianOrOtherPacific: isNativeHawaiianOrOtherPacific;
    public nativeHawaiian: boolean;
    public guamanianOrChamorro: boolean;
    public samoan: boolean;
    public other: boolean;
    public description: string;
}

class americanIndian {
    constructor() {
        this.name = "American Indian Or Alaska Native",
        this.ediValue = "1",
        this.mismoValue = "AmericanIndianOrAlaskaNative"
        // this.value = false;

    } 
    public name: string;
    public ediValue: string;
    public mismoValue: string;
    // public value: boolean;
}

class isAsian {
    constructor() {
        this.name = "Asian",
            this.ediValue = "2",
            this.mismoValue = "Asian"
        // this.value = false;

    } public name: string;
    public ediValue: string;
    public mismoValue: string;
    // public value: boolean;
}

class blackOrAfricanAmerican {
    constructor() {
        this.name = "Black Or African American",
            this.ediValue = "3",
            this.mismoValue = "BlackOrAfricanAmerican"
        // this.value = false;

    } public name: string;
    public ediValue: string;
    public mismoValue: string;
    // public value: boolean;
}

class isNativeHawaiianOrOtherPacific {
    constructor() {
        this.name = "Native Hawaiian or Other Pacific Islander",
            this.ediValue = "4",
            this.mismoValue = "NativeHawaiianOrOtherPacificIslander"
        // this.value = false;

    } public name: string;
    public ediValue: string;
    public mismoValue: string;
    // public value: boolean;
}

class white {
    constructor() {
        this.name = "White",
            this.ediValue = "5",
            this.mismoValue = "White"
        // this.value = false;

    } public name: string;
    public ediValue: string;
    public mismoValue: string;
    // public value: boolean;
}

class informationNotProvidedForRace {
    constructor() {
        this.name = "Information not provided by applicant mail internet or telephone application";
        this.ediValue = "6";
        this.mismoValue = "InformationNotProvidedByApplicantMailInternetOrTelephoneApplication";
    }
    public name: string;
    public ediValue: string;
    public mismoValue: string;
}

class refusedInformationforRace {
    constructor() {
        this.name = "Not Applicable",
            this.ediValue = "7",
            this.mismoValue = "NotApplicable"
        // this.value = false;
    }
    public name: string;
    public ediValue: string;
    public mismoValue: string;
    // public value: boolean;
}

class Gender {
    constructor() {
        this.male = new male();
        this.female = new female();
        this.refusedInformation = new refusedInformationforGender();
    }
    public male: male;
    public female: female;
    public refusedInformation: refusedInformationforGender;
}
class refusedInformationforGender {
    constructor() {
        this.name = "Not Applicable",
        this.ediValue = "N",
        this.mismoValue = "Not Applicable"
        // this.value = false;
    }
    public name: string;
    public ediValue: string;
    public mismoValue: string;
    // public value: boolean
}


class male {
    constructor() {
        this.name = "Male",
        this.ediValue = "M",
        this.mismoValue = "Male"
        // this.value = false;
    }
    public name: string;
    public ediValue: string;
    public mismoValue: string;
    // public value: boolean
}

class female {
    constructor() {
        this.name = "Female",
        this.ediValue = "F",
        this.mismoValue = "Female"
        // this.value = false;
    }
    public name: string;
    public ediValue: string;
    public mismoValue: string;
    // public value: boolean
}

class informationNotProvidedForGender {
    constructor() {
        this.name = "Information not provided by applicant mail internet or telephone application";
        this.ediValue = "I";
        this.mismoValue = "InformationNotProvidedByApplicantMailInternetOrTelephoneApplication";
    }
    public name: string;
    public ediValue: string;
    public mismoValue: string;
}

