import Vue from 'vue';
import stores from '@/store/store';
const store: any = stores;
/**
 * Concatenate all the address fields to full address string.
 */
Vue.prototype.$concatAddressFields = (obj: any) => {
    let addrsString = '';
    let addressArray = []
    if (obj) {
        if(obj.street)
            addressArray.push(obj.street)
        if(obj.unit)
            addressArray.push(obj.unit)
        if(obj.city)
            obj.city.name ? addressArray.push(obj.city.name) : typeof obj.city == 'string' ? addressArray.push(obj.city) : '';
        if(obj.state && obj.state.name)
            addressArray.push(obj.state.name);
        if(obj.zip)
            addressArray.push(obj.zip);
        if(obj.county)
            addressArray.push(obj.county);
        /*************************** For future use **************************************** */
        // if(obj.country)
        //     addressArray.push(obj.country);

        if (addressArray.length > 0)
            addrsString = addressArray.join(', ')
    }
    return addrsString;
}

Vue.prototype.$random = (length: number, charset?: string) => {
    let text = "";
    let set = charset || "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
        text += set.charAt(Math.floor(Math.random() * set.length));
    }
    return text;
}

Vue.prototype.$getLoanStatus = (status: any[]) => {
    if (!status || typeof status == 'string') return;
    if (status.length > 0) { //status length must be greater than 0    
        let statusObj = status[status.length - 1].active ? status[status.length - 1] : status[status.length - 2];
        return statusObj ? statusObj.name : '';
    }
}

Vue.prototype.$getCurrentUserTypePath = (pathName: any) => {
    let type = store.state.sessionObject.type;
    if (type == 'Admin Staff') {
        return '/admin-staff/' + pathName;
    } else if (type == 'Wemlo Processor') {
        return '/processor/' + pathName;
    } else if (type == 'Super Admin') {
        return '/super-admin/' + pathName;
    } else if (type == 'Broker') {
        return '/broker/' + pathName;
    } else if (type == 'Borrower') {
        return '/borrower/' + pathName;
    } else if (type == 'MLO') {
        return '/mlo/' + pathName;
    }
}

Vue.prototype.$getDateDifference = (createdOn: any) => {
    var d1 = new Date(createdOn);
    var d2 = new Date();
    var timeDiff = d2.getTime() - d1.getTime();
    var DaysDiff = timeDiff / (1000 * 3600 * 24);
    if (DaysDiff > 1) {
        return Math.floor(DaysDiff) + " days ago";
    } else {
        let x = timeDiff / (1000 * 3600);
        if (x > 1) {
            return Math.floor(x) + " hours ago";
        } else {
            let y = timeDiff / (1000 * 60);
            if (y > 1) {
                return Math.floor(y) + " min ago";
            } else {
                return "moments ago";
            }
        }
    }
}



Vue.prototype.$getRouteBasedOnStatus = (borrower, userType) => {
    let query: any = {};
    let path = "";
    borrower.loanStatus.forEach((status) => {
        if (status.active) {
            if (status.statusId == 1 || status.statusId == 2) {
                path = userType == "Broker" ? "/broker/new-loan" : "/mlo/new-loan";
                query = {
                    id: borrower.loanTxnId,
                    edit: true
                }
            } else {
                if (status.statusId != 10 && status.statusId != 17 && status.statusId != 19) {
                    path = userType == "Broker" ? "/broker/pipeline" : "/mlo/pipeline";
                    query = { id: borrower.loanTxnId }
                }
            }
        }
    })
    return { path, query };
}



Vue.prototype.$truncate = (str, length, ending) => {
    if (str) {
        if (length == null) {
            length = 100;
        }
        if (ending == null) {
            ending = "...";
        }
        if (str.length > length) {
            return str.substring(0, length - ending.length) + ending;
        } else {
            return str;
        }
    } else return "";
}

