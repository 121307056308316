































































































































import { Component, Vue } from "vue-property-decorator";
import saveAs from "save-as";
import _ from "lodash";
import Axios from "axios";
import {BASE_API_URL, EventBus} from "@/config";

@Component
export default class ToSeeAttachmentPopup extends Vue {
  public deleteButton = false;
  public currentFileIndex = 0;
  public dotLoader: any = null;
  public fileToDisplay = null;
  public fileNameToDisplay = null;
  public fileType = null;
  public file: any = [];
  public messageAttachment: any = [];
  public showPreviousButton = false;
  public showNextButton = false;

  public handleBeforeOpen(e) {
    this.deleteButton = e.params.type === 'outgoing'
    this.file = e.params.file
    this.currentFileIndex = e.params.currentFileIndex
    this.messageAttachment = e.params.messageAttachment

    this.deleteButton ? this.toSeeReplyAttachments() : this.toSeeMessageAttachments();
  }

  public closePopup() {
    this.$modal.hide('toSeeAttachment')
    this.currentFileIndex = 0
  }

  /**************************************************************************************************
   *                                    IF DOCUMENT NOT LOADED THEN                                  *
   **************************************************************************************************/
  public ifDocumentNotLoad(time: number) {
    this.dotLoader = true;
    try {
      setTimeout(() => {
        let checkLoader = document.getElementById("checkLoader");
        if (checkLoader !== null) {
          let iframe: any = document.getElementById("checkElement");
          if (iframe.localName === "iframe") {
            iframe.src = `https://docs.google.com/gview?url=${this.fileToDisplay}&embedded=true`;
          } else {
            iframe.src = this.fileToDisplay;
          }
          this.ifDocumentNotLoad(time + 1000);
        }
      }, time);
    } catch (error) {
      console.log(error);
      this.dotLoader = false;
    }
  }

  /* For FileUpload */
  public isFileHasUploadError(fileName) {
    let fileInfo = this.file.find(f => f.name == fileName);
    if(!fileInfo) return;
    if(_.get(fileInfo, 'errorMessage')) return true;
  }

  public getFileUploadErrorMessage(fileName) {
    let fileInfo = this.file.find(f => f.name == fileName);
    return _.get(fileInfo, 'errorMessage', '');
  }

  public toSeeReplyAttachments() {
    this.file.forEach((element, i) => {
      if (this.currentFileIndex == i) {
        this.fileToDisplay = URL.createObjectURL(element);
        this.fileNameToDisplay = element.name;
        let file = element.name.split('.');
        this.fileType = file[file.length - 1];
      }
    });
    this.setupShowPrevNextButtons(this.file);
  }

  public setupShowPrevNextButtons(files) {
    if (files.length === 1) {
      this.showNextButton = false;
      this.showPreviousButton = false;
    } else if (this.currentFileIndex === 0) {
      this.showPreviousButton = false;
      this.showNextButton = true;
    } else if (this.currentFileIndex === files.length - 1) {
      this.showPreviousButton = true;
      this.showNextButton = false;
    } else {
      this.showPreviousButton = true;
      this.showNextButton = true;
    }
  }

  public toSeeMessageAttachments() {
    console.log('toSeeMessageAttachments')
    this.messageAttachment.forEach((file, i) => {
      if (this.currentFileIndex == i) {
        this.fileToDisplay = file.path;
        this.fileNameToDisplay = file.name;
        this.fileType = this.fileToDisplay
            .split('.')
            .pop()
            .toLowerCase();
      }
    });
    this.setupShowPrevNextButtons(this.messageAttachment);
  }

  public previewAttachment() {
    this.currentFileIndex -= 1
    this.deleteButton ? this.toSeeReplyAttachments() : this.toSeeMessageAttachments();
  }

  public nextAttachment() {
    this.currentFileIndex += 1
    this.deleteButton ? this.toSeeReplyAttachments() : this.toSeeMessageAttachments();
  }

  public clearAttachment() {
    const fileForDelete = this.file[this.currentFileIndex];
    EventBus.$emit('file-for-delete', fileForDelete)
    this.file.splice(this.currentFileIndex, 1);
    this.currentFileIndex = 0;
    if (this.file.length == 1) {
      this.showNextButton = false;
    }
    this.toSeeReplyAttachments()

    if (this.file.length == 0) {
      this.currentFileIndex = 0;
      this.$modal.hide("toSeeAttachment");
    }
  }

  public downloadFile(file) {
    file = this.file[this.currentFileIndex];
    saveAs(file, file.name);
  }

  private async loadDocument(path) {
    try {
      if (!path) return;
      let ext = path.split('.').pop();
      if (!['pdf', 'png','jpg', 'jpeg'].includes(ext)) return;
      let response = await Axios.post(BASE_API_URL + 'common/fetchDocument', { path: path });
      let dom = document.getElementById('checkElement');
      if (ext !== 'pdf') response.data = `data:image/${ext};base64,` + response.data;
      dom['src'] = response.data;
      dom.append();
    } catch (error) {
      console.log(error);
    }
  }
}
