import local from './env.local';
import development from './env.development';
import wesign from './env.wesign';
import mismo from './env.mismo';
import proof from './env.proof';
import runaus from './env.runaus';
import stage from './env.stage';
import production from './env.production';
import cms from './env.cms';
import calcoast from './env.calcoast';
import threec from './env.threec';
import blackoak from './env.blackoak';
import capbull from './env.capbull';
import motto from './env.motto';
import welearn from './env.welearn';
import newco from './env.newco';
import oktamotto from './env.oktamotto';

const ENVS = { local, development, wesign, mismo, proof, runaus, stage, production, cms, calcoast, threec, blackoak, capbull, motto, welearn, newco, oktamotto };

// export default ENVS['local'];
  export default ENVS['calcoast'];
