import Vue from 'vue';
import App from '@/App.vue';
import router from '@/routes/index';
import store from '@/store/store';
import VeeValidate from 'vee-validate';
import Snotify from 'vue-snotify'
import { setInstanceVariables, snotifyOptions } from '@/config';
import VModal from 'vue-js-modal';
import VueNumeric from 'vue-numeric';
import VueMask from 'v-mask';
import { Datetime } from 'vue-datetime';
import lodash from 'lodash';
import VPopover from 'vue-js-popover';
import Loading from 'vue-loading-overlay';
// import Vuebar from "vuebar";
import VuejsDialog from 'vuejs-dialog';
import VueMoment from 'vue-moment';
import InfiniteLoading from 'vue-infinite-loading';
import VueTimepicker from 'vue2-timepicker';
import * as VueWindow from '@hscmap/vue-window'
import '@/vuebar';
import moment from 'moment';

/**
 * Import style
 */
import '@/assets/scss/main.scss';

import 'vue2-timepicker/dist/VueTimepicker.css'
/**
 * Import services, validators & directives
 */
import '@/services/common.service';
import "@/validator";
import "@/app.directive";
import "@/axios"
import VTooltip from 'v-tooltip'
Vue.use(VTooltip)
Vue.use(VueWindow)
Vue.use(VueNumeric);
let w = window as any;
Vue.use(VueMask);
Vue.use(VPopover, { tooltip: true })
Vue.use(VueTimepicker)
Vue.use(Loading);
Vue.use(VuejsDialog, {
  html: true,
  loader: false,
  okText: 'Yes',      
  cancelText: 'No',
  animation: 'zoom'    // Available: "zoom", "bounce", "fade"
});
Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } });
Vue.use(VueMoment);
const Vuebar = w.Vuebar as any;
Vue.use(Vuebar);
Vue.use(VeeValidate, { events: "change" }); //For validation
Vue.use(Snotify, snotifyOptions)
Vue.component('datetime', Datetime);
Vue.use(InfiniteLoading, {
  slots: {
    // hide keep  slot
    noResults: {
      render: h => h('div'),
    },

    // hide remove  slot
    noMore: {
      render: h => h('div'),
    },

    // hide error slot
    error: {
      render: h => h('div'),
    },
  },
});

Vue.filter('local', function (date) {
  date = new Date(date)
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();
  newDate.setHours(hours - offset);
  return newDate.toISOString();
})

Vue.filter('date_format', (value, format) => {
  if (value === null || value === undefined || format === undefined) {
    return '';
  }
  return moment(value).format(format);
});

Vue.filter('number_format', (value) => {
  if (value === null || value === undefined) {
    return '0.00';
  }

  const formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
  return formatter.format(value);
});

/**
 * Set lodash to instance.
 */
Vue.prototype.$lodash = lodash;
Vue.config.devtools = true;

Vue.config.devtools = true;
Vue.prototype.$store = store;

/**
 * Mount the application
 */
new Vue({
  router,
  store,
  async beforeCreate() {
    await setInstanceVariables()
  },
  render: (h) => h(App),
}).$mount('#WeMLOApp');
