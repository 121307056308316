import store from './store/store';
import config from '@/environments/index';
import Vue from 'vue';

/**
 * The backend server's base api url based on environment.
 */
export const BASE_API_URL = config.SERVER_BASE_URL;

/**
 * The optimizely key based on environment
 */
export const OPTIMIZELY_KEY = config.OPTIMIZELY_KEY;

/**
 * Phone Extension according to environment
 */
export const PHONE_EXT = config.PHONE_EXT;
/**
 * Environment name
 */
export const ENV = config.ENV;
/**
 * CLIENT URL
 */
export const CLIENT_URL = config.CLIENT_URL;
/**
 * Disable production tips
 */
Vue.config.productionTip = false;
/**
 * Hide VueJs dev tools
 */
Vue.config.devtools = false;
/**
 * Disable console on production environment.
 */
if (process.env.NODE_ENV == 'production') {
    // window.console.log = function (): void { }
    // window.console.error = function (): void { }
    // window.console.info = function (): void { }
}

/**
 * Assign/re-assign the instance variables value on window reloads.
 */
export async function setInstanceVariables() {
    let state: any = store.state;
    if (state.sessionObject.token) {
        Vue.prototype.$userId = state.sessionObject.userId;
        if (state.sessionObject.type)
            Vue.prototype.$userType = state.sessionObject.type;
        if (state.sessionObject.userInfo) {
            if (state.sessionObject.type == "Broker") {
                Vue.prototype.$brokerName = state.sessionObject.userInfo.firstName + " " + state.sessionObject.userInfo.lastName;
                Vue.prototype.$brokerEmail = state.sessionObject.email
                Vue.prototype.$brokerId = state.sessionObject.userId;
            } else {
                Vue.prototype.$brokerId = state.sessionObject.userInfo.brokerId;
            }
        }
    }
}
/**
 * The config options for Snotify.
 */
export const snotifyOptions = {
    toast: {
        position: 'rightBottom',
        showProgressBar: false
    }
}


/**
 * Exporting EventBus for
 */
export const EventBus = new Vue();
