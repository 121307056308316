import Vue from 'vue';
import Vuex from 'vuex';
import { VuexPersistence } from 'vuex-persist'
import actions from './actions'
import mutations from './mutations'
import { BorrowerInformation } from '@/models/pos/borrower-info.model';
import { DemographicInformation } from '@/models/pos/demographic-info.model';
import { Acknowledgement } from '@/models/pos/acknowledgement.model';
import { FinancialInfo } from '@/models/pos/financial-info.model';
import { Declaration } from '@/models/pos/declaration.model';
import { LoanAndProperty } from '@/models/pos/loan-and-property.model';
import { LoanOriginatorInfo } from '@/models/pos/loan-originator-info.model';
import { TransactionDetail } from '@/models/pos/transaction-detail.model';
import { borrowerData } from '@/models/borrowerData.model';
//import { loanScenario } from '@/models/loan-scenario.model'
import { BorrowerData, QuestionInfo, QuestionHistory } from '@/models/common.model';
Vue.use(Vuex);
/**
* We need our data to be persisted on page reloads.
*/
const vuexLocalStorage = new VuexPersistence({
  key: 'state.WeMLO',
  storage: window.localStorage
})

export const posSessionConfig = () => {
  return {
    loanTxnId: null,
    ...new QuestionInfo(),
  }
}

export const posProperties = () => {
  return {
    borrowerInfo: new BorrowerInformation(),
    demographicInfo: new DemographicInformation(),
    acknowledgement: new Acknowledgement(),
    financialInfo: new FinancialInfo(),
    declaration: new Declaration(),
    loanAndProperty: new LoanAndProperty(),
    transactionDetail: new TransactionDetail(),
    borrowerData: new borrowerData()
  }
}

export const urlaProps = () => {
  return {
    borrowerInfo: new BorrowerInformation(),
    demographicInfo: new DemographicInformation(),
    acknowledgement: new Acknowledgement(),
    financialInfo: new FinancialInfo(),
    declaration: new Declaration(),
    loanAndProperty: new LoanAndProperty(),
    loanOriginatorInfo: new LoanOriginatorInfo(),
    transactionDetail: new TransactionDetail(),
    borrowerData: new borrowerData()
  }
}

//Display Files Modal
const displayFiles = {
  documents: [],
  comments: [],
  downloadFilesArray: [],
  zipDownloadProgress: 'Zip',            //Not needed yet needs to verify
  singleDownloadProgress: 'Single',      //Not needed yet needs to verify
  fileToDisplay: null,
  fileType: null,
  fileArray: [],
  localFiles: [],
  dotLoader: false,
  fileIndex: null,
  showPrev: false,
  showTrue: false,
  review: false,
  comment: null,
  commentSampleAttachment: [],
  reClassify: null,
  reClassifyArray: [],
  currentComment: null,
  loanTxnId: null,
  notificationShow: null,
  byWemloStaff: null,
  ifLocalFiles: null,
  localFilesData: [],
  ifShowCommentModal: null,
  hideReClassify: null,
  conditionType: null,
  ifEmptySamples: null,
  attachComment: null,
  showDelete: null,
  showHeading: null,
  ifDeleteButton: null,
  deleteData: null,
  commentAttachments: null,
  ifReclassifyFromMessage:null
}

const profilePictures = {
  status: null,
  alpha: String(''),
  thumb: String(''),
  small: String('/'),
  medium: String(''),
  cordinates: { width: 0, height: 0 },
  error: null
}

export const initialState = () => ({
  sessionObject: {},
  scenarioDeskCount: 0,
  navigationLinks: [],
  loanSifterId: null,
  twilioToken: null,
  twilioActiveStatus: null,
  pipelineFilters: { searchString: '', searchLoanProcessors: 'All' },
  // posStepId: null,
  // questionnaireData: posProperties(),
  // urlaFormData: urlaProps(),
  posSessionConfig: posSessionConfig(),
  // haveUrlaFormDataValue: false,
  // borrowerData: new borrowerData(),
  visibleQuestions: [],
  pipeline: {},
  displayFiles: displayFiles,
  documents: [],
  requiredDocuments: [],
  approvedDocuments: [],
  removeLocalFiles: [],
  reviewFileIndex: null,
  reviewDoc: {},
  wemloLoader: null,
  posBasicInfo: null,
  uploadFnmUrl : {},
  profilePictures : profilePictures,
  routeHistory: {},
  isIdentityAssumedMode: undefined,
  copySuperAdminSessionObject: undefined,
  userInfoForIdentityAssumedMode: undefined
})


export default new Vuex.Store({
  state: initialState,
  strict: false,
  mutations: mutations,
  actions: actions,
  modules: {
  },
  plugins: [vuexLocalStorage.plugin]
});
