import posRoutes from './pos.routes';
import commonRoutes from './common.routes';
import prequisitesRoutes from './prequisites.routes';
import stores from '@/store/store';
const store: any = stores;
export const getCurrentUserTypePath = () => {
    let type = store.state.sessionObject.type;
    if (type == 'Admin Staff') {
        return 'AdminStaff';
    } else if (type == 'Wemlo Processor') {
        return 'Processor';
    } else if (type == 'Super Admin') {
        return 'SuperAdmin';
    } else if (type == 'Broker') {
        return 'Broker';
    } else if (type == 'Borrower') {
        return 'Borrower';
    } else if (type == 'MLO') {
        return 'MLO';
    }
}

export const beforeEachEnter = async (to, from, next) => {
    if (store.state.sessionObject.type == "Broker") {
        next()
    } else {
        let path = await getCurrentUserTypePath();
        next(path)
    }
};

export default [
    {
        path: "/broker",
        name: "Broker",
        component: () => import(/* webpackChunkName: "broker" */'@/components/broker/Broker.vue'),
        redirect: { name: 'BrokerDashBoard' },
        meta: {
            requiresAuth: true,
            title: "Broker"
        },
        beforeEnter: beforeEachEnter,
        children: [
            {
                path: "dashboard",
                name: "BrokerDashBoard",
                component: () => import(/* webpackChunkName: "broker-dashboard" */'@/components/broker/dashboard/Dashboard.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Dashboard"
                },
            },
            {
                path: 'pre-requisites',
                name: 'Prerequisites',
                component: () => import(/* webpackChunkName: "pre-requisites" */'@/components/broker/pre-requisites/Prerequisites.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Prerequisites"
                }
            },
            {
                path: 'customer-retention',
                name: 'Customer Retention',
                component: () => import(/* webpackChunkName: "customer-retention" */'@/components/customer-retention/CustomerRetention.vue'),
                meta: {
                    requiresAuth: true,
                    title: "CustomerRetention"
                }
            },
            {
                path: 'customer-alerts',
                name: 'Customer Alerts',
                component: () => import(/* webpackChunkName: "customer-alerts" */'@/components/customer-retention/alerts/CustomerAlerts.vue'),
                meta: {
                    requiresAuth: true,
                    title: "CustomerAlerts"
                }
            },
            {
                path: 'loan-estimate',
                name: 'BrokerLoanEstimate',
                component: () => import(/* webpackChunkName: "review-loan-estimate" */'@/views/reviewLoanEstimate/reviewLoanEstimate.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Fee Worksheet"
                }
            },
            {
                path: "disclosures",
                name: "BrokerDisclosures",
                component: () => import(/* webpackChunkName: "broker-disclosure" */'@/components/broker/disclosures/Disclosures.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Disclosures"
                }
            },
            {
                path: 'borrower-contact',
                name: 'BrokerBorrowerContact',
                component: () => import(/* webpackChunkName: "borrower-contact" */'@/views/borrower-contact/BorrowerContactForm.vue'),
                meta: {
                    requiresAuth: true,
                    title: "Borrower Contact"
                }
            },
            ...posRoutes('Broker'),
            ...commonRoutes('Broker')
        ]
    },
]


