


































import { BASE_API_URL, ENV } from '@/config';
import { isOnboardingCompleted } from '@/constants';
import ConsentToContact from '@/views/ConsentToContact.vue';
import EDisclosureAgreement from '@/views/EDisclosureAgreement.vue';
import WemloFooter from '@/views/Footer.vue';
import WeLearn from '@/views/LeanMoreVideoModal.vue';
import Loader from '@/views/Loader.vue';
import LostEmploymentInfoModal from '@/views/LostEmploymentInfoModal.vue';
import preRequisiteHeader from '@/views/preRequisiteHeader/PreRequisiteHeader.vue';
import ReAuthenticationModal from '@/views/ReAuthenticationModal.vue';
import ToSeeAttachmentPopup from '@/views/ToSeeAttachmentPopup.vue';
import WemloHeader from '@/views/WeMLOHeader.vue';
import Axios from 'axios';
import _ from 'lodash';
import LogRocket from 'logrocket';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { EventBus } from './config';
import { featureInit } from './feature-flag';
import BannerForIdentityAssumedMode from "@/views/BannerForIdentityAssumedMode.vue";

@Component({
  components: {
    AppSideNav: () => import('@/views/sideNav/AppSideNav.vue'),
    ConsentToContact,
    BannerForIdentityAssumedMode,
    EDisclosureAgreement,
    Loader,
    LostEmploymentInfoModal,
    preRequisiteHeader,
    ReAuthenticationModal,
    ToSeeAttachmentPopup,
    WeLearn,
    WemloFooter,
    WemloHeader,
  }
})
export default class AppComponent extends Vue {
  public pointer = true;
  public width = 180;
  public event = "hover";
  public brandingInformation = {};
  public pendingActions = [];
  public routerKey: number = 0;

  private weLearnData: WeLearnDataClass = new WeLearnDataClass();

  public get currentPendingAction(): any {
    return _.head(this.pendingActions);
  }

  public get userType() {
    return this.$store.state.sessionObject.type;
  }

  public get environment(): string {
    return ENV;
  }

  public get hasSession(): string {
    return this.$store.state.sessionObject.token;
  }

  public shouldShowPrerequisiteHeader(): boolean {
    const isBrokerUser = this.userType === 'Broker' || this.userType === 'MLO';
    return !this.hasSession || (isBrokerUser && !this.hasCompletedPrerequisites);
  }

  public shouldShowSidenav(): boolean {
    if (!this.hasSession) return false;

    const blacklistRoutes = [
      'BorrowerBorrowerWelcome',
      'BorrowerPOSQuestionnaire',
      'Prerequisites',
      'MLOPrerequisites',
    ];

    return !blacklistRoutes.includes(this.$route.name);
  }

  public get hasCompletedPrerequisites(): boolean {
    return isOnboardingCompleted(this.$store.state);
  }

  checkPendingActions() {
    this.pendingActions = [];

    if (this.shouldShowPrerequisiteHeader()) { return; }

    const pendingActions = _.get(this.$store.state, 'sessionObject.pendingActions', null);

    if (_.get(pendingActions, 'consentToContact', null) === false) {
      this.pendingActions.push('consentToContact');
    }

    if (_.isNull(_.get(pendingActions, 'eDisclosureAgreement', undefined))) {
      this.pendingActions.push('EDisclosureAgreement');
    }

    if (this.pendingActions.length) {
      this.$modal.show('pendingActionsModal');
    }
  }

  actionComplete() {
    this.pendingActions = _.tail(this.pendingActions);

    if (!this.pendingActions.length) {
      this.$modal.hide('pendingActionsModal');
    }
  }

  remountRoute() {
    this.routerKey += 1;
  }

  @Watch('$route', { immediate: true })
  onUrlChange() {
    this.$modal.hide('pendingActionsModal');
    this.checkPendingActions();
  }

  async logout() {

    this.$modal.hide('reAuthenticationModal');

    await this.$store.dispatch('logout');
    await this.$store.dispatch('clearSession');
    this.$snotify.warning('Your session has expired');

    this.$router.push({ name: "Login" });
  }

  async verifyJWTToken() {
    const currentTimestamp = + new Date() / 1000;
    const tokenExpiresIn = _.get(this.$store, 'state.sessionObject.tokenExpiresIn', null);
    const tokenHasExpired = tokenExpiresIn && currentTimestamp > tokenExpiresIn;

    if (tokenHasExpired) {
      await this.logout();
    }
  }

  /**
   * This is a quick way to get a list of all routes in the Vue.js instance.
   * Including nested/children routes.
   * Is not meant to be called on production.
   * But could be useful for generating reports locally.
   *
   * Possible usage:
   *   console.log(this.getAllRoutes());
   */
  getAllRoutes() {
    const routeReducer = (routeAggregator, route) => {
      routeAggregator.push(route.path);

      if (route.children) {
        route.children = route.children.map(children => {
          children.path = `${route.path}/${children.path}`;
          return children;
        });

        const childrenRoutes = route.children.reduce(routeReducer, []);

        routeAggregator = [
          ...routeAggregator,
          ...childrenRoutes
        ];
      }

      return routeAggregator;
    };

    const routes = _.get(this, '$router.options.routes', []);

    return routes.reduce(routeReducer, []);
  }

  async mounted() {
    await this.verifyJWTToken();

    EventBus.$on("hideLoader", () => {
      if (this.$loader) {
        this.$loader.hide();
      }
    });
    EventBus.$on("notifyError", (message: string) => {
      this.$snotify.clear();
      this.$snotify.error(message);
    });
    this.$store.state.wemloLoader = false;
    window.addEventListener("storage", async event => {
      if (event.key == "logout-event") {
        localStorage.removeItem("logout-event");
        await this.$store.dispatch("clearSession");
        if (this.$route.name != "Login" && this.$route.name != "ActivationPage")
          this.$router.push({ name: "Login" });
      }
    });
    /**
     * Making event for we learn videos
     */
    EventBus.$on('openWeLearn', (data)=>{
      this.weLearnData.isOpen = true;
      this.weLearnData.taskName = data.taskName;
      this.weLearnData.videoUrl = data.videoUrl;
    })
    EventBus.$on('closeWeLearn', (data)=>{
      this.weLearnData.isOpen = false;
    })

    /**
     * Do not log on nonprod environments
     */
    if (this.environment === 'production') {
      LogRocket.init('mnmv9f/wemlo-app');
    }

    featureInit();
    this.$store.state.wemloLoader = true;
    let response = await Axios.post(BASE_API_URL + "common/getBrandingInformation", {}, { headers: { public: true}});
    this.checkPendingActions();
    this.brandingInformation = response.data;
    this.$store.state.brandingInformation = this.brandingInformation;
    this.$store.state.wemloLoader = false;
  }
}

class WeLearnDataClass{
  constructor (){
    this.taskName = null;
    this.videoUrl = null;
    this.isOpen = null;
  }
  public isOpen: Boolean;
  public taskName: String;
  public videoUrl: String;
}


