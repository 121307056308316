















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
    Component,
    Vue,
    Prop
} from "vue-property-decorator";
import {
    BASE_API_URL
} from "@/config";
import Axios from "axios";
import {
    HollowDotsSpinner
} from "epic-spinners";
import saveAs from "save-as";
import print from "print-js";
import {
    EventBus
} from "@/views/CommunicationPopup.vue";
// import stores from '@/store/store'
// const store: any = stores.state;
import range from "lodash/range";
import pdfjs from "@/pdfjs";
import _ from "lodash";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";


@Component({
    components: {
        HollowDotsSpinner
    }
})
export default class DisplayDocument extends Vue {
    // public setBatchToLenderData :any ={};
    public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
    public inProcessComplete = false;
    public inProcessAccept = false;
    public inProcessReject = false;

    @Prop()
    public propData: object;
    @Prop({default: false})
    public loanIsLocked: boolean;
    @Prop()
    public show: boolean;
    @Prop()
    public from;
    public sourced = "";
    public errorShow = false;
    //----------------------------FOR ONLY DISPLAY COMMENT ATTACHMENTS OVERLAPPING MODAL---------------
    public fileToDisplay = null;
    public showNext = null;
    public showPrev = null;
    public fileType = null;
    public fileIndex = null;
    public fileArray = [];
    public dotLoader = null;
    //-------------ENDS HERE DON'T USE IT FOR DISPLAY DOCUMENTS UNTIL YOU NEED OVERLAPP MODAL----------
    public numPages = 0;
    public fileCategoryName = '';
    public documentStatus = '';
    public showFileHistory = false;

    public openFormsPage() {
      if (!this.$route.query.id) { return; }

      this.$router.push(`/processor/current-urla?id=${this.$route.query.id}#Forms`);
    }

      public async loadPdf(file, fileData) {
        try {
            let response = fileData;

            this.$store.state.displayFiles.fileExists = true;

            if(!fileData){
              try {
                response =
                  await Axios.post(
                    BASE_API_URL+'common/fetchDocument',
                    { path: file ? file : this.$store.state.displayFiles.fileToDisplay }
                  );

              } catch (error) {
                this.$store.state.displayFiles.fileExists = false;

                throw error;
              }
            }
            let pdf = await pdfjs.getDocument(
                // file ? file : this.$store.state.displayFiles.fileToDisplay
                {data : atob(response.data)}
            ).promise;

            let container = document.getElementById("pdfElement");
            let parent = document.getElementsByClassName("wemlo-dialog target-container")[0] as HTMLElement;

            /**
             * Prevent access of properties on undefined elements
             */
            if (!container || !parent) {
              return;
            }

            /**
             * Clear container before loading a new pdf
             */
            container.innerHTML = '';

            for (let pageNumber of range(1, pdf.numPages + 1)) {
                let page = await pdf.getPage(pageNumber);
                let canvas = document.createElement("canvas");

                // Prepare canvas using PDF page dimensions
                const scale = parent.offsetWidth / page.getViewport({ scale: 1 }).width;
                canvas.height = page.view[3] * scale;
                canvas.width = parent.offsetWidth;
                let viewport = page.getViewport({ scale });

                // Render PDF page into canvas context
                let canvasContext = canvas.getContext("2d");
                let renderContext = {
                    canvasContext,
                    viewport
                };

                await page.render(renderContext).promise;

                /**
                 * Append pdf page to container
                 */
                container.appendChild(canvas);
            }
        } catch (error) {
            console.error(error.message);

            const errorMessage = _.get(error, 'message', null);
            const customErrorMessages = [
              { originalMessage: 'Request failed with status code 404', newMessage: null },
              { originalMessage: 'No password given', newMessage: 'This PDF file is password protected' }
            ];
            const message = _.get(customErrorMessages.find(message => message.originalMessage === errorMessage), 'newMessage', errorMessage);

            if (message) {
              this.$snotify.error(message);
            }
        } finally {
            this.dotLoader = false;
            this.$store.state.displayFiles.dotLoader = false;
        }
    }

    public async loadImage (file, ext) {
      try {
        this.$store.state.displayFiles.dotLoader = true;
        let response = await Axios.post(BASE_API_URL+'common/fetchDocument',
          { path: file ? file : this.$store.state.displayFiles.fileToDisplay });
        let dom = document.getElementById("imageElement");
        dom['src'] = `data:image/${ext};base64,`+response.data;
        dom.append();
        this.$store.state.displayFiles.dotLoader = false;
      } catch (error) {
        this.$store.state.displayFiles.dotLoader = false;
        console.log(error);
      }
    }

    /************************************************************************************************
     *                        FUNCTION FOR CHECKING ORIGIN OF FILE UPLOAD                            *
     ************************************************************************************************/

    public checkUploadedBy() {
        try {
            let data = this.$store.state.displayFiles.reviewDoc.fileHistory;
            let check =
                data[data.length - 1].uploadedBy ==
                this.$store.state.sessionObject.userId;
            check
                ?
                (this.$store.state.displayFiles.byWemloStaff = true) :
                (this.$store.state.displayFiles.byWemloStaff = false);
        } catch (error) {
            console.log(error);
        }
    }

    /**************************************************************************************************
     *                        FUNCTION FOR SHOWING THE DISPLAY FILE MODAL                              *
     **************************************************************************************************/

    public setInitialValues(
        documents,
        comments,
        reviewDoc,
        review,
        loanTxnId,
        notificationShow,
        hideReClassify: Boolean,
        source
    ) {
        try {
            this.sourced = source;
            //FUNCTION STARTS//
            this.$store.state.displayFiles.documents = documents;
            this.$store.state.displayFiles.comments = comments;
            this.$store.state.displayFiles.reviewDoc = reviewDoc;
            this.$store.state.displayFiles.loanTxnId = loanTxnId;
            this.displayDocuments(reviewDoc);
            this.$store.state.displayFiles.review = review;
            this.setReClassify(loanTxnId);
            this.checkUploadedBy();
            if (notificationShow == false) {
                this.$store.state.displayFiles.notificationShow = notificationShow;
            }
            if (hideReClassify == true) {
                //send to hide reclassify
                this.$store.state.displayFiles.hideReClassify = hideReClassify;
            }
            //FUNCTION ENDS//
        } catch (error) {
            console.log(error);
        }
    }

    /**************************************************************************************************
     *                      FUNCTION FOR SHOWING THE DISPLAY LOCAL FILE MODAL                          *
     **************************************************************************************************/
    // better use modal: this.$modal.show('toSeeAttachment', {})
    public setInitialValuesForLocalFiles(files, ifDeleteButton, deleteData) {
        try {
            //FUNCTION STARTS//
            this.$store.state.displayFiles.localFiles = [] ;
            this.$store.state.displayFiles.fileArray = [];
            this.$store.state.displayFiles.fileArrayWithId = [];
            this.$store.state.displayFiles.ifDeleteButton = ifDeleteButton;
            this.$store.state.displayFiles.deleteData = deleteData;
            this.$store.state.displayFiles.localFilesData = [];
            this.$store.state.displayFiles.localFiles = files;
            files.forEach(file => {
                let type = file.type.split("/").pop();
                this.$store.state.displayFiles.localFilesData.push(type);
                let url = URL.createObjectURL(file);
                this.$store.state.displayFiles.fileArray.push(url);
            });
            this.$store.state.displayFiles.ifShowCommentModal = true;
            this.$store.state.displayFiles.fileIndex = 0;
            this.$store.state.displayFiles.fileToDisplay = this.$store.state.displayFiles.fileArray[
                this.$store.state.displayFiles.fileIndex
            ];
            this.$store.state.displayFiles.fileType = this.$store.state.displayFiles.localFilesData[
                this.$store.state.displayFiles.fileIndex
            ];
            this.$store.state.displayFiles.ifLocalFiles = true;
            if (this.$store.state.displayFiles.fileArray.length > 1) {
                this.$store.state.displayFiles.showNext = true;
            }

            if(this.from != 'management'){
              this.$modal.show("displayFileLocalWithDelete" + this.from);
            }
            //FUNCTION ENDS//
        } catch (error) {
            console.log(error);
        }
    }

    /**************************************************************************************************
     *                              FUNCTION FOR DISPLAYING MODAL AND DOCUMENTS                        *
     **************************************************************************************************/

    public setInitialValuesForShowMessage(message, notificationShow) {
        try {
            this.$store.state.displayFiles.currentComment = message;
            if (notificationShow == false) {
                this.$store.state.displayFiles.notificationShow = notificationShow;
            }
            this.$modal.show("viewMessage" + this.from);
        } catch (error) {
            console.log(error);
        }
    }

    /**************************************************************************************************
     *                      FUNCTION FOR DISPLAYING MODAL AND DOCUMENTS WITH COMMENT                   *
     **************************************************************************************************/
    public async setInitialValuesForAttachmentsAndComments(
            comment,
            attachments,
            commentAttachments,
            reClassify,
            reviewDoc
        ) {
            try {
                this.$store.state.displayFiles.reviewDoc = reviewDoc;
                this.$store.state.displayFiles.currentComment = comment;
                this.$store.state.displayFiles.commentAttachments = commentAttachments;
                this.$store.state.displayFiles.fileArray = [];
                this.$store.state.displayFiles.fileArrayWithId = [];
                this.$store.state.displayFiles.ifReclassifyFromMessage = reClassify;
                attachments.forEach(file => {
                    this.$store.state.displayFiles.fileArray.push(
                        file.path
                    );
                    this.$store.state.displayFiles.fileArrayWithId.push({
                        id: file._id,
                        name: file.path
                    });
                });
                if (this.$store.state.displayFiles.fileArray.length > 1) {
                    this.$store.state.displayFiles.showNext = true;
                }
                this.$store.state.displayFiles.fileToDisplay = this.$store.state.displayFiles.fileArray[0];
                this.$store.state.displayFiles.fileIndex = 0;
                this.$store.state.displayFiles.fileType = this.$store.state.displayFiles.fileToDisplay
                    .split(".")
                    .pop()
                    .toLowerCase();
                if (this.$store.state.displayFiles.fileType == "pdf") {
                    this.loadPdf(null, null);
                }
                if (this.$store.state.displayFiles.fileType == 'jpg' || this.$store.state.displayFiles.fileType == 'jpeg' || this.$store.state.displayFiles.fileType =='png') {
                  this.loadImage(this.$store.state.displayFiles.fileToDisplay, this.$store.state.displayFiles.fileType)
                }
                if (reClassify) {
                    let check = await this.checkIfreClassified(
                        reClassify.commId,
                        reClassify.loanTxnId,
                        this.$store.state.displayFiles.fileToDisplay
                    );
                    this.$store.state.displayFiles.loanTxnId = reClassify.loanTxnId;
                    this.$store.state.displayFiles.commId = reClassify.commId;
                    this.$store.state.displayFiles.alreadyReclasified = check;
                    this.$store.state.displayFiles.reclassifyFromMessage = check ?
                        false :
                        true;
                    this.setReClassify(reClassify.loanTxnId);
                }
                this.$store.state.displayFiles.dotLoader = true;
                this.$modal.show("displayFileCommon" + this.from);
            } catch (error) {
                console.log(error);
            }
        }

    public approveRejectCompliance(status){
      this.$emit("approveReject", status)
    }

    public setShowFileHistory(status){
      this.showFileHistory = status;
    }

    public async setFullModelView(
            comment,
            attachments,
            commentAttachments,
            categoryName,
            status,
            inputType
        ) {
            try {
                this.fileCategoryName = categoryName;
                this.documentStatus = status;
                this.$store.state.displayFiles.currentComment = comment;
                this.$store.state.displayFiles.commentAttachments = commentAttachments;
                this.$store.state.displayFiles.fileArray = [];
                this.$store.state.displayFiles.fileArrayWithId = [];
                if(inputType != 'raw'){
                  attachments.forEach(file => {
                      this.$store.state.displayFiles.fileArray.push(
                          file.path
                      );
                      this.$store.state.displayFiles.fileArrayWithId.push({
                          id: file._id,
                          name: file.path
                      });
                  });
                }
                if (this.$store.state.displayFiles.fileArray.length > 1) {
                    this.$store.state.displayFiles.showNext = true;
                }
                this.$store.state.displayFiles.fileToDisplay = this.$store.state.displayFiles.fileArray[0];
                this.$store.state.displayFiles.fileIndex = 0;
                if(inputType != 'raw'){
                  this.$store.state.displayFiles.fileType = this.$store.state.displayFiles.fileToDisplay
                      .split(".")
                      .pop()
                      .toLowerCase();
                }
                if ((this.$store.state.displayFiles.fileType == "pdf" || inputType) && this.$store.state.displayFiles.fileType !== 'zip') {
                    if(inputType == 'raw'){
                      this.loadPdf(null, {data : attachments});
                    }else{
                      this.loadPdf(null, null);

                    }
                }
                if (this.$store.state.displayFiles.fileType == 'jpg' || this.$store.state.displayFiles.fileType == 'jpeg' || this.$store.state.displayFiles.fileType =='png') {
                  this.loadImage(this.$store.state.displayFiles.fileToDisplay, this.$store.state.displayFiles.fileType)
                }
                this.$store.state.displayFiles.dotLoader = true;
                this.$modal.show("documentViewFull" + this.from);
            } catch (error) {
                console.log(error);
            }
        }

        /**************************************************************************************************
         *                   SET INITIAL VALUES FOR DISPLAY SAMPLES WITH HEADING TYPE                      *
         **************************************************************************************************/
    public setInitialValuesForShowingSamples(conditionName, samples, comment) {
        try {
            this.$store.state.displayFiles.conditionType = conditionName;
            this.$store.state.displayFiles.comment = comment;
            if (samples.length == 0) {
                this.$store.state.displayFiles.ifEmptySamples = true;
            } else {
                this.$store.state.displayFiles.dotLoader = true;
                this.$store.state.displayFiles.fileArray = [];
                this.$store.state.displayFiles.fileArrayWithId = [];

                samples.forEach(file => {
                    this.$store.state.displayFiles.fileArray.push(
                        file.path
                    );
                    this.$store.state.displayFiles.fileArrayWithId.push({
                        id: file._id,
                        name: file.path
                    });
                });
                if (this.$store.state.displayFiles.fileArray.length > 1) {
                    this.$store.state.displayFiles.showNext = true;
                }
                this.$store.state.displayFiles.fileToDisplay = this.$store.state.displayFiles.fileArray[0];
                this.$store.state.displayFiles.fileIndex = 0;
                this.$store.state.displayFiles.fileType = this.$store.state.displayFiles.fileToDisplay
                    .split(".")
                    .pop()
                    .toLowerCase();
            }
            if (this.$store.state.displayFiles.fileType == "pdf") {
                this.loadPdf(null, null);
            }
            if (this.$store.state.displayFiles.fileType == 'jpg' || this.$store.state.displayFiles.fileType == 'jpeg' || this.$store.state.displayFiles.fileType =='png') {
              this.loadImage(this.$store.state.displayFiles.fileToDisplay, this.$store.state.displayFiles.fileType)
            }
            this.$modal.show("displaySamples" + this.from);
        } catch (error) {
            console.log(error);
        }
    }

    /**************************************************************************************************
     *                   SET INITIAL VALUES FOR DISPLAY SAMPLES WITH HEADING TYPE                      *
     **************************************************************************************************/
    public setInitialValuesForShowingSamplesWithHeading(withHeading, samples) {
        try {
            this.$store.state.displayFiles.withHeading = withHeading;
            this.$store.state.displayFiles.dotLoader = true;
            this.$store.state.displayFiles.fileArray = [];
            this.$store.state.displayFiles.fileArrayWithId = [];

            samples.forEach(file => {
                this.$store.state.displayFiles.fileArray.push(
                    file.path
                );
                this.$store.state.displayFiles.fileArrayWithId.push({
                    id: file._id,
                    name: file.path
                });
            });
            if (this.$store.state.displayFiles.fileArray.length > 1) {
                this.$store.state.displayFiles.showNext = true;
            }
            this.$store.state.displayFiles.fileToDisplay = this.$store.state.displayFiles.fileArray[0];
            this.$store.state.displayFiles.fileIndex = 0;
            this.$store.state.displayFiles.fileType = this.$store.state.displayFiles.fileToDisplay
                .split(".")
                .pop()
                .toLowerCase();
            if (this.$store.state.displayFiles.fileType == "pdf") {
                this.loadPdf(null, null);
            }
            if (this.$store.state.displayFiles.fileType == 'jpg' || this.$store.state.displayFiles.fileType == 'jpeg' || this.$store.state.displayFiles.fileType =='png') {
              this.loadImage(this.$store.state.displayFiles.fileToDisplay, this.$store.state.displayFiles.fileType)
            }
            this.$modal.show("displaySamplesHeading" + this.from);
        } catch (error) {
            console.log(error);
        }
    }

    /**************************************************************************************************
     *                   SET INITIAL VALUES FOR DISPLAY SAMPLES WITH DELETE BUTTON                     *
     **************************************************************************************************/
    public setInitialValuesForShowingSamplesWithDeleteButton(
        samples,
        ifDeleteButton,
        deleteData,
        doc
    ) {
        try {
            this.$store.state.displayFiles.ifDeleteButton = ifDeleteButton;
            this.$store.state.displayFiles.deleteData = deleteData;
            this.$store.state.displayFiles.doc = doc;

            this.$store.state.displayFiles.dotLoader = true;
            this.$store.state.displayFiles.fileArray = [];
            this.$store.state.displayFiles.fileArrayWithId = [];

            samples.forEach(file => {
                this.$store.state.displayFiles.fileArray.push(
                    file.path
                );
                this.$store.state.displayFiles.fileArrayWithId.push({
                    id: file._id,
                    name: file.path
                });
            });
            if (this.$store.state.displayFiles.fileArray.length > 1) {
                this.$store.state.displayFiles.showNext = true;
            }
            this.$store.state.displayFiles.fileToDisplay = this.$store.state.displayFiles.fileArray[0];
            this.$store.state.displayFiles.fileIndex = 0;
            this.$store.state.displayFiles.fileType = this.$store.state.displayFiles.fileToDisplay
                .split(".")
                .pop()
                .toLowerCase();
            if (this.$store.state.displayFiles.fileType == "pdf") {
                this.loadPdf(null, null);
            }
            if (this.$store.state.displayFiles.fileType == 'jpg' || this.$store.state.displayFiles.fileType == 'jpeg' || this.$store.state.displayFiles.fileType =='png') {
              this.loadImage(this.$store.state.displayFiles.fileToDisplay, this.$store.state.displayFiles.fileType)
            }
            this.$modal.show("displaySamplesHeading" + this.from);
        } catch (error) {
            console.log(error);
        }
    }

    /**************************************************************************************************
     *                          FUNCTION FOR DISPLAYING MODAL AND DOCUMENTS                            *
     **************************************************************************************************/

    public displayDocuments(doc) {
        try {
            //FUNCTION STARTS//
            this.$store.state.displayFiles.currentComment = "";
            if (doc.fileHistory[doc.fileHistory.length - 1].status == "Rejected") {
                this.$store.state.displayFiles.currentComment = this.$store.state.displayFiles.comments[
                    doc.fileHistory[doc.fileHistory.length - 1].commentPath
                ].comment;
            }

            // this.currentComment = this.allComments[this[origin][documentIndex].fileHistory[fileIndex].commentIndex].comment;
            this.$store.state.displayFiles.showPrev = false;
            if (doc.fileHistory[doc.fileHistory.length - 1].files.length > 1) {
                this.$store.state.displayFiles.showNext = true;
            }
            this.$store.state.displayFiles.fileArray = [];
            this.$store.state.displayFiles.fileArrayWithId = [];

            doc.fileHistory[doc.fileHistory.length - 1].files.forEach(file => {
                this.$store.state.displayFiles.fileArray.push(
                    file.path
                );
                this.$store.state.displayFiles.fileArrayWithId.push({
                    id: file._id,
                    name: file.path
                });
            });
            this.$store.state.displayFiles.fileToDisplay = this.$store.state.displayFiles.fileArray[0];
            this.$store.state.displayFiles.fileIndex = 0;
            this.$store.state.displayFiles.fileType = this.$store.state.displayFiles.fileToDisplay
                .split(".")
                .pop()
                .toLowerCase();
            if (this.$store.state.displayFiles.fileType == "pdf") {
                this.loadPdf(null, null);
            }
            if (this.$store.state.displayFiles.fileType == 'jpg' || this.$store.state.displayFiles.fileType == 'jpeg' || this.$store.state.displayFiles.fileType =='png') {
              this.loadImage(this.$store.state.displayFiles.fileToDisplay, this.$store.state.displayFiles.fileType)
            }
            this.$store.state.displayFiles.dotLoader = true;
            this.$modal.show("displayFileCommon" + this.from);
            //FUNCTION ENDS//
        } catch (error) {
            console.log(error);
        }
    }

    /**************************************************************************************************
     *                     FUNCTION FOR CHANGING NEXT OR PREV BUTTON                                   *
     **************************************************************************************************/

    public async changeImageIndex(check) {
        try {
            //FUNCTION START//
            this.$store.state.displayFiles.dotLoader = true;
            if (this.$store.state.displayFiles.ifLocalFiles) {
                this.changeImageIndexForLocals(check);
                return;
            }
            if (check == "next") {
                this.$store.state.displayFiles.fileIndex++;
                this.$store.state.displayFiles.showPrev = true;
                this.$store.state.displayFiles.fileToDisplay = this.$store.state.displayFiles.fileArray[
                    this.$store.state.displayFiles.fileIndex
                ];
                this.$store.state.displayFiles.fileType = this.$store.state.displayFiles.fileToDisplay
                    .split(".")
                    .pop()
                    .toLowerCase();
                if (
                    this.$store.state.displayFiles.fileArray.length ==
                    this.$store.state.displayFiles.fileIndex + 1
                ) {
                    this.$store.state.displayFiles.showNext = false;
                }

                if (
                    this.$store.state.displayFiles.commId &&
                    this.$store.state.displayFiles.ifReclassifyFromMessage
                ) {
                    let check = await this.checkIfreClassified(
                        this.$store.state.displayFiles.commId,
                        this.$store.state.displayFiles.loanTxnId,
                        this.$store.state.displayFiles.fileToDisplay
                    );

                    this.$store.state.displayFiles.alreadyReclasified = check;
                    this.$store.state.displayFiles.reclassifyFromMessage = check ?
                        false :
                        true;
                }
                await this.setReClassify(this.$store.state.displayFiles.loanTxnId);
            }
            if (check == "previous") {
                this.$store.state.displayFiles.fileIndex--;
                this.$store.state.displayFiles.showNext = true;
                this.$store.state.displayFiles.fileToDisplay = this.$store.state.displayFiles.fileArray[
                    this.$store.state.displayFiles.fileIndex
                ];
                let length = this.$store.state.displayFiles.fileToDisplay.length;
                this.$store.state.displayFiles.fileType = this.$store.state.displayFiles.fileToDisplay
                    .split(".")
                    .pop()
                    .toLowerCase();
                if (this.$store.state.displayFiles.fileIndex == 0) {
                    this.$store.state.displayFiles.showPrev = false;
                }
                if (
                    this.$store.state.displayFiles.commId &&
                    this.$store.state.displayFiles.ifReclassifyFromMessage
                ) {
                    let check = await this.checkIfreClassified(
                        this.$store.state.displayFiles.commId,
                        this.$store.state.displayFiles.loanTxnId,
                        this.$store.state.displayFiles.fileToDisplay
                    );
                    this.$store.state.displayFiles.alreadyReclasified = check;
                    this.$store.state.displayFiles.reclassifyFromMessage = check ?
                        false :
                        true;
                }
                await this.setReClassify(this.$store.state.displayFiles.loanTxnId);
            }
            if (check == "refresh") {
                if (
                    this.$store.state.displayFiles.fileIndex >
                    this.$store.state.displayFiles.fileArray.length - 1
                ) {
                    this.$store.state.displayFiles.fileIndex--;
                }
                this.$store.state.displayFiles.fileToDisplay = this.$store.state.displayFiles.fileArray[
                    this.$store.state.displayFiles.fileIndex
                ];
                this.$store.state.displayFiles.fileType = this.$store.state.displayFiles.fileToDisplay
                    .split(".")
                    .pop()
                    .toLowerCase();
                if (
                    this.$store.state.displayFiles.fileArray.length ==
                    this.$store.state.displayFiles.fileIndex + 1
                ) {
                    this.$store.state.displayFiles.showNext = false;
                }
                if (this.$store.state.displayFiles.fileIndex == 0) {
                    this.$store.state.displayFiles.showPrev = false;
                }
                if (
                    this.$store.state.displayFiles.commId &&
                    this.$store.state.displayFiles.ifReclassifyFromMessage
                ) {
                    let check = await this.checkIfreClassified(
                        this.$store.state.displayFiles.commId,
                        this.$store.state.displayFiles.loanTxnId,
                        this.$store.state.displayFiles.fileToDisplay
                    );
                    this.$store.state.displayFiles.alreadyReclasified = check;
                    this.$store.state.displayFiles.reclassifyFromMessage = check ?
                        false :
                        true;
                }
                await this.setReClassify(this.$store.state.displayFiles.loanTxnId);
            }
            if (this.$store.state.displayFiles.fileType == "pdf") {
              await this.loadPdf(null, null);
            }
            if (this.$store.state.displayFiles.fileType == 'jpg' || this.$store.state.displayFiles.fileType == 'jpeg' || this.$store.state.displayFiles.fileType =='png') {
              await this.loadImage(this.$store.state.displayFiles.fileToDisplay, this.$store.state.displayFiles.fileType)
            }
            //FUNCTION ENDS//
        } catch (error) {
            console.log(error);
        }
    }

    /************************************************************************************************
     *                                       FUNCTION                                                *
     *                                       FOR                                                     *
     *                                       CHANGING                                                *
     *                                       NEXT OR PREV                                            *
     *                                       BUTTON FOR LOCAL                                        *
     ************************************************************************************************/

    public changeImageIndexForLocals(check) {
        try {
            if (check == "next") {
                this.$store.state.displayFiles.fileIndex++;
                this.$store.state.displayFiles.showPrev = true;
                this.$store.state.displayFiles.fileToDisplay = this.$store.state.displayFiles.fileArray[
                    this.$store.state.displayFiles.fileIndex
                ];
                this.$store.state.displayFiles.fileType = this.$store.state.displayFiles.localFilesData[
                    this.$store.state.displayFiles.fileIndex
                ];
                if (
                    this.$store.state.displayFiles.fileArray.length ==
                    this.$store.state.displayFiles.fileIndex + 1
                ) {
                    this.$store.state.displayFiles.showNext = false;
                }
            }
            if (check == "previous") {
                this.$store.state.displayFiles.fileIndex--;
                this.$store.state.displayFiles.showNext = true;
                this.$store.state.displayFiles.fileToDisplay = this.$store.state.displayFiles.fileArray[
                    this.$store.state.displayFiles.fileIndex
                ];
                this.$store.state.displayFiles.fileType = this.$store.state.displayFiles.localFilesData[
                    this.$store.state.displayFiles.fileIndex
                ];
                if (this.$store.state.displayFiles.fileIndex == 0) {
                    this.$store.state.displayFiles.showPrev = false;
                }
            }
            if (check == "refresh") {
                if (
                    this.$store.state.displayFiles.fileIndex >
                    this.$store.state.displayFiles.fileArray.length - 1
                ) {
                    this.$store.state.displayFiles.fileIndex--;
                }
                this.$store.state.displayFiles.fileToDisplay = this.$store.state.displayFiles.fileArray[
                    this.$store.state.displayFiles.fileIndex
                ];
                this.$store.state.displayFiles.fileType = this.$store.state.displayFiles.localFilesData[
                    this.$store.state.displayFiles.fileIndex
                ];
                if (
                    this.$store.state.displayFiles.fileArray.length ==
                    this.$store.state.displayFiles.fileIndex + 1
                ) {
                    this.$store.state.displayFiles.showNext = false;
                }
                if (this.$store.state.displayFiles.fileIndex == 0) {
                    this.$store.state.displayFiles.showPrev = false;
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    public changeImageIndexCA(check) {
        this.dotLoader = true;
        if (check == "next") {
            this.fileIndex++;
            this.showPrev = true;
            this.fileToDisplay = this.fileArray[this.fileIndex];
            this.fileType = this.fileToDisplay
                .split(".")
                .pop()
                .toLowerCase();
            if (this.fileArray.length == this.fileIndex + 1) {
                this.showNext = false;
            }
        }
        if (check == "previous") {
            this.fileIndex--;
            this.showNext = true;
            this.fileToDisplay = this.fileArray[this.fileIndex];
            let length = this.fileToDisplay.length;
            this.fileType = this.fileToDisplay
                .split(".")
                .pop()
                .toLowerCase();
            if (this.fileIndex == 0) {
                this.showPrev = false;
            }
        }
        if (this.fileType == "pdf") {
            this.loadPdf(this.fileToDisplay, null);
        }
        if (this.fileType == 'jpg' || this.fileType == 'jpeg' || this.fileType =='png') {
          this.loadImage(this.fileToDisplay, this.fileType)
        }
    }

    /************************************************************************************************
     *                                       FUNCTION                                                *
     *                                       FOR                                                     *
     *                                       HIDING                                                  *
     *                                       OR CLOSING                                              *
     *                                       DISPLAY MODAL                                           *
     ************************************************************************************************/
    public hideDisplayFileModal() {
        try {
            //FUNCTION STARTS//s
            this.$store.state.displayFiles.reviewDoc = [];
            this.$store.state.displayFiles.downloadFilesArray = [];
            this.$store.state.displayFiles.zipDownloadProgress = "Zip";
            this.$store.state.displayFiles.singleDownloadProgress = "Single";
            this.$store.state.displayFiles.fileToDisplay = null;
            this.$store.state.displayFiles.fileType = null;
            this.$store.state.displayFiles.fileArray = [];
            this.$store.state.displayFiles.dotLoader = false;
            this.$store.state.displayFiles.localFiles = [];
            this.$store.state.displayFiles.fileIndex = null;
            this.$store.state.displayFiles.showPrev = false;
            this.$store.state.displayFiles.showNext = false;
            this.$store.state.displayFiles.showTrue = false;
            this.$store.state.displayFiles.review = false;
            this.$store.state.displayFiles.comment = null;
            this.$store.state.displayFiles.commentSampleAttachment = [];
            this.$store.state.displayFiles.fileArrayWithId = [];
            this.$store.state.displayFiles.reClassify = null;
            this.$store.state.displayFiles.reClassifyArray = [];
            this.$store.state.displayFiles.ifLocalFiles = null;
            this.$store.state.displayFiles.ifDeleteButton = null;
            this.$store.state.displayFiles.localFilesData = [];
            this.$store.state.displayFiles.hideReClassify = null;
            this.$store.state.displayFiles.ifEmptySamples = false;
            this.$store.state.displayFiles.conditionType = null;
            this.$store.state.displayFiles.showDelete = null;
            this.$store.state.displayFiles.attachComment = null;
            this.$store.state.displayFiles.withHeading = null;
            this.$store.state.displayFiles.deleteData = null;
            this.$store.state.displayFiles.commentAttachments = null;
            this.$store.state.displayFiles.ifReclassifyFromMessage = null;
            this.$store.state.displayFiles.reclassifyFromMessage = null;
            this.sourced = null;
            if (this.$store.state.displayFiles.notificationShow == false) {
                EventBus.$emit("someChanges");
            }
            if (this.$store.state.displayFiles.ifShowCommentModal == true) {
                EventBus.$emit("ShowModal");
            }
            this.$store.state.displayFiles.ifShowCommentModal = null;
            this.$store.state.displayFiles.notificationShow = null;
            this.$modal.hide("displayFileCommon" + this.from);
            this.$modal.hide("viewMessage" + this.from);
            this.$modal.hide("displaySamples" + this.from);
            this.$modal.hide("displaySamplesHeading" + this.from);
            this.$modal.hide("displayFileLocalWithDelete" + this.from);
            // FUNCTION ENDS//
        } catch (error) {
            console.log(error);
        }
    }

    public hideDisplayCommentAttachmentModal() {
        try {
            this.fileToDisplay = null;
            this.fileType = null;
            this.fileArray = [];
            this.dotLoader = false;
            this.fileIndex = null;
            this.showPrev = false;
            this.showNext = false;
            this.$modal.hide("commentAttachments" + this.from);
            this.$modal.show("displayFileCommon" + this.from);
            if (this.$store.state.displayFiles.fileType == 'pdf')
              this.loadPdf(null, null);
            else if (['jpg', 'png', 'jpeg'].includes(this.$store.state.displayFiles.fileType))
              this.loadImage(this.$store.state.displayFiles.fileToDisplay, this.$store.state.displayFiles.fileType);
        } catch (error) {
            console.log(error);
        }
    }

    /************************************************************************************************
     *                                       FUNCTION                                                *
     *                                       FOR                                                     *
     *                                       UPLOADING                                               *
     *                                       SAMPLE                                                  *
     *                                       ATTACHMENTS                                             *
     ************************************************************************************************/
    public async uploadSample(event) {
            // let loader = this.$loading.show();

            try {
                //FUNCTION STARTS//
                if (event.target.files.length == 0) {
                    // loader.hide();
                    return;
                }
                this.$store.state.wemloLoader = true;
                let files = [];
                Object.values(event.target.files).forEach(f => {
                    files.push(f);
                });
                let loanTxnId = this.$store.state.displayFiles.loanTxnId;
                let path = "Borrower/" + loanTxnId + "/LoanDocuments/";
                let formData = new FormData();
                formData.append("path", JSON.stringify(path));
                files.forEach(f => {
                    formData.append("file", f);
                });
                let response = await Axios.post(
                    BASE_API_URL + "wemloprocessor/getFileUploadedPath",
                    formData);
                this.$store.state.displayFiles.commentSampleAttachment = [];
                response.data.forEach(element => {
                    this.$store.state.displayFiles.commentSampleAttachment.push({
                        path: element.path
                    });
                });
                // loader.hide();
                this.$store.state.wemloLoader = false;
                //FUNCTION ENDS//
            } catch (error) {
                // loader.hide();
                this.$store.state.wemloLoader = false;
                console.log(error);
            }
        }
        /************************************************************************************************
         *                                       FUNCTION                                                *
         *                                       FOR                                                     *
         *                                       REVIEW                                                  *
         *                                       DOCUMENTS                                               *
         *                                       COMMON                                                  *
         ************************************************************************************************/
        // public async reviewDocument(check, categoryName, categoryType) {
        //   try {
        //     this.inProcessComplete = true;
        //     check=='Approved'?this.inProcessAccept=true:this.inProcessReject=true
        //     //FUNCTION STARTS HERE//
        //     if (check == "Rejected") {
        //       let formValid = await this.$validator.validate("commentReview");
        //       // || this.$store.state.displayFiles.comment == ""
        //       if (!formValid ) {
        //         this.inProcessComplete= false;
        //         this.inProcessAccept=false;
        //         this.inProcessReject=false;
        //         this.errorShow =true
        //         return;
        //       }
        //     }

    //     let commentIdentifier;
    //     this.$store.state.displayFiles.reviewDoc.fileHistory[
    //       this.$store.state.displayFiles.reviewDoc.fileHistory.length - 1
    //     ].status = check;
    //     this.$store.state.displayFiles.reviewDoc.fileHistory[
    //       this.$store.state.displayFiles.reviewDoc.fileHistory.length - 1
    //     ].actionBy = this.$store.state.sessionObject.userInfo.userId;
    //     this.$store.state.displayFiles.reviewDoc.fileHistory[
    //       this.$store.state.displayFiles.reviewDoc.fileHistory.length - 1
    //     ].actionOn = new Date();
    //     this.$store.state.displayFiles.reviewDoc.updatedOn = new Date();
    //     this.$store.state.displayFiles.documents.forEach((data, i) => {
    //       if (data._id == this.$store.state.displayFiles.reviewDoc._id) {
    //         this.$store.state.displayFiles.documents[
    //           i
    //         ] = this.$store.state.displayFiles.reviewDoc;
    //         commentIdentifier = i;
    //       }
    //     });
    //     let dataObj: any = {
    //       loanTxnId: this.$store.state.displayFiles.loanTxnId,
    //       documents: this.$store.state.displayFiles.documents,
    //       commentIdentifier: commentIdentifier
    //     };
    //     if (this.$store.state.displayFiles.comment != null) {
    //       dataObj.comment = this.$store.state.displayFiles.comment;
    //     }
    //     if (this.$store.state.displayFiles.commentSampleAttachment.length > 0) {
    //       dataObj.attachment = this.$store.state.displayFiles.commentSampleAttachment;
    //     }
    //     if (this.$store.state.displayFiles.reClassify != null) {
    //       dataObj.reClassify = this.$store.state.displayFiles.reClassify;
    //     }
    //     let response = await Axios.post(
    //       BASE_API_URL + "wemloprocessor/reviewDocument",
    //       dataObj,
    //       {
    //         params : {
    //           source : this.sourced
    //         }
    //       }
    //     );
    //     this.$store.state.displayFiles.review = false;
    //     await this.$emit(
    //       "refreshFunction",
    //       this.$store.state.displayFiles.reviewDoc._id
    //     );
    //     if (response.data.message) {
    //       this.inProcessComplete = false;
    //       this.inProcessAccept=false;
    //       this.inProcessReject=false;
    //       this.$snotify.success("Document review successfully");
    //     }
    //     this.hideDisplayFileModal();
    //     //FUNCTION ENDS HERE//
    //   } catch (error) {
    //     this.inProcessComplete = false;
    //     console.log(error);
    //   }
    // }

    public uploadDisclosureFile() {
      if (!this.$store.state.displayFiles.reviewDoc._id) { return; }

      this.$emit('uploaddocument', { id: this.$store.state.displayFiles.reviewDoc._id });
    }

    public async reviewDocument(check, categoryName, categoryType) {
        try {
            this.inProcessComplete = true;
            check == "Approved" ?
                (this.inProcessAccept = true) :
                (this.inProcessReject = true);
            //FUNCTION STARTS HERE//
            if (check == "Rejected") {
                let formValid = await this.$validator.validate("commentReview");
                if (!formValid) {
                    this.inProcessComplete = false;
                    this.inProcessAccept = false;
                    this.inProcessReject = false;
                    this.errorShow = true;
                    return;
                }
            }
            let dataObj: any = {
                loanTxnId: this.$store.state.displayFiles.loanTxnId,
                check: check,
                docId: this.$store.state.displayFiles.reviewDoc._id
            };
            if (this.$store.state.displayFiles.comment != null)
                dataObj.comment = this.$store.state.displayFiles.comment;
            if (this.$store.state.displayFiles.commentSampleAttachment.length > 0)
                dataObj.attachment = this.$store.state.displayFiles.commentSampleAttachment;
            if (this.$store.state.displayFiles.reClassify != null)
                dataObj.reClassify = this.$store.state.displayFiles.reClassify;
            let response = await Axios.post(
                BASE_API_URL + "wemloprocessor/reviewDocument",
                dataObj, {
                    params: {
                        source: this.sourced
                    }
                }
            );
            this.$store.state.displayFiles.review = false;
            await this.$emit(
                "refreshFunction",
                this.$store.state.displayFiles.reviewDoc._id, check == 'Approved', this.$store.state.displayFiles.comment
            );

            if (categoryType === 'Broker Disclosures') {
              this.$emit('reloadwesign');
            }

            if (response.data.message) {
                this.inProcessComplete = false;
                this.inProcessAccept = false;
                this.inProcessReject = false;
                this.$snotify.success("Document review successfully");
            }
            this.hideDisplayFileModal();
            //FUNCTION ENDS HERE//
        } catch (error) {
            console.log(error);
        }
    }

    /************************************************************************************************
     *                                       FUNCTION                                                *
     *                                       FOR                                                     *
     *                                       SHOWING                                                 *
     *                                       COMMON MODAL                                            *
     *                                       ZIP & SINGLE                                            *
     ************************************************************************************************/

    public async downloadCurrentReviewDocument(
            fileArray,
            data,
            originalName,
            type
        ) {
            try {
                this.$store.state.displayFiles.downloadFilesArray = [];
                this.$store.state.displayFiles.downloadFilesArray = fileArray;
                await this.downloadDocs(true);
                if (data) {
                    let setBatchToLenderData = {};
                    setBatchToLenderData = data;
                    if (
                        setBatchToLenderData.hasOwnProperty("loanTxnId") &&
                        setBatchToLenderData.hasOwnProperty("docs")
                    ) {
                        let response = await Axios.post(
                            BASE_API_URL + "wemloprocessor/setBatchToLender",
                            setBatchToLenderData);
                        if (type != "batchToLender") {
                            await this.$emit("refreshFunction");
                        }
                        setBatchToLenderData = {};
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
        /************************************************************************************************
         *                                       FUNCTION                                                *
         *                                       FOR                                                     *
         *                                       DOWNLOADING                                             *
         *                                       FILES                                                   *
         *                                       AFTER ASKING                                            *
         ************************************************************************************************/
    public async downloadDocs(checkZip) {
            this.$store.state.wemloLoader = true;
            try {
                let zipName = this.$route.query.id || this.$route.query.loanTxnId || "Documents";

                let path = this.$store.state.displayFiles.downloadFilesArray.map(d => {
                    return d.hasOwnProperty("path") ? d.path : d;
                });
                let originalNames = this.$store.state.displayFiles.downloadFilesArray.map(
                    d => {
                        return d.hasOwnProperty("name") ? d.name : null;
                    }
                );
                originalNames =
                    originalNames.filter(a => a).length > 0 ? originalNames : null;
                let dataObj: any = {
                    check: checkZip,
                    data: path,
                    name: zipName
                };
                if (originalNames) dataObj.originalNames = originalNames;
                let response = await Axios.post(
                    BASE_API_URL + "common/downloadFileS3",
                    dataObj, {
                        responseType: "blob"
                    }
                );
                saveAs(response.data, zipName + `.zip`);
                this.$store.state.wemloLoader = false;
                this.$store.state.displayFiles.downloadFilesArray = [];
            } catch (error) {
                this.$store.state.wemloLoader = false;
                console.log(error);
            }
        }
        /************************************************************************************************
         *                                       FUNCTION                                                *
         *                                       FOR                                                     *
         *                                       PRINTING                                                *
         *                                       DOCUMENTS                                               *
         *                                       IN NEW TAB                                              *
         ************************************************************************************************/

    public async printImage(file) {
        try {
            let ext = file
                .split(".")
                .pop()
                .toLowerCase();
            //******************Dont remove the below code*******************************************
            // let browser = navigator['sayswho']= (function(){
            //   var ua= navigator.userAgent, tem,
            //   M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            //   if(/trident/i.test(M[1])){
            //       tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
            //       return 'IE '+(tem[1] || '');
            //   }
            //   if(M[1]=== 'Chrome'){
            //       tem= ua.match(/\b(OPR|Edge?)\/(\d+)/);
            //       if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera').replace('Edg ', 'Edge ');
            //   }
            //   M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
            //   if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
            //   return M.join(' ');
            // })();
            // browser = browser.split(" ")[0];
            // if (ext == 'pdf'&&browser!='Edge') {
            if (ext == "pdf" || ext == "jpg" || ext == "gif" || ext == "jpeg" || ext == "png") {
                this.$store.state.wemloLoader = true;
                let body = {
                    data: file
                };
                let response = await Axios.post(
                    BASE_API_URL + "common/downloadPDF",
                    body, {
                        responseType: "blob"
                    }
                );
                // loader.hide();
                this.$store.state.wemloLoader = false;
                let printUrl = URL.createObjectURL(response.data);
                if (ext == 'pdf')
                    print(printUrl);
                else if (ext == "jpg" || ext == "gif" || ext == "jpeg" || ext == "png")
                    print({
                        printable: printUrl,
                        type: "image",
                        imageStyle: "width:100%"
                    });
                // } else if ( ext == "jpg" || ext == "gif" || ext == "jpeg" || ext == "png") {
                //   let loader = this.$loading.show();
                //   let body = { data: file };
                //   let response = await Axios.post(
                //     BASE_API_URL + "common/downloadPDF",
                //     body,
                //     { responseType: "blob" }
                //   );
                //   loader.hide();
                //   let printUrl = URL.createObjectURL(response.data);
                //   print({ printable: printUrl, type: "image", imageStyle: "width:100%" });
            } else {
                this.$snotify.error("Printing is not supported on this document");
            }
        } catch (error) {
            this.$store.state.wemloLoader = false;
            console.log(error);
        }
    }

    /**************************************************************************************************
     *                        TO DISPLAY COMMENT ATTACHMENTS OVERLAPPING REJECTING                     *
     **************************************************************************************************/
    public async displayCommentAttachment(files) {
            try {
                this.$modal.hide("displayFileCommon" + this.from);
                this.fileArray = [];
                files.forEach(file => {
                    this.fileArray.push(file.path);
                });
                if (this.fileArray.length > 1) {
                    this.showNext = true;
                }
                this.fileToDisplay = this.fileArray[0];
                this.fileIndex = 0;
                this.fileType = this.fileToDisplay
                    .split(".")
                    .pop()
                    .toLowerCase();
                if (this.fileType == "pdf") {
                    this.loadPdf(this.fileToDisplay, null);
                }
                if (this.fileType == 'jpg'||this.fileType == 'jpeg'||this.fileType =='png') {
                  this.loadImage(this.fileToDisplay, this.fileType)
                }
                this.dotLoader = true;
                this.$modal.show("commentAttachments" + this.from);
            } catch (error) {
                console.log(error);
            }
    }

    public async displayDocumentFromRawFile(files) {
            try {
                this.$modal.hide("rawDocModal" + this.from);
                this.fileArray = [];
                files.forEach(file => {
                    this.fileArray.push(file);
                });
                if (this.fileArray.length > 1) {
                    this.showNext = true;
                }
                this.fileToDisplay = this.fileArray[0];
                this.fileIndex = 0;
                this.loadPdf(null, { data: this.fileToDisplay});
                this.dotLoader = true;
                this.$modal.show("rawDocModal" + this.from);
            } catch (error) {
                console.log(error);
            }
    }
        /**************************************************************************************************
         *                               TO DELETE ATTACHMENTS FROM LOCAL VARIABLES                        *
         **************************************************************************************************/
    public async deleteCurrentDocument() {
            try {
                let file = this.$store.state.displayFiles.fileArray[
                    this.$store.state.displayFiles.fileIndex
                ];
                let obj = {
                    index: this.$store.state.displayFiles.fileIndex,
                    deleteData: this.$store.state.displayFiles.deleteData
                };
                await this.checkFileIndexOnDeleting(file, true);
                if (obj.deleteData == "attachedFiles") {
                    EventBus.$emit("deleteDocs", obj);
                } else {
                    this.$emit("deleteDoc", obj);
                }
            } catch (error) {
                console.log(error);
            }
        }
        /**************************************************************************************************
         *                               TO DELETE ATTACHMENTS FROM S3                                     *
         **************************************************************************************************/
    public async deleteCurrentS3Document() {
        try {
            let file = this.$store.state.displayFiles.fileArray[
                this.$store.state.displayFiles.fileIndex
            ];
            let obj = {
                files: file,
                deleteData: this.$store.state.displayFiles.deleteData
            };
            await this.checkFileIndexOnDeleting(file, null);
            await this.$emit("deleteFile", obj);
            await this.$store.dispatch("removeLocalFiles", [{
                path: file
            }]);
        } catch (error) {
            console.log(error);
        }
    }

    /************************************************************************************************
     *                                       FUNCTION                                                *
     *                                       FOR                                                     *
     *                                       SETTING                                                 *
     *                                       CLASSIFY                                                *
     *                                       ARRAY OPTIONS                                           *
     ************************************************************************************************/
    public async setReClassify(loanTxnId) {
            try {
                let curentDoc = this.$store.state.displayFiles.reviewDoc ?
                    this.$store.state.displayFiles.reviewDoc :
                    null;
                let data = loanTxnId ?
                    loanTxnId :
                    this.$store.state.displayFiles.loanTxnId;
                if (data) {
                    let response = await Axios.post(
                        BASE_API_URL + "wemloprocessor/fetchReclassify", {
                            loanTxnId: data,
                            currentDocument: curentDoc
                        });
                    this.$store.state.displayFiles.reClassifyArray =
                        response.data.reClassifyDocs;
                }
            } catch (error) {
                console.log(error);
            }
        }
        /**************************************************************************************************
         *                                TO DISPLAYFILE AFTER DELETING                                    *
         **************************************************************************************************/
    public async checkFileIndexOnDeleting(file, ifLocalFiles) {
        try {
            let index = this.$store.state.displayFiles.fileIndex;
            if (ifLocalFiles) {
                let index = this.$store.state.displayFiles.fileArray.findIndex(
                    f => f != file
                );
                this.$store.state.displayFiles.localFilesData.splice(index, 1);
            }
            this.$store.state.displayFiles.fileArray = this.$store.state.displayFiles.fileArray.filter(
                f => f != file
            );
            let fileArray = this.$store.state.displayFiles.fileArray;
            if (fileArray.length == 0) {
                this.hideDisplayFileModal();
            } else {
                this.changeImageIndex("refresh");
            }
        } catch (error) {
            console.log(error);
        }
    }

    public async checkIfreClassified(commId, loanTxnId, currentImage) {
        try {
            let id = await this.toGetTheCurrentImage(currentImage);
            let response = await Axios.post(
                BASE_API_URL + "wemloprocessor/checkIfReClassified", {
                    commId: commId,
                    loanTxnId: loanTxnId,
                    id: id
                });
            return response.data.alreadyReClassified ?
                "This document is re-classified to " +
                response.data.alreadyReClassified :
                false;
        } catch (error) {
            console.log(error);
        }
    }

    public async toGetTheCurrentImage(currentImage) {
        try {
            let id = null;
            this.$store.state.displayFiles.fileArrayWithId.forEach(e => {
                if (e.name == currentImage) {
                    id = e.id;
                }
            });
            return id;
        } catch (error) {
            console.log(error);
        }
    }

    public async reclassifyMessageAttachment(fileToDisplay) {
        try {
            if (this.$store.state.displayFiles.reClassify == null) {
                this.errorShow = true;
                return;
            }
            let id = null;

            this.fileToDisplay = fileToDisplay;
            // JSON.parse(fileToDisplay);
            fileToDisplay.fileArrayWithId.forEach(e => {
                if (e.name == this.$store.state.displayFiles.fileToDisplay) {
                    id = e.id;
                }
            });
            let data = {
                loanTxnId: this.$store.state.displayFiles.loanTxnId,
                commId: this.$store.state.displayFiles.commId,
                fileId: id,
                docId: this.$store.state.displayFiles.reClassify._id
            };
            let response = await Axios.post(
                BASE_API_URL + "wemloprocessor/reclassifyFromMessage",
                data);
            if (response.data.message == "alreadyExist") {
                this.$modal.show("confirmRemoveFiles");
            } else {
                this.$snotify.success("Document Reclasified");
                this.hideDisplayFileModal();
            }
        } catch (error) {
            console.log(error);
        }
    }

    public async replace(check) {
        try {
            let id = null;
            this.fileToDisplay.fileArrayWithId.forEach(e => {
                if (e.name == this.$store.state.displayFiles.fileToDisplay) {
                    id = e.id;
                }
            });
            let data = {
                check: check,
                loanTxnId: this.$store.state.displayFiles.loanTxnId,
                commId: this.$store.state.displayFiles.commId,
                fileId: id,
                docId: this.$store.state.displayFiles.reClassify._id
            };
            let response = await Axios.post(
                BASE_API_URL + "wemloprocessor/replaceExistingFile",
                data);
            this.hideDisplayFileModal();
            this.$modal.hide("confirmRemoveFiles");
            this.$snotify.success("Document Reclasified");
        } catch (error) {}
    }

    public getDocumentName(doc) {
      // make utility function, shared with wemlo-processor/BorrowerDetail, broker/Pipeline, DisplayDocument, etc.
      //  - caveat, for DisplayDocument we need to prepend an additional " - ", however we do not need this for the tooltip in other uses
      if (doc && doc.borrowerName) {
          return (` - ${doc.borrowerName} - ${doc.annotatedCategoryName || doc.categoryName}`);
      } else if (doc && doc.categoryName) {
        return (` - ${doc.annotatedCategoryName || doc.categoryName}`);
      } else {
        return "";
      }
    }

    public async downloadFile(file) {
      try {
        let fileName = null;
        let blob = null;
        if (file.indexOf("blob") == 0 ) {
          blob = this.returnLocalBlob(file);
          fileName = blob.name;
        } else {
          this.$store.state.wemloLoader = true;
          let response = await Axios.post(BASE_API_URL+'common/fetchDocumentOnce', { path: file }, { responseType: 'blob'});
          fileName = file.split('/').pop();
          this.$store.state.wemloLoader = false;
          blob = response.data;
        }
        saveAs(blob, fileName);
      } catch (error) {
        console.log(error);
      }
    }

    private returnLocalBlob(path) {
      return this.$store.state.displayFiles.localFiles[this.$store.state.displayFiles.fileIndex];
    }

    mounted() {}
}
